import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../lotties/pop-card-animation.json';


const LottieCard = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
    return (
      <div style={{marginTop: "4vh", position: "absolute", left: 0, right: 0}}>
        <Lottie 
          options={defaultOptions}
          height={200}
          width={400}
        />
      </div>
    );
}

export default LottieCard