/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useGlobalState } from "..";
import AppServices from "../services/AppServices";
import LanguageSelect from "./LanguageSelect";
import UploadPopup from "./Modal/UploadPopup";

const Header = ({ logged, user }) => {
  const [config, setConfig] = useGlobalState("config");

  const [pop, setPop] = useState(false);
  const [bgImage, setBgImage] = useGlobalState("cover");
  const [imagePrev, setImagePrev] = useState("");
  const [bgPosX, setBgPosX] = useState(0);
  const [bgPosY, setBgPosY] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [lang] = useGlobalState("lang_tran");
  const [data, setUser] = useGlobalState("user");
  const [zoom, setZoom] = useState(100);
  const [numTouches, setNumTouches] = useState(0);
  const [prevDistance, setPrevDistance] = useState(0);
  const [inisialState, setInitialState] = useState({
    x: 0,
    y: 0,
    zoom: 100,
    url: "",
  });
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const hasConfigCover = config?.cover;
    const hasUserCover = user?.cover;
    const defaultCover = { cover: { url: "" } };

    let initialState = defaultCover;
    if (hasConfigCover) {
      initialState = config.cover.url !== "" ? config : user;
    } else if (hasUserCover) {
      initialState = user;
    }

    setInitialState(initialState);
    console.log("initialState", initialState);
  }, [config, user]);

  useEffect(() => {
    setPop(false);

    setBgImage({
      url: inisialState.cover?.url,
    });
    setBgPosX(
      inisialState.cover?.x
        ? inisialState.cover?.x
        : inisialState.cover?.x === 0
        ? inisialState.cover?.x
        : 0
    );
    setBgPosY(
      inisialState.cover?.y
        ? inisialState.cover?.y
        : inisialState.cover?.y === 0
        ? inisialState.cover?.y
        : 0
    );
    setZoom(inisialState.cover?.zoom ? inisialState.cover?.zoom : 100);
  }, [inisialState]);

  const handleprofileimage = () => {
    setPop(true);
  };
  const handleprofileimageEdit = () => {
    setIsEditing(true);
    setPop(true);
  };
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);

  const handleMouseMove = (event) => {
    if (dragging && logged) {
      if (dragging && logged) {
        const deltaX = event.clientX - mousePosition.x;
        const deltaY = event.clientY - mousePosition.y;
        setBgPosX(bgPosX + deltaX);
        setBgPosY(bgPosY + deltaY);
        setMousePosition({ x: event.clientX, y: event.clientY });
      }
    }
  };

  const handlePinchGesture = (event) => {
    if (numTouches === 2 && imagePrev !== "") {
      event.preventDefault();
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      const currentDistance = Math.sqrt(
        Math.pow(touch1.clientX - touch2.clientX, 2) +
          Math.pow(touch1.clientY - touch2.clientY, 2)
      );

      if (prevDistance !== 0) {
        const distanceChange = currentDistance - prevDistance;
        if (Math.abs(distanceChange) > 2) {
          // Add a threshold to avoid small changes in distance
          const newZoom = zoom + distanceChange * 0.5; // Adjust the multiplier according to your preference
          setZoom(Math.max(10, Math.min(300, newZoom)));
        }
      }

      setPrevDistance(currentDistance);
    }
  };

  const handleTouchMove = (event) => {
    if (dragging && numTouches === 1 && imagePrev !== "") {
      event.preventDefault();
      const touch = event.touches[0];
      const deltaX = touch.clientX - mousePosition.x;
      const deltaY = touch.clientY - mousePosition.y;
      setBgPosX(bgPosX + deltaX);
      setBgPosY(bgPosY + deltaY);
      setMousePosition({ x: touch.clientX, y: touch.clientY });
    }
  };

  const handleMouseDown = (event) => {
    setDragging(true);
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const handleTouchStart = (event) => {
    setNumTouches(event.touches.length);

    if (event.touches.length === 1) {
      setDragging(true);
      const touch = event.touches[0];
      setMousePosition({ x: touch.clientX, y: touch.clientY });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleTouchEnd = () => {
    setNumTouches(0);
    setDragging(false);
  };

  const handleZoom = (event) => {
    if (imagePrev !== "") {
      event.preventDefault();
      const newZoom = zoom + event.deltaY * -0.1;
      setZoom(Math.max(10, Math.min(300, newZoom)));
    }
  };

  const handleGestureStart = (event) => {
    if (event.touches.length === 2) {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      const initialDistance = Math.sqrt(
        Math.pow(touch1.clientX - touch2.clientX, 2) +
          Math.pow(touch1.clientY - touch2.clientY, 2)
      );
      setZoomTouchData({
        initialDistance,
        initialZoom: zoom,
      });
    } else {
      setNumTouches(event.touches.length);

      if (event.touches.length === 1) {
        setDragging(true);
        const touch = event.touches[0];
        setMousePosition({ x: touch.clientX, y: touch.clientY });
      }
    }
  };

  const handleGestureMove = (event) => {
    if (event.touches.length === 2) {
      event.preventDefault();
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      const currentDistance = Math.sqrt(
        Math.pow(touch1.clientX - touch2.clientX, 2) +
          Math.pow(touch1.clientY - touch2.clientY, 2)
      );
      const distanceChange = currentDistance - zoomTouchData.initialDistance;
      const newZoom = zoomTouchData.initialZoom + distanceChange * 0.5;
      setZoom(Math.max(10, Math.min(300, newZoom)));
    }
  };

  useEffect(() => {
    window.addEventListener("touchmove", handleTouchMove, { passive: false });
    window.addEventListener("touchmove", handlePinchGesture, {
      passive: false,
    });
    return () => {
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchmove", handlePinchGesture);
    };
  }, [dragging]);

  const handleSavePic = () => {
    AppServices.post(
      {
        action: 5,
        data: {
          cover: {
            url: imagePrev,
            x: bgPosX,
            y: bgPosY,
            zoom: zoom,
          },
        },
      },
      "/app"
    ).then((res) => {
      if (res.status === 200) {
        setBgImage({
          url: imagePrev,
        });
        setImagePrev("");
        setPop(false);

        setUser({
          ...user,
          cover: {
            url: imagePrev,
            x: bgPosX,
            y: bgPosY,
            zoom: zoom,
          },
        });
      }
    });
  };

  const handeAnnuler = () => {
    setImagePrev("");
    // setBgImage to default
    setBgImage({
      url: user.cover?.url,
    });
    setBgPosX(
      user.cover?.x ? user.cover?.x : user.cover?.x === 0 ? user.cover?.x : 0
    );
    setBgPosY(
      user.cover?.y ? user.cover?.y : user.cover?.y === 0 ? user.cover?.y : 0
    );
    setZoom(user.cover?.zoom ? user.cover?.zoom : 100);

    setPop(false);
  };

  const handleIsEditing = () => {
    setIsEditing(true);
  };

  const Annuler = {
    fr: "Annuler",
    en: "Cancel",
  };
  const Enregistrer = {
    fr: "Enregistrer",
    en: "Save",
  };
  const [zoomTouchData, setZoomTouchData] = useState({
    initialDistance: 0,
    initialZoom: 0,
  });

  useEffect(() => {
    const updateWindowDimensions = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", updateWindowDimensions);
    updateWindowDimensions();

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);
  return (
    <>
      <header
        id="header"
        className={`bg-[#354552] ${
          logged === false && bgImage?.url !== ""
            ? "h-[255px] md:h-[280px]"
            : ""
        } 
        ${logged === false && bgImage?.url === "" ? "h-[220px]" : ""}
        ${
          logged === true && bgImage?.url === "" ? "h-[255px] md:h-[280px]" : ""
        }
        ${
          logged === true && bgImage?.url !== "" ? "h-[255px] md:h-[280px]" : ""
        }
        ${logged === true && imagePrev !== "" ? "h-[255px] md:h-[280px]" : ""}
        ${logged === false && !user.cover ? "h-[220px]" : ""}
        ${logged === false && config?.cover ? "h-[255px] md:h-[280px]" : ""}
         z-[90] top-0  rounded-b-[100%] w-[125%] ml-[-12%]`}
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: `${zoom}%`,
          backgroundPosition: `${bgPosX}px ${
            isDesktop ? bgPosY - 100 : bgPosY
          }px`,

          backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}${bgImage?.url})`,
        }}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onTouchMove={handleGestureMove}
        onTouchStart={handleGestureStart}
        onMouseUp={handleMouseUp}
        onTouchEnd={handleTouchEnd}
        onWheel={handleZoom}
      >
        {!imagePrev ? (
          <>
            <div id="backShadowHeader" />
            <div className="logo-container max-w-[78%] m-auto">
              {config.logo.length == 0 && config.company_name[lang] == 0 ? (
                <span
                  style={{ color: "white", fontSize: "19px" }}
                  className="z-[99999]"
                >
                  {config.company_name[lang]}
                </span>
              ) : (
                <img
                  src={
                    config.logo
                      ? process.env.REACT_APP_IMAGE_PATH + config.logo
                      : "/assets/img/footer-logo.svg"
                  }
                  className="top-logo z-[99999]"
                  alt="Popcard"
                  srcSet=""
                />
              )}
              <div className="absolute right-4">
                <LanguageSelect logged={logged} />
              </div>
            </div>

            {logged ? (
              bgImage?.url ? (
                <div className="bg-[#97979781]  rounded-full p-2 justify-center absolute top-20 left-[45%]">
                  <img
                    className="  
                    w-[30px] h-[30px]
                  "
                    alt=""
                    src={"/assets/img/icons/add-back.svg"}
                    onClick={handleprofileimageEdit}
                  />
                </div>
              ) : (
                <div className="bg-[#97979781]   rounded-full p-2 justify-center absolute top-20 left-[45%] ">
                  <img
                    className="  
                    w-[30px] h-[30px]
                  
              "
                    alt=""
                    src={"/assets/img/icons/edit-back-image.svg"}
                    onClick={handleprofileimage}
                  />
                </div>
              )
            ) : null}
            {/* <div className="shape" /> */}
            {pop ? (
              <div
                className="fixed top-0 left-0 w-full h-full  bg-opacity-50 z-50 flex justify-center items-center
            "
              >
                <div
                  className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-1 flex justify-center items-center"
                  onClick={() => setPop(false)}
                />
                <UploadPopup
                  setPop={setPop}
                  setBgImage={setBgImage}
                  bgImage={bgImage}
                  setImagePrev={setImagePrev}
                  imagePrev={imagePrev}
                  setBgPosX={setBgPosX}
                  setBgPosY={setBgPosY}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  setZoom={setZoom}
                />
              </div>
            ) : null}
          </>
        ) : (
          <>
            <div className="max-w-[75%] m-auto z-[999] pt-4">
              <div className="flex justify-between items-center">
                <button
                  className="px-5 text-[17px] py-2  rounded-full  bg-black text-white"
                  onClick={handeAnnuler}
                >
                  {Annuler[lang]}
                </button>
                <button
                  className="px-5 py-2  text-[17px]   rounded-full  bg-black text-white"
                  onClick={handleSavePic}
                >
                  {Enregistrer[lang]}
                </button>
              </div>
            </div>
          </>
        )}
      </header>
    </>
  );
};

export default Header;
