import React from "react";
import { useGlobalState } from "..";

const AddNotFound = ({ text, action, style }) => {
    const [editComponantId, seteditComponantId] = useGlobalState('editComponantId')

    return (
        <div  onClick={() => {
            seteditComponantId(action)
        }}>
            <div className="no-content" style={style}>
                <div className="no-content-text">{text}</div>
                <div className="no-content-icon">
                <img src="../../assets/img/icons/plus.svg" alt="" srcSet="" />
                </div>
            </div>
        </div>

    )
}

export default AddNotFound