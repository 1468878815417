import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGlobalState } from "..";
import About from "../componants/About";
import { AboutOnboarding } from "./onboarding/About";
import Button from "../componants/Button";
import Details from "../componants/Details";
import EditButtons from "../componants/EditButtons";
import FloatButton from "../componants/FloatButton";
import Footer from "../componants/Footer";
import Header from "../componants/Header";
import Images from "../componants/Images";
import Products from "../componants/Products";
import Links from "../componants/Links";
import MainCard from "../componants/MainCard";
import Socials from "../componants/Socials";
import Videos from "../componants/Videos";
import AppServices from "../services/AppServices";
import { LinksOnboarding } from "./onboarding/Links";
import { ImagesOnboarding } from "./onboarding/Images";
import { VideosOnboarding } from "./onboarding/Videos";
import { ProfessionalInfoOnboarding } from "./onboarding/ProfessionalInfo";
import { SocialsOnboarding } from "./onboarding/Socials";
import { DetailsOnboarding } from "./onboarding/Details";
import Calendly from "../componants/Calendly";
import { CalendlyOnboarding } from "./onboarding/Calendly";
import Order from "../componants/Order/Order";
import CropImage from "../componants/onboarding/Links/CorpImage";
import Login from "./Login";
import ForgetPassword from "./ForgetPassword";
import LogoutAnimation from "../componants/LogoutAnimation";
import useTracking from "../hooks/useTracking";
import NotificationPopup from "../componants/Modal/NotificationPopup";
import Setting from "../componants/Setting";
import { style } from "@mui/system";
import { ProductServicesOnboarding } from "./onboarding/ProductServices";
import DateAdhesion from "../componants/DateAdhesion";
import Memebership from "../componants/MembershipType";

const Profile = () => {
  const [user, setUser] = useGlobalState("user");

  const [onboardingUser, setOnboardingUser] = useGlobalState("onboarding_user");
  const [branding, setBranding] = useGlobalState("branding");
  const [lang, setLang] = useGlobalState("lang_tran");
  const [loading, setLoading] = useGlobalState("loading");
  const [config, setConfig] = useGlobalState("config");
  const [refresh, setRefresh] = useGlobalState("refresh");

  const [DataSlug, setDataSlug] = useState([]);
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [logged, setLogged] = useGlobalState("isLogged");
  const [now, setNow] = useGlobalState("nowUtc");

  const [EditPopups, setEditPopups] = useState([
    {
      componant: <AboutOnboarding edit={true} />,
      style: {},
    },
    {
      componant: <ProfessionalInfoOnboarding edit={true} />,
      style: {},
    },
    {
      componant: <CalendlyOnboarding edit={true} />,
      style: {display: "none"},
    },
    {
      componant: <SocialsOnboarding edit={true} />,
      style: {},
    },
    {
      componant: <LinksOnboarding edit={true} />,
      style: {},
    },
    {
      componant: <ImagesOnboarding edit={true} />,
      style: {},
    },
    {
      componant: <VideosOnboarding edit={true} />,
      style: {},
    },
    {
      componant: <Order />,
      style: {},
    },
    {
      componant: <DetailsOnboarding edit={true} />,
      style: {},
    },
    {
      componant: (
        <Login
          edit={true}
          name={onboardingUser.first_name}
          picture={onboardingUser.profile_picture}
        />
      ),
      style: {},
    },
    {
      componant: (
        <ForgetPassword
          edit={true}
          name={onboardingUser.first_name}
          picture={onboardingUser.profile_picture}
        />
      ),
      style: {},
    },
    {
      componant: <Setting user={DataSlug} />,
      style: {},
    },
    {
      componant: <ProductServicesOnboarding edit={true} />,
      style: {"display": "none"},
    },
  ]);

  const [dataProfile, setDataProfile] = useState([]);

  const extractMainDomain = (url) => {
    var domain;
    if (url.indexOf("://") > -1) {
      domain = url.split('/')[2];
    }
    else {
      domain = url.split('/')[0];
    }
    domain = domain.split(':')[0];
    var parts = domain.split('.');
    var mainDomain = parts[parts.length - 2] + '.' + parts[parts.length - 1];
  
    return "https://" + mainDomain;
  }

  const getProfileData = (profile) => {
    AppServices.post(
      {
        action: 0,
        data: {
          id: id,
        },
      },
      "/app"
    ).then((response) => {
      if (!response.active){
        window.location = extractMainDomain(window.location.hostname);
      }else{
        setDataProfile(response["profile"][0]);
        if (profile && (response.profile_activated || response.profile_activated == undefined)) {
          setConfig(response["profile"][0]);
        }
        delete response["profile"];
        setUser(response);
        setLang(
          response?.language
            ? response?.language
            : localStorage.getItem("lang")
            ? localStorage.getItem("lang")
            : "fr"
        );
        setLoading(false);
      }
    });
  };

  const { sendTracking, trackResult } = useTracking({
    action: 51,
    collaborator_id: id,
  });

  const getUtcDate = () => {
    AppServices.post(
      {
        action: 10,
      },
      "/app"
    ).then((response) => {
      const now = new Date(response.utc);
      setNow(now);
    });
  };

  useEffect(() => {
    getUtcDate();
  }, []);

  useEffect(() => {
    AppServices.post(
      {
        action: 1,
        data: {
          id: id,
        },
      },
      "/collaborators"
    ).then((response) => {
      setUser(response);
    });
  }, [refresh]);

  useEffect(() => {
    AppServices.post(
      {
        action: 11,
        data: {
          id: id,
        },
      },
      "/app"
    ).then((response) => {
      console.log("branding", response.data[0]);
      setBranding(response?.data[0]);
    });
  }, []);

  useEffect(() => {
    getProfileData(true);
    if (
      localStorage.getItem("token") !== (null && undefined && "") &&
      id === localStorage.getItem("collaborator_id")
    ) {
      setLogged(true);
    } else {
      setLogged(false);
    }
    sendTracking();
  }, []);

  const [popupStyle, setPopupStyle] = useState({
    overflowY: "hidden",
  });

  const openPopup = () => {
    EditPopups[editComponantId] = {
      ...EditPopups[editComponantId],
      style: {
        ...EditPopups[editComponantId].style,
        display: "block",
      },
    };
    setEditPopups([...EditPopups]);

    setTimeout(() => {
      EditPopups[editComponantId] = {
        ...EditPopups[editComponantId],
        style: {
          ...EditPopups[editComponantId].style,
          opacity: 1,
          top: 0,
          zIndex: 100000,
        },
      };
      setEditPopups([...EditPopups]);
    }, 200);

    setPopupStyle({
      ...popupStyle,
      position: "fixed",
      top: 0,
      right: 0,
      left: 0,
    });
  };

  const hideForgerPassword = () => {
    EditPopups[10] = {
      ...EditButtons[10],
      style: {
        display: "block",
      },
    };

    setEditPopups([...EditPopups]);

    EditPopups[10] = {
      ...EditButtons[10],
      style: {},
    };

    setTimeout(() => {
      setEditPopups([...EditPopups]);
    }, 300);
  };

  const resetPopup = () => {
    setEditPopups([
      {
        componant: <AboutOnboarding edit={true} />,
        style: {
          display: "none",
        },
      },
      {
        componant: <ProfessionalInfoOnboarding edit={true} />,
        style: {
          display: "none",
        },
      },
      {
        componant: <CalendlyOnboarding edit={true} />,
        style: {
          display: "none",
        },
      },
      {
        componant: <SocialsOnboarding edit={true} />,
        style: {
          display: "none",
        },
      },

      {
        componant: <LinksOnboarding edit={true} />,
        style: {
          display: "none",
        },
      },
      {
        componant: <ImagesOnboarding edit={true} />,
        style: {
          display: "none",
        },
      },
      {
        componant: <VideosOnboarding edit={true} />,
        style: {
          display: "none",
        },
      },
      {
        componant: <Order />,
        style: {
          display: "none",
        },
      },
      {
        componant: <DetailsOnboarding edit={true} />,
        style: {
          display: "none",
        },
      },
      {
        componant: (
          <Login
            edit={true}
            name={onboardingUser.first_name}
            picture={onboardingUser.profile_picture}
          />
        ),
        style: {
          display: "none",
        },
      },
      {
        componant: (
          <ForgetPassword
            edit={true}
            name={onboardingUser.first_name}
            picture={onboardingUser.profile_picture}
          />
        ),
        style: {
          display: "none",
        },
      },
      {
        componant: <Setting user={DataSlug} />,
        style: {
          display: "none",
        },
      },
      {
        componant: <ProductServicesOnboarding edit={true} />,
        style: {
          display: "none",
        },
      },
    ]);
  };
  const closePopup = () => {
    setEditPopups([
      {
        componant: <AboutOnboarding edit={true} />,
        style: {
          display: "block",
        },
      },
      {
        componant: <ProfessionalInfoOnboarding edit={true} />,
        style: {
          display: "block",
        },
      },
      {
        componant: <CalendlyOnboarding edit={true} />,
        style: {
          display: "none",
        },
      },
      {
        componant: <SocialsOnboarding edit={true} />,
        style: {
          display: "block",
        },
      },

      {
        componant: <LinksOnboarding edit={true} />,
        style: {
          display: "block",
        },
      },
      {
        componant: <ImagesOnboarding edit={true} />,
        style: {
          display: "block",
        },
      },
      {
        componant: <VideosOnboarding edit={true} />,
        style: {
          display: "block",
        },
      },
      {
        componant: <Order />,
        style: {
          display: "block",
        },
      },
      {
        componant: <DetailsOnboarding edit={true} />,
        style: {
          display: "block",
        },
      },
      {
        componant: (
          <Login
            edit={true}
            name={onboardingUser.first_name}
            picture={onboardingUser.profile_picture}
          />
        ),
        style: {
          display: "block",
        },
      },
      {
        componant: (
          <ForgetPassword
            edit={true}
            name={onboardingUser.first_name}
            picture={onboardingUser.profile_picture}
          />
        ),
        style: {
          display: "block",
        },
      },
      {
        componant: <Setting user={DataSlug} />,
        style: {
          display: "block",
        },
      },
      {
        componant: <ProductServicesOnboarding edit={true} />,
        style: {
          display: "none",
        },
      },
    ]);
  };

  useEffect(() => {
    if (editComponantId > -1 || editComponantId === -3) {
      AppServices.post(
        {
          action: 1,
          data: {
            id: id,
          },
        },
        "/collaborators"
      ).then((response) => {
        console.log("res:", response);
        setOnboardingUser(response);
      });
      if (editComponantId !== -3) {
        openPopup();
      }
    } else {
      if (editComponantId === -1) {
        getProfileData();
      }

      setPopupStyle({
        overflowY: "scroll",
        position: "relative",
      });
      closePopup();
      setTimeout(() => {
        resetPopup();
      }, 1000);
    }
  }, [editComponantId]);

  const onCrop = (id, img) => {
    AppServices.post(
      {
        action: 1,
        data: {
          profile_picture: img,
        },
      },
      "/onboarding"
    ).then((response) => {
      seteditComponantId(-1);
      getProfileData();
    });
  };

  useEffect(() => {
    setLang(
      localStorage.getItem("lang") === "en"
        ? localStorage.getItem("lang")
        : "fr"
    );
  }, []);

  useEffect(() => {
    document.title = `Madaef Golf - ${user.first_name} ${user.last_name}`;
  }, [user.first_name, user.last_name]);

  const page = [
    <Memebership id={id}/>,
    <DateAdhesion id={id}/>,
    <About id={id} />,
    <Details id={id} />,
    <Socials id={id} />,
    // <Products id={id} />,
    // <Calendly id={id} />,
    <Links id={8} />,
    <Images id={id} />,
    <Videos id={id} />,
  ];

  const [hideLogout, setLogout] = useState(
    localStorage.getItem("logout") === "true" ? true : false
  );

  return (
    <div
      style={editComponantId !== -1 ? popupStyle : null}
      className="overflow-hidden relative max-w-[586px] m-auto"
    >
      <CropImage show={show} onUpload={setShow} onCropState={onCrop} />

      {hideLogout ? (
        <LogoutAnimation
          onHide={() => {
            setLogout(false);
            localStorage.setItem("logout", "false");
          }}
        />
      ) : null}
      {EditPopups.map((element) => {
        return (
          <section class="popup-action max-w-[580px] m-auto" id="about-popup" style={element.style}>
            {element.componant}
          </section>
        );
      })}

      <Header logged={logged} user={user} />
      <MainCard logged={logged} onUpload={setShow} />
      {logged !== null ? (
        logged ? (
          <EditButtons onUpload={setShow} logged={logged} />
        ): null
      ) : null}
      <div>
        {user.element_order.map((val) => {
          return page[val - 1];
        })}
      </div>
      <Footer logged={logged} />
      {/* <NotificationPopup /> */}
    </div>
  );
};

export default Profile;
