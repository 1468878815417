import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGlobalState } from "..";

const Edit = ({ value }) => {
    const [editComponantId, seteditComponantId] = useGlobalState('editComponantId')
    const [logged, setLogged] = useGlobalState('isLogged')
    const { id } = useParams()
   
    return (
        <>
        {logged ?
        <div class="section-top-buttons">
            <div class="btn-edit" onClick={() => {
                seteditComponantId(value)
            }}>
                <img src="../../assets/img/icons/edit-icon.svg" alt="" srcset="" />
            </div>
            <div class="btn-drag" onClick={() => {
                seteditComponantId(7)
            }}>
                <img src="../../assets/img/icons/drag-icon.svg" alt="" srcset="" />
            </div>
        </div> : null
        }
        </>
    )
}

export default Edit