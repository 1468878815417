import React, { useEffect, useState } from "react";
import { useGlobalState } from "..";
import ContentLoader from "react-content-loader";
import Edit from "./Edit";
import DetailsContainer from "./DetailsContainer";
import PhoneDetails from "./PhoneDetails";


const Details = () => {
    const [user] = useGlobalState('user')
    const [config] = useGlobalState('config')
    const [detailsFields, setDetailsFields] = useState([])
    const [loading, setLoading] = useGlobalState('loading')
    const [branding] = useGlobalState("branding")


    const details = (detailsArray) => {
        let k = 1
        let temp = []
        if (branding?.show_phone_number){
            user.phone.map((val, i) => {
                temp.push(<PhoneDetails number={val.number} prefix={val.prefix} k={i}/>)
            })
        }
        if (temp.length === 0){
            k = 0
        }
        detailsArray.map((val, i) => {

            if (val){
                temp.push(<DetailsContainer k={k} i={i} id={user.collaborator_id} val={val}/>)
                k += 1
            }
            
        })

        setDetailsFields(temp)
    }

    useEffect(() => {
      details(["", config.show_website ? config.restrict_website ? config.website : user.website : "", config.show_address ? config.restrict_address? config.address : user.address : ""])
    }, [config, user])
    const [lang] = useGlobalState("lang_tran");
    const [trans] = useGlobalState('onboarding_trans')
    return (
        <section className="contact" data-id={2}>
            <div className="edit-section">
                <div className="title">{trans.profile["contact-information-section"].title[lang]}</div>
                <Edit value={1}/>
            </div>

        <div className="contact-list">
            {loading ? <ContentLoader
                viewBox="0 0 400 100"
                width={"370px"}
                height={"100px"}
                speed={2}
                
                title="Content Loading..."
                backgroundColor="#d1d1d1"
                foregroundColor="#ecebeb"
            >
                    <rect x="0" y="0" rx="4" ry="3" width="100%" height="15" />
                    <rect x="0" y="40" rx="4" ry="3" width="100%" height="15" />
                    <rect x="0" y="80" rx="4" ry="3" width="100%" height="15" />
                    
            </ContentLoader>: detailsFields}
        </div>
    </section>  
    )
}

export default Details