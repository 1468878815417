import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGlobalState } from "..";
import useTracking from "../hooks/useTracking";
import useWindowOpen from "../hooks/useWindowOpen";
import AddNotFound from "./AddNotFound";
import Edit from "./Edit";
import LinkContainer from "./LinkContainer";

const Links = ({ links }) => {
  const { id } = useParams();
  const [user] = useGlobalState("user");
  const [config] = useGlobalState("config");
  const [logged] = useGlobalState("isLogged");
  const windowOpen = useWindowOpen;
  const [links_data, setLinks] = useState([]);

  useEffect(() => {
    if (config.restrict_links) {
      setLinks([
        ...config.links,
        ...user.links.filter((link) => {
          if (link.is_admin) {
            return link;
          }
        }),
      ]);
    } else {
      setLinks([...user.links]);
    }
  }, [config.links, user.links]);
  const [lang] = useGlobalState("lang_tran");
  const [onboarding_trans] = useGlobalState("onboarding_trans");

  const button = {
    fr: "Ajouter mes liens",
    en: "Add my links",
  };
  return (
    <>
      <section className="links" data-id={5}>
        {logged ? (
          <div className="edit-section">
            <div className="title">
              {onboarding_trans.profile["links-section"].title[lang]}
            </div>
            {links_data.length > 0 && config.show_links !== false ? (
              <Edit value={4} />
            ) : null}
          </div>
        ) : null}
        {links_data.length > 0 && config.show_links !== false ? (
          <div className="links-list">
            {!logged ? (
              <div className="title">
                {onboarding_trans.profile["links-section"].title[lang]}
              </div>
            ) : null}

            {links_data.map((val, i) => {
              return <LinkContainer val={val} id={id} />;
            })}
          </div>
        ) : null}

        {logged && !config.restrict_links && user.links?.length === 0 ? (
          <AddNotFound text={button[lang]} action={4} />
        ) : null}
      </section>
    </>
  );
};

export default Links;

