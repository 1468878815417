import React from "react";
import Lottie from "react-lottie";
import { useGlobalState } from "..";
import animationData from "../lotties/EN.json";
import animFr from "../lotties/FR.json";

const LottieLang = () => {
  const [lang] = useGlobalState("lang_tran");
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lang === "en" ? animationData : animFr,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div style={{ width: "100%", height: "100%", objectFit: "contain" }}>
      <Lottie options={defaultOptions} height={"100%"} width={"300px"} />
    </div>
  );
};

export default LottieLang;
