import React from "react";
import useTracking from "../hooks/useTracking";
import { useGlobalState } from "..";

const DetailsContainer = ({ k, i, val, id }) => {
  const { sendTracking, trackResult } = useTracking({
    action: 111,
    collaborator_id: id,
    componant: "Details",
    url: val,
    title: i === 0 ? "Email" : i === 2 ? "Address" : "Website",
  });
  const [config] = useGlobalState("config");

  return (
    <>
      <>
        {k > 0 ? <div className="divider-home" /> : null}
        <div
          className="single-contact-item"
          onClick={() => {
            i === 0
              ? window.open(`mailto:${val}`)
              : i === 2
              ? window.open(`https://www.google.com/maps/search/${val}`)
              : window.open(val.includes("http") ? val : `http://${val}`);
            sendTracking();
          }}
        >
          <div className="contact-icon-text">
            <div className="contact-item-icon">
              <img
                src={`../assets/img/icons/${
                  i === 0 ? "email" : i === 2 ? "address" : "website"
                }.svg`}
                alt=""
                srcSet=""
              />
            </div>
            <div className="contact-item-text">{val}</div>
          </div>
          <div className="contact-item-arrow">
            <img src="../assets/img/icons/arrow-right.svg" alt="" srcSet="" />
          </div>
        </div>
      </>
    </>
  );
};

export default DetailsContainer;
