import React, { useEffect, useState } from "react";
import { useGlobalState } from "..";
import MainCard from "./MainCard";

const LogoutAnimation = ({ onHide }) => {
    const [popup, setPopup] = useState({transform: "rotate(33deg)"})
    const [lang] = useGlobalState("lang_tran")
    const swiperCard = () => {
        setPopup({
            transform: "none"
        })

        setTimeout(() => {
            setPopup({transform: "rotate(33deg)"})
        }, 300);
    }

    useEffect(() => {
        setTimeout(() => {
            swiperCard()
        }, 400);
        setInterval(() => {
            swiperCard()
        }, 5000);
    }, [])
    
    const title = {
        fr: "Pour vous reconnecter la prochaine fois, swipez cette zone à gauche",
        en: "To reconnect next time, swipe this area to the left"
    }

    const button = {
        fr: "Ok, c’est noté !",
        en: "Okay, noted!"
    }

    return (
        <div className="logout-slider" style={{ display: "block" }}>
        <div className="logout-slider-body">
            <div className="">   
            <MainCard margin={0} infinite_animation={true}/>
            </div>
            <div className="logout-slider-popup">
            <img
                style={popup}
                src="../../assets/img/icons/hand-swipe.svg"
                id="icon-swipe" 
                alt=""
                srcSet=""
                className=""
            />
            <p>{title[lang]}</p>
            <button onClick={onHide}>{button[lang]}</button>
            </div>
        </div>
        </div>

    )
}

export default LogoutAnimation