import React from "react";
import { useState } from "react";
import ContentLoader from "react-content-loader";
import { useGlobalState } from "..";
import Edit from "./Edit";

const About = () => {
  const [lang, setLang] = useGlobalState("lang_tran");

  const [about, setAbout] = useState(false);
  const [user] = useGlobalState("user");
  const [config] = useGlobalState("config");
  const [loading, setLoading] = useGlobalState("loading");
  const [show, setShow] = useState(false);

  const aboutSplit = (text) => {
    var first_slice = "";
    var second_slice = "";
    if (text?.length <= 200) {
      first_slice = text;
    } else {
      first_slice = text?.slice(0, 200);
      second_slice = text?.slice(200, about.length);
    }
    return (
      <>
        {(about ? text : first_slice).split("\n").map((val, i) => {
          return (
            <div>
              {val}{" "}
              {(about ? text : first_slice).split("\n").length - 1 === i ? (
                text.length > 200 ? (
                  <>
                    {!about ? "..." : null}
                    <button
                      className="read-more"
                      onClick={() => {
                        setAbout(!about);
                      }}
                    >
                      {about ? "Lire moins" : "Lire plus"}
                    </button>
                  </>
                ) : null
              ) : (
                <br />
              )}
            </div>
          );
        })}
      </>
    );
  };
  const [onboarding_trans] = useGlobalState("onboarding_trans");

  return (
    <>
      {config.show_about && user?.about?.[lang] ? (
        <section className="about" data-id={1}>
          <div className="edit-section">
            <div className="title">
              {onboarding_trans.profile["about-section"].title[lang]}
            </div>
            <Edit value={0} />
          </div>
          <div className="about-content" id="about_text_content">
            {loading ? (
              <ContentLoader
                viewBox="0 0 400 100"
                width={"100%"}
                height={"100%"}
                speed={2}
                title="Content Loading..."
                backgroundColor="#d1d1d1"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="4" ry="3" width="80%" height="12" />
                <rect x="0" y="30" rx="4" ry="3" width="90%" height="12" />
                <rect x="0" y="60" rx="4" ry="3" width="95%" height="12" />
                <rect x="0" y="90" rx="4" ry="3" width="100%" height="12" />
              </ContentLoader>
            ) : (
              <p style={{ wordWrap: "break-word" }}>
                {config.restrict_about
                  ? aboutSplit(config?.about ? config?.about[lang] : "")
                  : aboutSplit(user?.about ? user?.about[lang] : "")}
              </p>
            )}
          </div>
        </section>
      ) : null}
    </>
  );
};

export default About;
