import { useEffect, useState } from "react";

const useSuggestPlaces = (address) => {
    const [places, setPlaces] = useState([]);

    useEffect(() => {
        if (!window.google) return;

        const autocomplete = new window.google.maps.places.Autocomplete(
            address
        );

        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            const address_maps = place.address_components.map(component => component.long_name)
            setPlaces(address_maps);
        }); 
    }, [address]);

    return places.join(', ');
};

export default useSuggestPlaces;