import React, { useEffect, useState } from "react";
import Close from "./Close";
import CopyToClipboard from "./CopyToClipboard";
import "../styles/Setting.scss";
import { useGlobalState } from "..";
import SettingCode from "./SettingCode";
import SettingPass from "./SettingPass";
import SettingSlug from "./SettingSlug";

const Setting = ({ edit, setPop }) => {
  const [user, setUser] = useGlobalState("user");
  const collaborator_id = localStorage.getItem("collaborator_id");
  const [branding] = useGlobalState("branding");
  const [slug, setSlug] = useState(collaborator_id);

  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);

  const [url, setUrl] = useState(
    `${branding?.slug_url ? branding?.slug_url : "https://getpopcard.com/"}${
      user?.slug ? user?.slug : collaborator_id
    }`
  );

  // useEffect hooks
  useEffect(() => {
    if (user) {
      setSlug(user.slug ? user.slug : collaborator_id);
    }
  }, [user]);

  useEffect(() => {
    setUrl(
      `${
        branding?.slug_url ? branding?.slug_url : "https://www.madaefgolfs.com/"
      }${slug}`
    );
  }, [slug]);

  const handleShare = () => {
    const encodedText = encodeURIComponent("");
    const encodedUrl = encodeURIComponent(url);
    window.location.href = `https://wa.me/?text=${encodedText}%20${encodedUrl}`;
  };

  const handleShareOther = async () => {
    try {
      await navigator.share({ url });
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  const handleStep1 = () => {
    setStep1(true);
  };

  const handleStep2 = () => {
    setStep2(true);
  };

  const [lang] = useGlobalState("lang_tran");
  const [trans] = useGlobalState("onboarding_trans");
  return (
    <div className="max-w-[560px] m-auto">
      <Close setStep1={setStep1} setStep2={setStep2} setPop={setPop} />
      <section
        className={`fields-content
        ${step1 ? "hidden" : "block"}
        ${step2 ? "hidden" : "block"}
      `}
      >
        <div className="field-title">{trans.settings.title[lang]}</div>
        <div className="flex flex-col gap-4 mt-[3rem]">
          <div className="flex justify-between">
            <div className="flex gap-5" onClick={handleStep2}>
              <img
                src="/assets/img/icons/key-icon.svg"
                alt=""
                className="setting-icon"
              />
              <div className="text-[18px]">
                {trans.settings["password-change-section"].title[lang]}
              </div>
            </div>
            <img
              src="/assets/img/icons/right-black.svg"
              alt=""
              className="setting-icon"
              onClick={handleStep2}
            />
          </div>

          {/* add line */}

          <hr className="bg-[#CCB7B7] h-[1px] w-full border-none" />

          <div className="flex justify-between">
            <div className="flex gap-5" onClick={handleStep1}>
              <img
                src="/assets/img/icons/more-icon-setting.svg"
                alt=""
                className="setting-icon"
              />
              <div className="text-[18px]">
                {trans.settings["custom-link-section"].title[lang]}
              </div>
            </div>
            <img
              src="/assets/img/icons/right-black.svg"
              alt=""
              className="setting-icon"
              onClick={handleStep1}
            />
          </div>
          <div className="mt-[-10px] ml-[43px] overflow-hidden">
            <div className="text-[18px] text-[#4E3131]">
              {trans.settings["custom-link-section"].lienactuel[lang]}
            </div>
            <div className="text-[14px] text-black">
              {branding?.vcard_url
                ? branding?.vcard_url + "/"
                : "app.getpopcard.com/"}

              <span className="text-[#795656]">{slug}</span>
            </div>
            <div className="text-[18px] text-[#4E3131] mt-3">
              {trans.settings["custom-link-section"].partager[lang]}
            </div>
            {/* coppy and share the link via whatssap and other */}
            <div className="flex gap-4 mt-[1rem]">
              <div className="flex flex-col items-center">
                <div className="bg-black p-2 px-3 rounded-full w-[44px] h-[44px]">
                  <CopyToClipboard
                    value={
                      branding?.slug_url
                        ? branding?.slug_url + slug
                        : "https://www.madaefgolfs.com/" + slug
                    }
                    color="white"
                  />
                </div>
                <div className="text-[11px] text-black">
                  {trans.settings["custom-link-section"].copier[lang]}
                </div>
              </div>
              <div className="flex flex-col items-center" onClick={handleShare}>
                <div className="bg-[#3EBA43] p-3 rounded-full">
                  <img
                    src="/assets/img/icons/whatsapp.svg"
                    alt=""
                    className="w-[20px] h-[20px]"
                  />
                </div>
                <div className="text-[11px] text-black">Whatsapp</div>
              </div>
              <div className="flex flex-col items-center">
                <div
                  className="bg-gray-300 p-3 rounded-full"
                  onClick={handleShareOther}
                >
                  <img
                    src="/assets/img/icons/3-dots.svg"
                    alt=""
                    className="w-[20px] h-[20px]"
                  />
                </div>
                <div className="text-[11px] text-black">
                  {trans.settings["custom-link-section"].other[lang]}
                </div>
              </div>
            </div>
          </div>
          <hr className="bg-[#CCB7B7] h-[1px] w-full border-none" />

          <SettingCode />

          <hr className="bg-[#CCB7B7] h-[1px] w-full border-none" />

          <div className="flex justify-between">
            <div
              className="flex gap-5"
              onClick={() => {
                const keys = Object.keys(localStorage);
                keys.forEach((key) => {
                  if (key !== "user_token") {
                    localStorage.removeItem(key);
                  }
                });
                localStorage.setItem("logout", "true");
                window.location.reload();
              }}
            >
              <img
                src="/assets/img/icons/logout.svg"
                alt=""
                className="setting-icon"
              />
              <div className="text-[18px]">
                {trans.profile.buttonLogout[lang]}
              </div>
            </div>
            <img
              src="/assets/img/icons/right-black.svg"
              alt=""
              className="setting-icon"
              onClick={() => {
                const keys = Object.keys(localStorage);
                keys.forEach((key) => {
                  if (key !== "user_token") {
                    localStorage.removeItem(key);
                  }
                });
                localStorage.setItem("logout", "true");
                window.location.reload();
              }}
            />
          </div>
        </div>
      </section>
      {
        // step 2
        step1 && (
          <SettingSlug
            setStep1={setStep1}
            setStep2={setStep2}
            setSlug={setSlug}
            slug={slug}
          />
        )
      }
      {
        // step 3
        step2 && <SettingPass setStep1={setStep1} setStep2={setStep2} />
      }
    </div>
  );
};

export default Setting;
