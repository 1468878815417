import { useEffect, useState } from "react";
import { FiGlobe } from "react-icons/fi";
import { FaFlag } from "react-icons/fa";
import { MdArrowDropDown } from "react-icons/md";
import { HiCheck } from "react-icons/hi";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import "../styles/LanguageSelect.scss";
import { useGlobalState } from "..";
import AppServices from "../services/AppServices";

const languageOptions = [
  { value: "en", label: "En", icon: <FaFlag className="w-4 h-4 mr-2" /> },
  { value: "fr", label: "Fr", icon: <FaFlag className="w-4 h-4 mr-2" /> },
];

function LanguageSelect({ logged }) {
  const [isOpen, setIsOpen] = useState(false);
  const [lang, setLang] = useGlobalState("lang_tran");
  const [user, setUser] = useGlobalState("user");
  const [selectedOption, setSelectedOption] = useState({
    value: lang === "fr" ? "FR" : "EN",
    icon: `/assets/img/icons/${lang}-flag.svg`,
  });

  useEffect(() => {}, [lang]);

  useEffect(() => {
    setLang(
      logged
        ? user?.language
          ? user?.language
          : localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "fr"
        : localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "fr"
    );

    setSelectedOption({
      value: logged
        ? user?.language === "fr"
          ? "FR"
          : "EN"
        : lang === "fr"
        ? "FR"
        : "EN",
      icon: logged
        ? `/assets/img/icons/${user?.language}-flag.svg`
        : `/assets/img/icons/${lang}-flag.svg`,
    });

  }, [lang, logged, user?.language]);

  const options = [
    { value: "FR", label: "FR", icon: "/assets/img/icons/fr-flag.svg" },
    { value: "EN", label: "EN", icon: "/assets/img/icons/en-flag.svg" },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption({ value: option.value, icon: option.icon });
    setIsOpen(false);
    setLang(option.value === "FR" ? "fr" : "en");
    // sotre lang in localStorege
    localStorage.setItem("lang", option.value === "FR" ? "fr" : "en");

    // update user language
    setUser({ ...user, language: option.value === "FR" ? "fr" : "en" });
    logged &&
      AppServices.post(
        {
          action: 5,
          data: {
            language: option.value === "FR" ? "fr" : "en",
          },
        },
        "/app"
      ).then((response) => {
        

        setUser({
          ...user,
          language: option.value === "FR" ? "fr" : "en",
        });
        // set it on localsotrage
        localStorage.setItem("lang", option.value === "FR" ? "fr" : "en");
      });
  };

  return (
    <div className="dropdown-container">
      <button className="dropdown-btn" onClick={toggleDropdown}>
        <span className="flex items-center">
          <img src={selectedOption.icon} alt="flag" className="w-4 h-4 mr-1" />
          {selectedOption.value}
        </span>
        {isOpen ? (
          <img
            src="/assets/img/icons/up-filled.svg"
            alt="chevron-up"
            className="dropdown-icon"
          />
        ) : (
          <img
            src="/assets/img/icons/down-filled.svg"
            alt="chevron-up"
            className="dropdown-icon"
          />
        )}
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          {options
            .filter((option) => option.value !== selectedOption.value)
            .map((option) => (
              <button
                key={option.value}
                className={`dropdown-option ${
                  option.value === selectedOption.value &&
                  "dropdown-option--selected"
                }`}
                onClick={() => handleOptionClick(option)}
              >
                <span className="flex items-center">
                  <img src={option.icon} alt="flag" className="w-4 h-4 mr-2" />
                  {option.label}
                </span>
              </button>
            ))}
        </div>
      )}
    </div>
  );
}

export default LanguageSelect;
