import React, { useEffect, useState } from "react";
import { useGlobalState } from "..";
import useUserToken from "../hooks/useUserToken";
import AppServices from "../services/AppServices";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import "../styles/Setting.scss";

const Login = ({ edit, name, picture }) => {
  const [error, setError] = useState(false);
  const [safe, setSafe] = useState(false);
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");
  const [lang] = useGlobalState("lang_tran");
  const [trans] = useGlobalState("onboarding_trans");
  const userToken = useUserToken();
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const [user, setUser] = useGlobalState("user");

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]:
        e.target.name === "email"
          ? e.target.value.toLowerCase()
          : e.target.value,
    });
  };

  useEffect(() => {
    if (editComponantId === 9 || !edit) {
      if (
        data.email.length > 0 &&
        data.password.length >= 6 &&
        validateEmail(data.email)
      ) {
        setSafe(true);
      } else {
        setSafe(false);
      }
    }
  }, [data]);

  const handleSubmit = () => {
    AppServices.post(
      {
        action: 2,
        data: {
          ...data,
          user_token:
            userToken.getUserToken() === null
              ? undefined
              : userToken.getUserToken(),
        },
      },
      "/auth"
    )
      .then((response) => {
        Object.keys(response).map((key) => {
          localStorage.setItem(key, response[key]);
        });
        window.location = `/${response["collaborator_id"]}`;
      })
      .catch((error) => {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 4000);
      });
  };
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <div className="wrapper">
        <div
          className="popup-header"
          onClick={() => {
            if (edit) {
              seteditComponantId(-1);
            } else {
              window.location = "/";
            }
          }}
        >
          <a>
            <img src="../../assets/img/icons/close.svg" alt="" srcSet="" />
          </a>
        </div>
        <section className="login-content">
          <div class="avatar-container">
            <img
              src={
                user.profile_picture
                  ? `${process.env.REACT_APP_IMAGE_PATH}` + user.profile_picture
                  : "/assets/img/logo-black.svg"
              }
              alt=""
              style={
                !user.profile_picture
                  ? { padding: "10px", objectFit: "contain" }
                  : {}
              }
              srcset=""
            />
          </div>
          <div className="avatar-title">
            {user.first_name ? `${trans.profile["edit-information-login"].bonjour[lang]} ${user.first_name} !` : trans.profile.buttonLogin[lang]}
          </div>
          {error ? (
            <div class="avatar-description text-dander ">
              🚫 Le nom d’utilisateur ou mot de passe sont incorrects. Veuillez
              réessayer ou cliquer sur «&nbsp;Mot de passe oublié&nbsp;».
            </div>
          ) : (
            <div className="avatar-description">
            {trans.profile["edit-information-login"].pleaseLogin[lang]}
            </div>
          )}
          <div>
            <div className="inputs-container">
              <div className="single-input">
                <label htmlFor="email" className="label">
                  Email
                </label>
                <input
                  defaultValue={data.email}
                  className="login-input"
                  onChange={handleChange}
                  type="text"
                  name="email"
                  id="email"
                />
              </div>
              <div className="mt-18" />
              <div className="single-input button_father_password">
                <label htmlFor="password" className="label">
                {trans.profile["edit-information-login"].pass[lang]}
                </label>
                <input
                  defaultValue={data.password}
                  className="login-input relative"
                  type={passwordVisible ? "text" : "password"}
                  onChange={handleChange}
                  name="password"
                  id="password"
                />
                <div id="password-togglee" onClick={togglePasswordVisibility}>
                  {passwordVisible ? (
                    <RiEyeOffLine className="text-[#795656]" />
                  ) : (
                    <RiEyeLine className="text-[#795656]" />
                  )}
                </div>
              </div>
            </div>
            <div className="forgot-password-container">
              <div
                className="forgot-password-link"
                onClick={() => {
                  // seteditComponantId(-1)
                  edit
                    ? seteditComponantId(10)
                    : window.open("/forget-password", "_self");
                }}
              >
               {trans.profile["edit-information-login"]["pass-oublie"][lang]}
              </div>
            </div>
            <div className="login-btn-wrapper">
              <button
                className={
                  safe ? "pop-btn-disabled btn-active" : "pop-btn-disabled"
                }
                onClick={handleSubmit}
                id="btn-login"
                disabled={!safe}
              >
                {trans.profile.buttonLogin[lang]}
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
