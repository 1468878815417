import React, { useEffect, useState } from "react";
import { useGlobalState } from "..";
import useVCard from "../hooks/useVCard";
import AppServices from "../services/AppServices";
import Identification from "./Identification";
import NotificationPopup from "./Modal/NotificationPopup";

const Button = ({ id, traking, user, dataProfile }) => {
  const [dataRes, setDataRes] = useState("");
  const [contactLocked, setContactLocked] = useState(false);
  const [show, setShow] = useState(false);
  const [valid, setValid] = useState(false);
  const [createdOn, setCreatedOn] = useState("2021-07-08T09:00:00.000Z");
  const [expactedCode, setExpactedCode] = useState("123456");
  const [lang] = useGlobalState("lang_tran");
  const [trans] = useGlobalState("onboarding_trans");
  const [branding] = useGlobalState("branding")
  useEffect(() => {
    setContactLocked(user?.contact_locked);
  }, [user]);
  const createCompleter = (data) => {
    AppServices.post(
      {
        action: 1,
        data: {
          card: "",
          email: "",
          first_name: "",
          last_name: "",
          phone: "",
          identified_in: user?._id,
          tracking: traking?.id,
          identification_methode: 0,
          user_token: localStorage.getItem("user_token"),
          checked: branding?.whatsapp_notifications != (undefined || null) ? !branding.whatsapp_notifications : false
        },
      },
      "/identify"
    )
      .then((res) => {
  
        setDataRes(res);
        // stored in localstorege
        localStorage.setItem("completerId", res.id);
        // store updated_by_user
        localStorage.setItem("updated_by_user", res.updated_by_user);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const [loading, setLoading] = useState(false);
  const handleClick = useVCard(
    () => id,
    setLoading,
    "MainButton",
    traking,
    user,
    createCompleter
  );
  const [popup, setPopup] = useState(false);
  const handlePopupOpen = () => {
    setValid(false);
    if (contactLocked) {
      setShow(true);
      setCreatedOn(user?.contact_lock?.created_on);
      setExpactedCode(user?.contact_lock.password);
    } else {
      handleClick();
      // setTimeout for 1500ms
      setTimeout(() => {
        setPopup(true);
      }, 1500);
    }
    // if (valid) {
    //   handleClick();

    //   // setTimeout for 1500ms
    //   setTimeout(() => {
    //     setPopup(true);
    //   }, 1500);
    // }
  };

  return (
    <>
      <section className="contact-buttons">
        <button
          className="btn btn-primary flex text-center items-center justify-center"
          onClick={handlePopupOpen}
          style={{ width: "100%", fontSize: 15 }}
          id="btn-get-contact"
        >
          <img
            src="/assets/img/loaders/loading.gif"
            style={{ display: loading ? undefined : "none" }}
            width="23px"
            alt="hello"
          />
          {loading ? null : trans.addToContact[lang]}
        </button>
        <div id="show-btn" style={{ position: "fixed" }} />
      </section>
      <NotificationPopup
        handleClick={handleClick}
        show={popup}
        close={() => setPopup(false)}
        traking={traking}
        user={user}
        dataProfile={dataProfile}
        dataRes={dataRes}
      />

      {contactLocked && show && (
        <div
          className="
          fixed top-0 left-0 w-full h-full bg-opacity-50 z-50 flex justify-center items-center"
        >
          <div
            className="
          fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-20 flex justify-center items-center"
            onClick={() => setShow(false)}
          />
          <Identification
            setValid={setValid}
            valid={valid}
            createdOn={createdOn}
            setShow={setShow}
            expactedCode={expactedCode}
            handleClick={handleClick}
            setPopup={setPopup}
          />
        </div>
      )}
    </>
  );
};

export default Button;
