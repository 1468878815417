import React, { useEffect } from "react";
import { useGlobalState } from "../../..";
import VideosDropDown from "./VideosDropDown";

const Fields = ({
  id,
  data,
  removeField,
  fieldsChange,
  handleDropDown,
  locked,
  edit,
}) => {
  const [showDropDown, setShowDropDown] = useGlobalState("dropdown_id");
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");
  const [lang] = useGlobalState("lang_tran");
  useEffect(() => {
    if ((editComponantId === 6 && !locked) || (!edit && !locked)) {
      function handleClick(e) {
        if (
          e.target.localName !== "dropsocial" &&
          e.target.localName !== "dropvideo"
        ) {
          setShowDropDown(false);
        }
      }
      document.body.addEventListener("click", handleClick);

      return () => {
        document.body.removeEventListener("click", handleClick);
      };
    }
  }, []);

  return (
    <dropvideo className="single-social mb-2 videos-fields">
      <dropvideo className="social-header">
        <dropvideo className="drop-social" onClick={() => setShowDropDown(id)}>
          <dropvideo className="text-drop-social-0">
            {" "}
            {data.type === 1 ? "Youtube" : "Vimeo"}{" "}
          </dropvideo>
          <dropvideo className="arrow-drop-social" style={{ display: "flex" }}>
            {!locked ? (
              <img
                src="../../assets/img/icons/arrow-down.svg"
                alt=""
                srcSet=""
              />
            ) : (
              <img src="../../assets/img/icons/lock.svg" alt="" srcset="" />
            )}
          </dropvideo>
        </dropvideo>

        {!locked ? (
          <dropvideo className="section-top-buttons">
            <dropvideo className="btn-delete" onClick={() => removeField(id)}>
              <img src="../../assets/img/icons/delete.svg" alt="" srcSet="" />
            </dropvideo>
            {/* <dropvideo className="btn-drag">
                        
                        <img
                        src="../../assets/img/icons/drag-icon.svg"
                        alt=""
                        srcSet=""
                        />
                    </dropvideo> */}
          </dropvideo>
        ) : null}
        {showDropDown === id ? (
          <VideosDropDown
            handleDropDown={handleDropDown}
            id={id}
            locked={locked}
          />
        ) : null}
      </dropvideo>
      <div className="mt-15" />
      <dropvideo
        className="underline-input"
        style={{ borderBottom: locked ? "none" : undefined }}
      >
        <input
          type="text"
          name="url"
          onChange={(e) => fieldsChange(e, id)}
          className="url "
          value={data.url}
          disabled={locked}
          placeholder="URL*"
          id="input-url-0"
        />
      </dropvideo>
      <div className="mt-15" />
      <dropvideo
        className="underline-input"
        style={{ borderBottom: locked ? "none" : undefined }}
      >
        <input
          type="text"
          name="description"
          onChange={(e) => fieldsChange(e, id)}
          value={data.description[lang]}
          className="description "
          placeholder="Description"
          disabled={locked}
          id="input-description-0"
        />
      </dropvideo>
    </dropvideo>
  );
};

export default Fields;
