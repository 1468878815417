import React, { useEffect, useState } from "react";
import { useGlobalState } from "..";
import Close from "../componants/Close";
import AppServices from "../services/AppServices";

const ForgetPassword = ({ picture }) => {
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");
  const [user, setUser] = useGlobalState("user");
  const [lang] = useGlobalState("lang_tran");
  const [trans] = useGlobalState("onboarding_trans");
  const [email, setEmail] = useState("");
  const [safe, setSafe] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(2);
  const [branding] = useGlobalState("branding");
  const [messages, setMessages] = useState([
    `✅ ${trans.profile["edit-information-login"].topr[lang]} « ${email} »`,
    `🚫 « ${email} » ${trans.profile["edit-information-login"].ressaye[lang]}`,
    trans.profile["edit-information-login"].subtitle[lang],
  ]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    if (email.length > 0 && validateEmail(email)) {
      setSafe(true);
    } else {
      setSafe(false);
    }
    setMessages([
      `✅ ${trans.profile["edit-information-login"].topr[lang]} « ${email} »`,
      `🚫 « ${email} » ${trans.profile["edit-information-login"].ressaye[lang]}`,
      trans.profile["edit-information-login"].subtitle[lang],
    ]);
  }, [email, lang]);

  useEffect(() => {
    if (currentMessage == 1) {
      setTimeout(() => {
        setCurrentMessage(2);
      }, 3000);
    }
  }, [currentMessage]);

  const handleEmail = () => {
    AppServices.post(
      {
        action: 1,
        data: {
          email: email,
          logo: branding?.logo ? branding?.logo : undefined,
          mailto: branding?.mailto ? branding?.mailto : undefined,
          domain: branding?.domain ? branding?.domain : undefined,
          server: branding?.server ? branding.server : undefined,
        },
      },
      "/email"
    )
      .then((respose) => {
        setCurrentMessage(0);
      })
      .catch(() => {
        setCurrentMessage(1);
      })
    
  };

  return (
    <>
      <Close />
      <section className="login-content">
        <div className="avatar-container">
          <div class="avatar-container">
            <img
              src={
                user.profile_picture
                  ? `${process.env.REACT_APP_IMAGE_PATH}` + user.profile_picture
                  : "/assets/img/logo-black.svg"
              }
              alt=""
              style={!picture ? { padding: "10px", objectFit: "contain" } : {}}
              srcset=""
            />
          </div>
        </div>
        <div className="avatar-title">
          {user.first_name
            ? `${trans.profile["edit-information-login"].bonjour[lang]} ${user.first_name} !`
            : trans.profile["edit-information-login"]["pass-oublie"][lang]}
        </div>
        <div
          className={
            currentMessage === 0
              ? "avatar-description text-success"
              : currentMessage === 1
              ? "avatar-description text-dander"
              : "avatar-description"
          }
        >
          {messages[currentMessage]}
        </div>
        <div>
          <div className="inputs-container">
            <div className="single-input">
              <label htmlFor="email" className="label">
                Email
              </label>
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="text"
                required=""
                name="email"
                className="login-input"
                id="email-pwd"
              />
            </div>
          </div>
          <div className="forgot-pwd-actions">
            <div
              className="btn-back-forgot-pwd"
              onClick={() => {
                seteditComponantId(-1);
              }}
            >
              ←{trans.profile["edit-information-login"].retour[lang]}
            </div>
            <button
              className={
                safe ? "pop-btn-disabled btn-active" : "pop-btn-disabled"
              }
              style={{ marginTop: 0 }}
              id="btn-fp"
              disabled={!safe}
              onClick={handleEmail}
            >
              {trans.profile["edit-information-login"].buttonSend[lang]}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgetPassword;
