import { useEffect, useState } from "react";
import NotificationPopup from "../componants/Modal/NotificationPopup";
import AppServices from "../services/AppServices";
import useTracking from "./useTracking";
import { useGlobalState } from "..";

function useVCard(id, setLoading, buttonType, traking, user, createCompleter) {
  const { sendTracking, trackResult } = useTracking({
    action: 65,
    collaborator_id: id(),
    componant: buttonType,
  });
  const [branding] = useGlobalState("branding");
  const [prev, setPrev] = useState(null);
  const [completer, setcompleter] = useState({
    card: "",
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    identified_in: "",
    tracking: "",
    identification_methode: 0,
  });
  const [completerSent, setCompleterSent] = useState(false);

  useEffect(() => {
    if (trackResult !== null && trackResult !== prev) {
      setPrev(trackResult);
      window.location = `${
        process.env.REACT_APP_API_BASE_URL
      }/contact/${id()}/${branding?.vcard_url ? branding?.vcard_url : ""}`;
    }
  }, [trackResult]);

  function handleClick() {
    sendTracking();
    if (!completerSent && user.form === true) {
      createCompleter(completer);
      setCompleterSent(true);
    }

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }

  return handleClick;
}

export default useVCard;
