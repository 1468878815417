import axios from 'axios';
import { useState } from 'react';
import AppServices from '../services/AppServices';
import useUserToken from './useUserToken';

const useTracking = ({ action, collaborator_id, url=undefined, componant=undefined, title=undefined, social_id=undefined}) => {
  const userToken = useUserToken();
  const [trackResult, setTrackResult] = useState(null);

    const sendTracking = () => {
        axios.get('https://api.ipdata.co/?api-key=54d73da804f5aad094403d13477111312532c025ae89c1d44ed34898', {
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(response => {
            AppServices.post({
                action: 0,
                data: {
                    action: action,
                    ...response.data,
                    user_agent: navigator.userAgent,
                    user_token: userToken.getUserToken(),
                    collaborator_id: collaborator_id,
                    clicked_on: {
                        componant: componant,
                        url: url,
                        title: title,
                        social_id: social_id
                    }
                }
            }, '/track').then((response) => {
                setTrackResult(response);
            }) 
        })
    }
    
    return {
        sendTracking,
        trackResult
    };
}
    
export default useTracking;
    