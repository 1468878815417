import React from 'react';
import ContentLoader from 'react-content-loader'

const Loader = (props) => {
    return (
    <ContentLoader
        viewBox="0 0 350 162"
        width={350}
        speed={2}
        height={162}
        title="Content Loading..."
        backgroundColor="#d1d1d1"
        foregroundColor="#ecebeb"
        
        {...props}
    >
        <rect x="0" y="0" rx="5" ry="5" width="134" height="162" />
        <rect x="150" y="20" rx="3" ry="3" width="134" height="12.12" />
        <rect x="150" y="50" rx="3" ry="3" width="134" height="9" />
        <rect x="150" y="70" rx="3" ry="3" width="134" height="9" />

    </ContentLoader>
    )
}


export default Loader;