import React, { useState, useCallback, useMemo, memo, useEffect } from "react";
import { useGlobalState } from "..";
import AppServices from "../services/AppServices";

const ProductItem = memo(
  ({
    setChangeTitle,
    handleDelete,
    data,
    setIndexId,
    index,
    setAddPop,
    setIsEditing,
    locked,
  }) => {
    const [images, setImages] = useState(data?.pictures);
    const [orders] = useGlobalState("orders");
    const [lang] = useGlobalState("lang_tran");
    useEffect(() => {
      setImages(data?.pictures);
    }, [data]);

    const handleThumbnailClick = useCallback(
      (clickedIndex) => {
        const newImages = [...images];
        const clickedImage = newImages.splice(clickedIndex, 1)[0];
        newImages.unshift(clickedImage);
        setImages(newImages);
      },
      [images]
    );

    const imageUrls = useMemo(
      () =>
        images?.map((image) => `${process.env.REACT_APP_IMAGE_PATH}${image}`),
      [images]
    );

    const handleEdit = () => {
      setIndexId(index);
      setAddPop(true);
      setIsEditing(true);
      setChangeTitle(true);
    };

    function addHttp(url) {
      if (!/^(f|ht)tps?:\/\//i.test(url)) {
        url = `http://${url}`;
      }
      return url;
    }

    const [favicons, setFavicons] = useState([]);

    useEffect(() => {
      if (data?.links && data?.links.length > 0) {
        data.links.forEach((link, index) => {
          if (link.store === "64066931621af85e8b8c230b") {
            const url = addHttp(link.url);
            AppServices.post(
              {
                action: 9,
                data: {
                  url: url,
                },
              },
              "/app"
            ).then((res) => {
              setFavicons((prevFavicons) => {
                const newFavicons = [...prevFavicons];
                if (res?.favicon) {
                  newFavicons[index] = res.favicon;
                }
                return newFavicons;
              });
            });
          }
        });
      }
    }, [data]);

    return (
      <div className="flex relative mb-[1rem]">
        <div className="flex flex-col gap-2">
          <img
            src={imageUrls?.[0]}
            alt="product"
            className="w-[114px] min-w-[114px] h-[114px] min-h-[114px] object-cover rounded-lg shadow-md cursor-pointer"
          />
          <div className="flex gap-2">
            {imageUrls?.slice(1).map((url, index) => (
              <img
                key={images[index + 1]?.id}
                src={url}
                alt="product"
                className="w-[35px] h-[35px] object-cover rounded-lg "
                onClick={() => handleThumbnailClick(index + 1)}
              />
            ))}
          </div>
        </div>
        {locked ? (
          <div className="absolute right-0 flex gap-2">
            <img src="../../assets/img/icons/lock.svg" alt="" srcset="" />
          </div>
        ) : (
          <div className="absolute right-0 flex gap-2 mt-[-1rem]">
            <img
              src="/assets/img/icons/edit-icon.svg"
              alt="edit"
              className="w-[20px] h-[20px] object-contain"
              onClick={handleEdit}
            />
            <img
              src="/assets/img/icons/delete.svg"
              alt="delete"
              className="w-[20px] h-[20px] object-contain"
              onClick={handleDelete}
            />
          </div>
        )}
        <div className="flex flex-col gap-2 mt-[0.5rem] ml-3 w-full">
          <div className="flex flex-col justify-between">
            <div className="text-[#101010] font-[400] text-[16px]">
              {data?.title[lang]}
            </div>
            <div className="text-[#4E3131] font-bold text-[18px] mt-[0.5rem]">
              {data?.price?.amount === 0 || !data?.price?.amount
                ? ""
                : data?.price?.amount}{" "}
              <span className="text-[16px] font-normal">
                {data?.price?.amount === 0 || !data?.price?.amount
                  ? ""
                  : data?.price?.currency}
              </span>
            </div>
          </div>
          {/* show order by store*/}
          <div className="flex gap-2  flex-wrap w-full">
            {data?.links?.map((item, index) => (
              <div className="flex flex-col justify-between">
                <div className="text-[#101010] font-[400] text-[14px]">
                  {/* look in orders by store */}

                  {orders[item.store]?.label === "Autre" ? (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={
                          favicons[index] !== undefined
                            ? `${process.env.REACT_APP_IMAGE_PATH}${favicons[index]}`
                            : `../..${orders[item.store]?.icon}.png`
                        }
                        alt={orders[item.store]?.label}
                        className="w-[35px] p-1 h-[35px] shadow-sm object-cover bg-white rounded-lg"
                      />
                    </a>
                  ) : orders[item.store]?.label === "Whatsapp" ? (
                    <img
                      src={`../..${orders[item.store]?.icon}.png`}
                      alt={orders[item.store]?.label}
                      className="w-[35px] h-[35px] shadow-sm object-contain rounded-lg"
                    />
                  ) : (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(event) => {
                        event.preventDefault();
                        try {
                          const url = new URL(addHttp(item.url));
                          window.open(url.href, "_blank");
                        } catch (error) {
                          console.error(error);
                          // alert("Invalid URL");
                        }
                      }}
                    >
                      <img
                        src={`../..${orders[item.store]?.icon}.png`}
                        alt={orders[item.store]?.label}
                        className="w-[35px] h-[35px] shadow-sm object-contain rounded-lg"
                      />
                    </a>
                  )}
                </div>
                <div className="text-[#4E3131] font-bold text-[18px]">
                  {/* look in orders by store */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
);

export default ProductItem;
