import { useEffect, useState } from "react";
import { useNavigation, useParams } from "react-router-dom";
import { useGlobalState } from "..";
import Login from "../views/Login";
import Loader from "./Loader";
import moment from "moment/moment";


const MainCard = ({ margin, infinite_animation, onUpload }) => {
  const [loading] = useGlobalState("loading");
  const [user] = useGlobalState("user");
  const [config] = useGlobalState("config");
  const [startX, setX] = useState();
  const [startY, setY] = useState();
  const { id } = useParams();
  const [logged, setLogged] = useGlobalState("isLogged");
  const [swiped, setSwipped] = useState(null);
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");

  const detectSwipeX = (endX) => {
    if (endX < startX) {
      setSwipped({
        position: "relative",
        left: "-300px",
      });
    }

    setTimeout(() => {
      setSwipped({
        position: "relative",
      });
    }, 300);
  };

  const detectSwipeY = (endY) => {
    if (endY > startY && swiped !== null) {
      setSwipped(null);
    }
  };

  const swiperCard = () => {
    setSwipped({
      position: "relative",
      left: "-300px",
    });

    setTimeout(() => {
      setSwipped({
        position: "relative",
      });
    }, 300);
  };

  useEffect(() => {
    if (infinite_animation) {
      setTimeout(() => {
        swiperCard();
      }, 400);
      setInterval(() => {
        swiperCard();
      }, 5000);
    }
  }, []);
  const [pop, setPop] = useState(false);

  const [image, setImage] = useGlobalState("image");

  const fileToDataUri = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onload = (event) => {
      setImage({
        id: 0,
        image: event.target.result,
      });
      onUpload(true);
      setPop(false);
    };
    reader.readAsDataURL(file);
  };

  const handleprofileimage = () => {
    setPop(true);
  };

  const [lang] = useGlobalState("lang_tran");
  const [trans] = useGlobalState('onboarding_trans')
  return (
    <>
      <section className="main-card relative" style={{ margin: margin }}>
        <div
          className="main-card-container"
          style={swiped}
          onTouchStart={(e) => {
            setX(e.targetTouches[0].pageX);
          }}
          onTouchEnd={(e) => {
            detectSwipeX(e.changedTouches[0].pageX);
          }}
          id="popcard"
        >
          {loading ? (
            <Loader />
          ) : (
            <>
              {logged !== null ? (
                logged ? (
                  <input
                    type="file"
                    accept="image/*"
                    className="files"
                    name="images"
                    onChange={fileToDataUri}
                    style={{ display: "none" }}
                    id="profile-picture"
                  />
                ) : null
              ) : null}
              <label htmlFor="profile-picture" >
                <div
                  style={
                    user.profile_picture
                      ? {
                          backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}${user.profile_picture})`,
                        }
                      : null
                  }
                  className={
                    user.profile_picture
                      ? "user-picture-container relative"
                      : "user-picture-container default-profile-picture relative"
                  }
                  onClick={handleprofileimage}
                >
                  {!user.profile_picture ? (
                    <img
                      className={
                        user.profile_picture ? "edit-profile-icon" : null
                      }
                      alt=""
                      src={"/assets/img/icons/default-profile.svg"}
                    />
                  ) : null}
                  {logged !== null ? (
                    logged ? (
                      <img
                        className="absolute top-0 left-0  bg-black p-2 edit-icon"
                        alt=""
                        src={"/assets/img/icons/edit-icon-image.svg"}
                      />
                    ) : null
                  ) : null}
                </div>
              </label>
              <div className="user-info-container">
                {swiped !== null && !logged ? (
                  <div className="swiped-card">
                    <div className="name-container">{user.first_name},</div>
                    <div className="user-card-bio">
                      {trans.profile.mainCard.title[lang]} 
                    </div>
                    <div
                      className="login-link-container"
                      onClick={() => {
                        seteditComponantId(9);
                      }}
                    >
                      {trans.profile.buttonLogin[lang]} →
                    </div>
                  </div>
                ) : (
                  <div className="unswipped-card">
                    <div className="name-container">
                      {user.first_name + " " + user.last_name}
                    </div>
                    <div className="user-card-bio">
                      N° de licence : {user?.title?.[lang]} <br />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {/* {pop ? (
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center
            "
          >
            <UploadPopup setPop={setPop} onUpload={onUpload} />
          </div>
        ) : null} */}
      </section>
    </>
  );
};

export default MainCard;
