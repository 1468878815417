import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useParams } from "react-router-dom";
import { useGlobalState } from "..";
import useWindowOpen from "../hooks/useWindowOpen";
import AppServices from "../services/AppServices";
import AddNotFound from "./AddNotFound";
import Edit from "./Edit";
import SocialContainer from "./SocialContainer";

const Socials = () => {
  const [user] = useGlobalState("user");
  const [config] = useGlobalState("config");
  const [loading, setLoading] = useGlobalState("loading");
  const [socials, setSocials] = useGlobalState("socials");
  const [logged] = useGlobalState("isLogged");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (config.restrict_socials) {
      setData([
        ...config.socials.filter((val) => {
          if (val.url.length === 0) {
            var results = user.socials.filter((social_user) => {
              if (social_user.social_id === val.social_id) {
                return social_user;
              }
            });
            if (results.length === 0) {
              val.wasEmpty = true;
              return val;
            }
          } else {
            return val;
          }
        }),
        ...user.socials.filter((val) => {
          if (val.url.length === 0) {
            val.wasEmpty = true;
            return val;
          } else if (val.is_admin) {
            return val;
          }
        }),
      ]);
    } else {
      setData([...user.socials]);
    }
  }, [user.socials, config.restrict_socials]);

  const [lang] = useGlobalState("lang_tran");
  const [onboarding_trans] = useGlobalState("onboarding_trans");

  const button = {
    fr: "Ajouter vos reseaux sociaux",
    en: "Add your social networks",
  };
  return (
    <>
      <section className="social">
        {(config.restrict_socials &&
          config.socials.length > 0 &&
          config.show_socials) ||
        !config.restrict_socials ? (
          user.socials.length > 0 || logged ? (
            <div className="edit-section">
              <div className="title">
                {onboarding_trans.profile["social-media-section"].title[lang]}
              </div>
              <Edit value={3} />
            </div>
          ) : null
        ) : null}
      </section>
      <section className="social" data-id={3}>
        {(config.restrict_socials && config.socials.length > 0) ||
        !config.restrict_socials ? (
          user.socials.length > 0 ? (
            <>
              {loading ? (
                [...Array(4).keys()].map((i) => {
                  return (
                    <div className="social-item">
                      <ContentLoader
                        viewBox="0 0 100 100"
                        width={"100%"}
                        height={"100%"}
                        speed={2}
                        title="Content Loading..."
                        backgroundColor="#d1d1d1"
                        foregroundColor="#ecebeb"
                      >
                        <circle cx="50" cy="50" r="30" />
                      </ContentLoader>
                    </div>
                  );
                })
              ) : (
                <div className="flex  flex-wrap justify-between items-center gap-3">
                  {data.map((val, i) => {
                    return (
                      <SocialContainer
                        val={val}
                        socials={socials}
                        id={user.collaborator_id}
                      />
                    );
                  })}
                </div>
              )}
            </>
          ) : logged ? (
            <AddNotFound text={button[lang]} action={3} style={{ margin: 0 }} />
          ) : null
        ) : null}
      </section>
    </>
  );
};

export default Socials;
