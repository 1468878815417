import React from "react";
import { useGlobalState } from "../..";

const OrderElements = ({ text, index, ChangeOrder }) => {
    const [onboardingUser, setOnboardingUser] = useGlobalState('onboarding_user')

    return (
        <div className="single-arrange" style={{ top: 360 }}>
            <div className="arrange-left">{index + 1}. {text}</div>
                <div className="arrange-right">
                    <div className="drag-items">
                    <div
                        className="btn-drag-up"
                        style={{ display: index === 8 ? "block" : index === 0 ?  "none" : undefined }}
                        onClick={() => {
                            ChangeOrder(index, -1)
                        }}
                    >
                        <img src="../../assets/img/icons/up.svg" alt="" srcSet="" />
                    </div>
                    <div className="separator-w" />
                    <div
                        className="btn-drag-down"
                        style={{ display: index === 0 ? "block" : index === 8 ?  "none" : undefined }}
                        onClick={() => {
                            ChangeOrder(index, 1)
                        }}
                    >
                        <img src="../../assets/img/icons/down.svg" alt="" srcSet="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderElements