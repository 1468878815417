import React, { useEffect, useState } from "react";
import { FaCameraRetro } from "react-icons/fa";
import { useGlobalState } from "../..";
import AppServices from "../../services/AppServices";
import TakingPic from "../TakingPic";
import EXIF from "exif-js";

const UploadPopup = (props) => {
  const {
    isEditing,
    onUpload,
    setPop,
    setBgImage,
    bgImage,
    imagePrev,
    setImagePrev,
    setBgPosX,
    setBgPosY,
    setIsEditing,
    setZoom,
  } = props;

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [stream, setStream] = useState(null);
  const [showIcon, setShowIcon] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [uploadPercentStr, setUploadPercentStr] = useState("0%");
  const [onboardingUser, setOnboardingUser] = useGlobalState("onboarding_user");
  const [user, setUser] = useGlobalState("user");
  const [lang] = useGlobalState("lang_tran");
  const [trans] = useGlobalState("onboarding_trans");


  const handleImageOrientation = (img, file, callback) => {
    EXIF.getData(img, () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const orientation = EXIF.getTag(img, "Orientation");
  
      const targetWidth = img.width;
      const targetHeight = img.height;
  
      let radians = 0;
      let scaleX = 1;
      let scaleY = 1;
  
      switch (orientation) {
        case 2:
          scaleX = -1;
          break;
        case 3:
          radians = Math.PI;
          break;
        case 4:
          scaleY = -1;
          break;
        case 5:
          radians = Math.PI / 2;
          scaleY = -1;
          [canvas.width, canvas.height] = [targetHeight, targetWidth];
          break;
        case 6:
          radians = Math.PI / 2;
          [canvas.width, canvas.height] = [targetHeight, targetWidth];
          break;
        case 7:
          radians = (3 * Math.PI) / 2;
          scaleY = -1;
          [canvas.width, canvas.height] = [targetHeight, targetWidth];
          break;
        case 8:
          radians = (3 * Math.PI) / 2;
          [canvas.width, canvas.height] = [targetHeight, targetWidth];
          break;
        default:
          [canvas.width, canvas.height] = [targetWidth, targetHeight];
          break;
      }
  
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(radians);
      ctx.scale(scaleX, scaleY);
      ctx.drawImage(
        img,
        -targetWidth / 2,
        -targetHeight / 2,
        targetWidth,
        targetHeight
      );
  
      const correctedDataUri = canvas.toDataURL(file.type);
      callback(correctedDataUri);
    });
  };
  const fileToDataUri = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        handleImageOrientation(img, file, (correctedDataUri) => {
          upload(correctedDataUri, file);
        });
      };
    };

    reader.readAsDataURL(file);
  };

  function closeCamera() {
    if (!stream) return;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    setStream(null);
    setOpen(false);
  }

  useEffect(() => {
    if (bgImage) {
      closeCamera();
    }
  }, []);

  function dataUriToBlob(dataUri, mimeType) {
    const byteString = atob(dataUri.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeType });
  }
  function upload(correctedDataUri, file) {
    if (uploadPercent > 0) return;
    if (!file) return;

    // Create a new Blob from the corrected data URI
    const correctedBlob = dataUriToBlob(correctedDataUri, file.type);

    AppServices.upload(
      correctedBlob,
      "file",
      "/upload",
      {
        "Content-Type": file.type,
      },
      handleUploadProgress
    )
      .then((res) => {
        setUploadPercent(0);
        setUploadPercentStr("0%");
        // setImage
        setBgImage({
          url: res.url,
        });
        setImagePrev(res.url);
        // set backround image
        setBgPosX(0);
        setBgPosY(0);
        onUpload(true);
        setPop(false);
        setZoom(100);
        // onboardingUser

        setOnboardingUser({
          ...onboardingUser,
          cover: {
            url: res.url,
          },
        });

        // user

        setUser({
          ...user,
          cover: {
            url: res.url,
          },
        });
      })
      .catch((err) => {
        // console.log(err);
      });
    setZoom(100);
  }

  function handleUploadProgress(e) {
    let percentage = (e.progress * 100).toFixed(2);
    const roundCircum = 2 * 70 * Math.PI;
    const svgPercentage = (percentage * roundCircum) / 100;
    setUploadPercent(svgPercentage);
    setUploadPercentStr(Math.floor(percentage - 1) + "%");
  }

  function handleSupprimer() {
    setBgImage(null);
    setImagePrev(null);
    AppServices.post(
      {
        action: 5,
        data: {
          cover: {
            url: "",
            x: 0,
            y: 0,
          },
        },
      },
      "/app"
    ).then((res) => {
      // if res 200
      if (res.status !== 200) return;
      setBgImage({
        url: "",
      });
      setImagePrev("");
      // set backround image
      setBgPosX(0);
      setBgPosY(0);
      setZoom(100);
      setIsEditing(false);
    });
    setPop(false);

    // onboardingUser

    setOnboardingUser({
      ...onboardingUser,
      cover: {
        url: "",
        x: 0,
        y: 0,
      },
    });

    // user

    setUser({
      ...user,
      cover: {
        url: "",
        x: 0,
        y: 0,
      },
    });
  }

  function handleTakePictureFromIphone(
    capturedImageDataURL,
    capturedImageFile
  ) {
    const img = new Image();
    img.src = capturedImageDataURL;

    img.onload = () => {
      handleImageOrientation(img, capturedImageFile, (correctedDataUri) => {
        upload(correctedDataUri, capturedImageFile);
      });
    };
  }
  return (
    <div className="bg-white w-[100%] max-w-[347px] rounded-xl p-[20px] z-[999]">
      <div>
        <div className="flex flex-col justify-between gap-1 ">
          {/* <div className="Box">
            {/* {showIcon ? (
              <FaCameraRetro
                className="camera-icon z-[999]"
                onClick={handleTakePicture}
              />
            ) : (
              ""
            )} */}

          {/* <input
              type="file"
              accept="image/*"
              className="files"
              name="images"
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  const file = e.target.files[0];
                  const reader = new FileReader();

                  reader.onload = (e) => {
                    handleTakePictureFromIphone(e.target.result, file);
                  };

                  reader.readAsDataURL(file);
                }
              }}
              style={{ display: "none" }}
              id="profile-picturer"
              capture="camera"
            /> */}
          {/* <div className="camera">
              {open ? (
                <video
                  autoPlay
                  playsInline
                  muted
                  ref={(ref) => {
                    if (ref) {
                      ref.srcObject = stream;
                    }
                  }}
                  className="z-[998]"
                />
              ) : (
                ""
              )}
            </div> */}
          {/* </div>
          <label
            htmlFor="profile-picturer"
            className="text-[17px] font-[400] text-black
            hover:bg-gray-200 p-3 rounded-xl
          "
          >
            {trans.uploaderPic.upload[lang]}
          </label>  */}
          <TakingPic
            onUpload={onUpload}
            setPop={setPop}
            setBgImage={setBgImage}
            setImagePrev={setImagePrev}
            setBgPosX={setBgPosX}
            setBgPosY={setBgPosY}
            setZoom={setZoom}
            handleImageOrientation={handleImageOrientation}
          />
          <input
            type="file"
            accept="image/*"
            className="files"
            name="images"
            onChange={fileToDataUri}
            style={{ display: "none" }}
            id="profile-picture"
          />
          <label
            htmlFor="profile-picture"
            className="text-[17px] rounded-xl hover:bg-gray-200 font-[400] text-black p-3"
          >
            {trans.uploaderPic.telecharger[lang]}
          </label>
          {isEditing && (
            <label
              className="text-[17px] rounded-xl hover:bg-gray-200 font-[400] text-black p-3"
              onClick={handleSupprimer}
            >
              {trans.uploaderPic.annuler[lang]}
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadPopup;
