import React, { useEffect, useState } from "react";
import { useGlobalState } from "..";
import AddNotFound from "./AddNotFound";
import Carousel from "./Carousel";
import CarouselProduct from "./CarouselProduct";
import Edit from "./Edit";

const Products = () => {
  const [user] = useGlobalState("user");
  const [onboardingUser, setOnboardingUser] = useGlobalState("onboarding_user");
  const [config] = useGlobalState("config");
  const [data, setData] = useState([]);
  const [logged, setLogged] = useGlobalState("isLogged");
  const [product, setProduct] = useState([]);
  useEffect(() => {
    console.log("user", user);
    const filteredProducts = user?.products?.filter(
      (product) =>
         product?.pictures
    );

    setData(
      filteredProducts && filteredProducts.length > 0
        ? [...filteredProducts]
        : []
    );
  }, [user]);

  useEffect(() => {
    if (config.restrict_products) {
      setProduct([
        ...config.products,
        ...user.products.filter((product) => {
          if (product.is_admin) {
            return product;
          }
        }),
      ]);
    } else {
      const filteredProducts = user?.products?.filter(
        (product) =>
          product?.pictures
      );
      setProduct(filteredProducts && filteredProducts.length > 0 ? [...filteredProducts] : []);
    }
  }, [config, user]);
  const [lang, setLang] = useGlobalState("lang_tran");

  const title = {
    en: "Products and services",
    fr: "Produits et services",
  };

  const buttonTrans = {
    fr: "Ajouter vos produits",
    en: "Add your products",
  };

  // render a default product if the user does not have any products
  if (!user?.products && logged) {
    return (
      <section>
        <div className="edit-section" style={{ marginTop: "3vh" }}>
          <div className="title">{title[lang]}</div>
          <Edit value={12} />
        </div>
        <AddNotFound text={buttonTrans[lang]} action={12} />
      </section>
    );
  }

  return (
    <>
      {(!config.restrict_products && user?.products?.length > 0 && config?.show_products !== false) ||
      config.restrict_products ? (
        <CarouselProduct
          items={2}
          values={product}
          title={title[lang]}
          type={1}
        />
      ) : logged ? (
        <section>
          <div className="edit-section" style={{ marginTop: "3vh" }}>
            <div className="title">{title[lang]}</div>
            <Edit value={12} />
          </div>
          <AddNotFound text={buttonTrans[lang]} action={12} />
        </section>
      ) : null}
    </>
  );
};

export default Products;
