import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useTracking from "../hooks/useTracking";

const CarrouselImagePreview = ({ images, currentImage, setCurrentImage}) => {
    const { id } = useParams()
    const { sendTracking, trackResult } = useTracking({ action: 110, collaborator_id: id, componant: "Images", url: images[currentImage].image});    

    useEffect(() => {
      if (currentImage !== -1){
        sendTracking()
      }
    }, [currentImage])
    

    return (
      <div
        className="mfp-wrap mfp-gallery mfp-close-btn-in mfp-ready"
        tabIndex={-1}
        style={{ top: 0, position: "fixed", height: "100%", background: "rgb(0 0 0 / 29%)"}}
      >
        <div className="mfp-container mfp-s-ready mfp-image-holder">
          <div className="mfp-content">
            <div className="mfp-figure">
              <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => {
                          setCurrentImage(-1)
              }}>
                ×
              </button>
              <figure>
                <img
                  className="mfp-img"
                  src={`${process.env.REACT_APP_IMAGE_PATH}` + images[currentImage].image}
                  style={{ maxHeight: 844 }}
                />
                <figcaption>
                  <div className="mfp-bottom-bar">
                    <div className="mfp-title" />
                    <div className="mfp-counter">1 of {images.length}</div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="mfp-preloader">Loading...</div>
          <button
            title="Previous (Left arrow key)"
            type="button"
            onClick={() => {
              if (currentImage - 1 >= 0 ){
                setCurrentImage(currentImage - 1)
              }
            }}
            className="mfp-arrow mfp-arrow-left mfp-prevent-close"
          />
          <button
            onClick={() => {
              if (currentImage + 1 <= images.length - 1){
                setCurrentImage(currentImage + 1)
              }
            }}
            title="Next (Right arrow key)"
            type="button"
            className="mfp-arrow mfp-arrow-right mfp-prevent-close"
          />
        </div>
      </div>

    )
}

export default CarrouselImagePreview