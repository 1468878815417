import React from "react";

const ImageProduct = ({img, onRemove, id, lock}) => {
    return (
        <div class="single-imagee">
            {lock ? 
                <div className="locked-image">
                    <img src="../../assets/img/icons/lock.svg" alt="" style={{
                        position: "absolute",
                        padding: "10px"
                    }} srcset="" />
                </div>
            
            : null}
            <div class="image-containerr" style={{backgroundImage: `url(${img})`}}/>
            {!lock ? <div class="delete-section-images" onClick={() => onRemove(id)}>
                Supprimer
            </div>: null}
        </div>
    )
}

export default ImageProduct