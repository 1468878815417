import { configure } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../..";
import WhatsappSocials from "../../WhatsappSocials";
import DropDown from "./DropDown";

const SocialsFields = ({id, setData, data, value, safeCheck, locked, configRestriction, edit}) => {
    const [socials, setSocials] = useGlobalState('socials')
    const [showDropDown, setShowDropDown] = useGlobalState('dropdown_id')
    const [currentSocialMedia, setCurrentSocialMedia] = useState("Linkedin")
    const [editComponantId, seteditComponantId] = useGlobalState('editComponantId')

    const handleDelete = (id) => {
        data.splice(id, 1);
        setData([
            ...data
        ])
    }

    useEffect(() => {
        setCurrentSocialMedia(value ? socials[value.social_id].label : "Linkedin")
    }, [value])
    

    const HandleChange = (e) => {
        data[id] = {
            ...data[id],
            [e.target.name]: e.target.value
        }
        setData(data)
        safeCheck()
    }

    useEffect(() => {
      setShowDropDown(-1)
    }, [currentSocialMedia])

    const handleWhatsappChange = (e) => {
        const value = e.target.value
        const name = e.target.name
        data[id] = {
            ...data[id],
            url: name === "prefix" ? value + "-" + data[id].url.split('-')[1] :  data[id].url.split('-')[0] + '-' + value
        }
        setData(data)
        safeCheck()
        
    }

    useEffect(() => {
        if (editComponantId === 3 || !edit){
            function handleClick(e) {
                if (e.target.localName !== 'dropsocial' && e.target.localName !== 'dropvideo'){
                    setShowDropDown(false)
                }
            }
            document.body.addEventListener('click', handleClick);
        
            return () => {
              document.body.removeEventListener('click', handleClick);
            };
        }
      }, []);

      return (
        <div className="single-social " id="">
            <dropsocial className="social-section-delete-info" >
            <div className="social-header">
                <dropsocial className="drop-social" onClick={() => setShowDropDown(id)}>
                        <dropsocial className="text-drop-social-1" onClick={() => setShowDropDown(id)}> {currentSocialMedia} </dropsocial>
                        <dropsocial className="arrow-drop-social" onClick={() => setShowDropDown(id)}>
                            {!locked && !configRestriction ?<img
                                src="../../assets/img/icons/arrow-down.svg"
                                alt=""
                                onClick={() => setShowDropDown(id)}
                            /> : <img src="../../assets/img/icons/lock.svg" alt="" srcset="" />}
                        </dropsocial>
                </dropsocial>
            </div>
            {!locked && !configRestriction ? <dropsocial className="section-top-buttons">
                    
                    <dropsocial className="btn-delete" onClick={() => {
                        handleDelete(id)
                    }}>
                        
                        <img
                        src="../../assets/img/icons/delete.svg"
                        alt=""
                        srcSet=""
                        />
                    </dropsocial>
                    {/* <dropsocial className="btn-drag">
                        
                        <img
                        src="../../assets/img/icons/drag-icon.svg"
                        alt=""
                        srcSet=""
                        />
                    </dropsocial> */}
                </dropsocial>:  null}
            {id === showDropDown && !locked && !configRestriction ? <DropDown  id={id} setData={setData} data={data} setSocial={setCurrentSocialMedia} /> : null}
            </dropsocial>
            <div className="mt-15" />
            
                
                {locked && !value.wasEmpty ? <>
                    <div
                className="underline-input"
                style={{ borderBottom: !locked || value.wasEmpty  ? "0.7px solid #ccb7b7" : "none"  }}
            >
                {value.url}
                </div>
                </> : data[id].social_id === '637235ad60136a9a3e26f784' ? <WhatsappSocials value={value} locked={locked} HandleChange={handleWhatsappChange} /> : <div
                className="underline-input"
                style={{ borderBottom: !locked || value.wasEmpty  ? "0.7px solid #ccb7b7" : "none"  }}
            ><input
                    type="text"
                    className="url"
                    onChange={HandleChange}
                    name="url"
                    placeholder="www"
                    defaultValue={value.url}
                /></div>}
            <div className="mt-15" />
            </div>
           

    )
}

export default SocialsFields