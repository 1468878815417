import React, { useEffect, useState } from "react";
import { useGlobalState } from "..";
import AppServices from "../services/AppServices";

const SettingSlug = (props) => {
  const { setStep2, setStep1, setSlug, slug } = props;

  const [lang] = useGlobalState("lang_tran");
  const [trans] = useGlobalState("onboarding_trans");
  const [user, setUser] = useGlobalState("user");
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");

  const [customLink, setCustomLink] = useState("");
  const [slugError, setSlugError] = useState(false);
  const [safe, setSafe] = useState(false);
  const [safeR, setSafeR] = useState(false);
  const [branding] = useGlobalState("branding");

  const collaborator_id = localStorage.getItem("collaborator_id");

  useEffect(() => {
    if (customLink.length > 2) {
      AppServices.post(
        {
          action: 7,
          data: {
            slug: customLink,
          },
        },
        "/app"
      )
        .then((res) => {
          if (res.message === "available") {
            setSafe(true);
            setSlugError(false);
          }
        })
        .catch((error) => {
          setSafe(false);
          setSlugError(true);
        });
    } else {
      setSafe(false);
    }
  }, [customLink]);

  useEffect(() => {
    if (slug !== collaborator_id && slug) {
      setSafeR(true);
    } else {
      setSafeR(false);
    }
  }, [slug]);

  // Event handlers
  const handleKeyDown = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    const regex = /^[a-zA-Z0-9-_]*$/; // Allow only alphanumeric characters, hyphens, and underscores

    if (regex.test(input)) {
      setCustomLink(input);
    } else {
      const sanitizedInput = input.slice(0, -1); // Remove the last character
      if (regex.test(sanitizedInput)) {
        setCustomLink(sanitizedInput);
      }
    }
  };

  const handleCheckSubmit = () => {
    if (safe && !slugError) {
      AppServices.post(
        {
          action: 3,
          data: {
            slug: customLink,
          },
        },
        "/app"
      ).then((res) => {
        setSlug(customLink);
        seteditComponantId(-1);
        // set steps false
        setStep1(false);
        setStep2(false);
        // clear
        setCustomLink("");

        // update user
        setUser({
          ...user,
          slug: customLink,
        });
      });
    }
  };

  const handleRetablirSubmit = () => {
    AppServices.post(
      {
        action: 5,
        data: {
          slug: "",
        },
      },
      "/app"
    ).then((res) => {
      setSlug(user.collaborator_id);
      seteditComponantId(-1);
      // set steps false
      setStep1(false);
      setStep2(false);
      // clear
    });
  };

  return (
    <section className="fields-content overflow-hidden">
      <img
        src="../../assets/img/icons/arrow-back.svg"
        alt=""
        srcSet=""
        onClick={() => {
          setStep1(false);
        }}
        className="cursor-pointer absolute top-0 left-0 mt-[40px] ml-[20px]"
      />

      <div className="field-title">
        {trans.settings["custom-link-section"].title[lang]}{" "}
      </div>
      <div className="mt-[1rem] text-[17px] font-[500]">
        {trans.settings["custom-link-section"].subtitle[lang]}
      </div>
      <div className="text-[18px] text-[#4E3131] mt-[1rem]">
        {trans.settings["custom-link-section"].lienactuel[lang]}
      </div>
      <div className="text-[16px] text-[#795656]">
        {branding?.vcard_url ? branding?.vcard_url + "/" : "app.getpopcard.com/"}

        <span className="text-[#795656]">{slug}</span>
      </div>

      <div className="text-[18px] text-[#4E3131] mt-[1rem]">
        {trans.settings["custom-link-section"].lien[lang]}
      </div>
      <div className="text-[16px] text-black flex">
        {branding?.vcard_url ? branding?.vcard_url + "/" : "app.getpopcard.com/"}
        <div className="relative">
          <input
            type="text"
            className={`
                    ${slugError ? "errorInputText" : "input-custom-link"}
                    ${safe ? "SucsessInputText" : "input-custom-link"}
                    
                  `}
            value={customLink}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          {safe && (
            <>
              <hr className="bg-[#42A84A] h-[0.5px] w-full border-none" />
              <img
                src="/assets/img/icons/rightcheck.svg"
                alt=""
                className="w-[15px] h-[15px] object-contain absolute right-0 top-1"
              />
            </>
          )}
          {slugError && (
            <>
              <hr className="bg-[#C00808] h-[0.5px] w-full border-none" />
              <img
                src="/assets/img/icons/wrong.svg"
                alt=""
                className="w-[15px] h-[15px] object-contain absolute right-0 top-1"
              />
            </>
          )}
          {!safe && !slugError && (
            <hr className="bg-[#795656] h-[0.5px] w-full border-none" />
          )}
        </div>
      </div>
      {safe && (
        // sucuss text
        <div className="text-[14px] text-[#42A84A] mt-[0.5rem]">
          {trans.settings["custom-link-section"].success[lang]}
        </div>
      )}
      {slugError && (
        // wrong text
        <div className="text-[14px] text-[#C00808] mt-[0.5rem]">
          {trans.settings["custom-link-section"].error[lang]}
        </div>
      )}

      <div className="button-container-setting">
        <button
          className={safe ? "btn-fields btn-active" : "btn-fields"}
          id="btn-next"
          onClick={handleCheckSubmit}
          disabled={!safe}
        >
          {trans.profile.button[lang]}
        </button>
      </div>
      <div className="button-container-rest">
        <button
          className={safeR ? "btn-fields btn-active" : "btn-fields"}
          id="btn-next"
          onClick={handleRetablirSubmit}
          disabled={!safeR}
        >
          {trans.settings["custom-link-section"].button[lang]}
        </button>
      </div>
    </section>
  );
};

export default SettingSlug;
