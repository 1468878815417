import React, { useEffect, useState } from "react";
import { useGlobalState } from "../..";
import Close from "../../componants/Close";
import Header from "../../componants/onboarding/Header";
import SocialsFields from "../../componants/onboarding/Socials/SocialsFields";
import AppServices from "../../services/AppServices";

const Socials = ({ edit }) => {
  const [onboarding, setOnboarding] = useGlobalState("onboarding");
  const [onboardingUser, setOnboardingUser] = useGlobalState("onboarding_user");
  const [safe, setSafe] = useState(false);
  const [data, setData] = useState(
    onboardingUser.socials.length > 0
      ? onboardingUser.socials
      : [
          {
            url: "",
            social_id: "637235ad60136a9a3e26f78e",
          },
        ]
  );
  const [config] = useGlobalState("config");
  const [saveIsEmpty, setSaveIsEmpty] = useState(false);

  const SafeCheck = () => {
    const filter = data.map((social) => {
      if (social.social_id === "637235ad60136a9a3e26f784") {
        if (
          social?.url?.split("-")[1]?.length > 6 &&
          social?.url?.split("-")[0]?.length > 1 &&
          /^\d*$/.test(social?.url?.replace("-", ""))
        ) {
          return true;
        } else {
          return false;
        }
      }
      if (social.url.length === 0) {
        return false;
      } else {
        return true;
      }
    });
    if (filter.includes(false)) {
      setSafe(false);
    } else {
      setSafe(true);
    }
  };

  useEffect(() => {
    if (editComponantId === 3 || !edit) {
      SafeCheck();
    }
  }, [data]);

  useEffect(() => {
    if (config.restrict_socials) {
      
      setData([
        ...config.socials.filter((val) => {
          if (val.url.length === 0) {
            val.wasEmpty = true;
            setSaveIsEmpty(true);
            
            var results = onboardingUser.socials.filter((social_user) => {
              if (social_user.social_id === val.social_id) {
                social_user.wasEmpty = true;
                return social_user;
              }
            });

            if (results.length > 0) {
              return null;
            } else {
              return val;
            }
          } else {
            return val;
          }
        }),
        ...onboardingUser.socials.filter((val) => {
          if (val.url.length === 0) {
            val.wasEmpty = true;
            setSaveIsEmpty(true);
            return val;
          } else if (val.is_admin) {
            return val;
          }
        }),
      ]);
    } else {
      setData(
        onboardingUser.socials.length > 0
          ? onboardingUser.socials
          : [
              {
                url: "",
                social_id: "637235ad60136a9a3e26f78e",
              },
            ]
      );
    }
  }, [onboardingUser.socials, config.restrict_socials]);

 
  const [loading, setLoading] = useState(false);
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");

  const handleSubmit = () => {
    setLoading(true);
    AppServices.post(
      {
        action: 1,
        data: {
          onboarding_step: edit ? undefined : config.show_appointment ? 5 : 6,
          socials: data.filter((social) => {
            delete social.wasEmpty;
            if (!config.socials.includes(social)) {
              return social;
            }
          }),
        },
      },
      "/onboarding"
    ).then((response) => {
      setLoading(false);
      if (edit) {
        seteditComponantId(-1);
      } else {
        setOnboarding({
          ...onboarding,
          step: onboarding.step + (config.show_appointment ? 1 : 2),
        });
      }
    });
  };

  const [lang] = useGlobalState("lang_tran");
  const [onboarding_trans] = useGlobalState("onboarding_trans");
  return (
    <>
      {edit ? <Close /> : <Header />}
      <section className="fields-content">
        <div className="field-title ">
          {onboarding_trans.profile["social-media-section"].title[lang]}
        </div>
        <div className="field-description ">
          {onboarding_trans.profile["social-media-section"].subtitle[lang]}
        </div>
        <div className="mt-40" />
        <div>
          <div
            className="list-social-container "
            id="list-social-container"
            style={{
              height: "auto",
              display: "block",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {data.map((val, i) => {
              return (
                <SocialsFields
                  id={i}
                  safeCheck={SafeCheck}
                  setData={setData}
                  data={data}
                  value={val}
                  locked={config.restrict_socials && val.url.length > 0}
                  configRestriction={config.restrict_socials}
                  edit={edit}
                />
              );
            })}
          </div>
          <div className="mt-40" />
          <div
            className="icon-button-container"
            id="_add_new_social_link"
            onClick={() => {
              setData([
                ...data,
                {
                  url: "",
                  social_id: "637235ad60136a9a3e26f78e",
                },
              ]);
            }}
          >
            {!config.restrict_socials ? (
              <>
                <div className="button-icon">
                  <img
                    src="../../assets/img/icons/plus-circle.svg"
                    alt=""
                    srcSet=""
                  />
                </div>
                <div className="button-text">
                  {
                    onboarding_trans.profile["social-media-section"].button[
                      lang
                    ]
                  }
                </div>
              </>
            ) : null}
          </div>
          <div className="mb-[20rem]" />
        </div>
        <div className="button-container">
          {saveIsEmpty || !config.restrict_socials ? (
            <button
              className={safe ? "btn-fields btn-active" : "btn-fields"}
              id="social-btn-nxt"
              disabled={!safe}
              onClick={handleSubmit}
            >
              {edit ? (
                <img
                  src="/assets/img/loaders/loading.gif"
                  style={{
                    display: loading ? undefined : "none",
                    padding: "2px",
                  }}
                  width="23px"
                  alt=""
                  className="ml-[45%]"
                />
              ) : null}
              {loading
                ? null
                : edit
                ? onboarding_trans?.profile.button[lang]
                : onboarding_trans?.button_next[lang]}
            </button>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default Socials;
export { Socials as SocialsOnboarding };
