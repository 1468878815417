import React, { useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import AppServices from "../../../services/AppServices";
import { useGlobalState } from "../../..";

const CropImage = ({ show, onUpload, onCropState }) => {
  const [lang] = useGlobalState("lang_tran");
  const [image, setImage] = useGlobalState("image");
  const [loading, setLoading] = useState(false);
  const cropperRef = useState(null);
  const [imageElement, setImageElement] = useState();

  const onCrop = () => {
    setImageElement(cropperRef?.current);
  };

  const onSubmit = () => {
    const cropper = imageElement?.cropper;
    const data = cropper.getCroppedCanvas().toDataURL();
    setLoading(true);
    AppServices.upload(
      {
        data,
      },
      "base64",
      "/upload"
    ).then((response) => {
      onCropState(image.id, response.url);
      onUpload(false);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (show) {
      window.scrollTo(0, 0);
    }
  }, [show]);

  const title = {
    fr: "Recadrez votre photo pour un meilleur rendu",
    en: "Crop your photo for a better result",
  };

  const button = {
    fr: "Terminer",
    en: "Finish",
  };

  return (
    <div className="popup-links" style={{ display: show ? "flex" : "none" }}>
      <div className="close-btn-wrapper" onClick={() => onUpload(false)}>
        <button type="button" className="btn-close btn-clos-crop-modal" />
      </div>
      <p className="crop-text" style={{ zIndex: 100000 }}>
        {title[lang]}
      </p>
      <div className="crop-wrapper">
        {show ? (
          <Cropper
            src={image.image}
            style={{
              height: "60vh",
              width: "90vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            crop={onCrop}
            ref={cropperRef}
            viewMode={1}
            aspectRatio={134 / 168}
          />
        ) : null}
      </div>
      <section
        className="contact-buttons"
        style={{ zIndex: 10000000, position: "absolute" }}
      >
        <div className="button-container">
          <button className="btn-fields-terminer btn-active" onClick={onSubmit}>
            <img
              src="/assets/img/loaders/loading.gif"
              style={{ display: loading ? undefined : "none", padding: "2px" }}
              width="23px"
              alt=""
            />
            {button[lang]}
          </button>
        </div>
      </section>
    </div>
  );
};

export default CropImage;
