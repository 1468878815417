import React from "react";
import useTracking from "../hooks/useTracking";
import useWindowOpen from "../hooks/useWindowOpen";

const SocialContainer = ({ val, socials, id}) => {
    const windowOpen = useWindowOpen
    const { sendTracking, trackResult } = useTracking({ action: 112, collaborator_id: id, componant: "Socials", url: val.url, social_id: val.social_id});    

    return (
        <div className="social-item">
            <img
                src={`../assets/img/socials/${socials[val?.social_id]?.icon}`}
                onClick={() => {windowOpen(val.url, val.social_id)
                    sendTracking()
                }}
                width="40px"
                alt={val.id}
                srcSet=""
            />
        </div>
    )
}

export default SocialContainer