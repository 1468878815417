import React, { useEffect, useState } from "react";
import { useGlobalState } from "../..";
import Close from "../../componants/Close";
import Phone from "../../componants/onboarding/Phone";
import AppServices from "../../services/AppServices";

const Details = ({ edit }) => {
  const [onboarding, setOnboarding] = useGlobalState("onboarding");
  const [onboardingUser, setOnboardingUser] = useGlobalState("onboarding_user");
  const [safe, setSafe] = useState(false);
  const [loading, setLoading] = useState(false);
  const onDelete = (e) => {
    const index = parseInt(e.target.dataset.id);

    const phones = onboardingUser.phone.filter((_, i) => i !== index);
    setOnboardingUser({
      ...onboardingUser,
      phone: phones,
    });
  };
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");
  const [config] = useGlobalState("config");

  const handleSubmit = () => {
    setLoading(true);
    AppServices.post(
      {
        action: 1,
        data: {
          phone: onboardingUser.phone,
          first_name: onboardingUser.first_name,
          last_name: onboardingUser.last_name,
          company: onboardingUser.company,
          title: onboardingUser.title,
          temporary_password: onboardingUser.temporary_password,
          onboarding_step: edit ? undefined : 2,

        },
      },
      "/onboarding"
    ).then((response) => {
      setLoading(false);
      if (edit) {
        seteditComponantId(-1);
      } else {
        setOnboarding({
          ...onboarding,
          step: onboarding.step + 1,
        });
      }
    });
  };

  const HandleChange = (e) => {
    if (['company', 'title'].includes(e.target.name)){
      setOnboardingUser({
        ...onboardingUser,
        [e.target.name]: {
          en: e.target.value,
          fr: e.target.value
        },
      });
    }else{
      setOnboardingUser({
        ...onboardingUser,
        [e.target.name]: e.target.value
      });
    }

    console.log(onboardingUser)
  };

  const handleAdd = () => {
    setOnboardingUser({
      ...onboardingUser,
      phone: [
        ...onboardingUser.phone,
        {
          prefix: "212",
          number: "",
        },
      ],
    });
  };

  useEffect(() => {
    if (onboardingUser.phone.length === 0) {
      handleAdd();
    }
  }, [onboardingUser.phone]);

  const checkPhone = (phone) => {
    return phone.filter((value) => {
      if (value.number !== "" && value.prefix !== "") {
        return true;
      }
    });
  };

  useEffect(() => {
    if (editComponantId === 8 || !edit) {
      if (
        onboardingUser.first_name.length > 0 &&
        onboardingUser.last_name.length > 0
      ) {
        setSafe(true);
      } else {
        setSafe(false);
      }
    }
  }, [
    onboardingUser.first_name,
    onboardingUser.last_name,
    JSON.stringify(onboardingUser.phone),
  ]);

  const [lang, setLang] = useGlobalState("lang_tran");
  const [onboarding_trans] = useGlobalState("onboarding_trans");

  const buttonAdd = {
    fr: "Ajouter un Numéro",
    en: "Add a Phone Number",
  };
  return (
    <>
      {edit ? <Close /> : <header className="header-onboarding"></header>}

      <section className="fields-content">
        <div className="field-title ">
          {onboarding_trans.profile["contact-card-section"].title[lang]}
        </div>
        <div className="field-description ">
          {onboarding_trans.profile["contact-card-section"].subtitle[lang]}
        </div>
        <div className="mt-40" />
        <div>
          <div
            className="custom-form-container row "
            style={{ marginBottom: "10rem" }}
          >
            <div className="single-card-input ">
              <label htmlFor="">
                {
                  onboarding_trans.profile["contact-card-section"][
                    "first-name"
                  ][lang]
                }
                *
              </label>
              <input
                type="text"
                name="first_name"
                placeholder={
                  onboarding_trans.profile["contact-card-section"][
                    "first-name"
                  ][lang]
                }
                id="fname"
                onChange={HandleChange}
                defaultValue={onboardingUser.first_name}
              />
            </div>
            <div className="single-card-input">
              <label htmlFor="">
                {" "}
                {
                  onboarding_trans.profile["contact-card-section"]["last-name"][
                    lang
                  ]
                }
                *
              </label>
              <input
                type="text"
                name="last_name"
                placeholder={
                  onboarding_trans.profile["contact-card-section"]["last-name"][
                    lang
                  ]
                }
                id="lname"
                onChange={HandleChange}
                defaultValue={onboardingUser.last_name}
              />
            </div>

            {config.show_company_name ? (
              <div>
                {config.restrict_company_name ? (
                  <div class="single-card-input card-input-full d-flex-h">
                    <label style={{ display: "flex", gap: "4px" }}>
                      <img
                        src="../../assets/img/icons/lock.svg"
                        alt=""
                        srcset=""
                      />
                      {
                        onboarding_trans.profile["contact-card-section"]
                          .company[lang]
                      }
                    </label>
                    <span class="input-txt-disabled">
                      {config.company_name[lang]}
                    </span>
                  </div>
                ) : (
                  <div className="single-card-input card-input-full date-card">
                    <label htmlFor="">
                    Date fin adhésion
                    </label>
                    <input
                      type="date"
                      name="company"
                      placeholder="Date fin adhésion"
                      id="company"
                      onChange={HandleChange}
                      defaultValue={onboardingUser?.company?.[lang]}
                    />
                  </div>
                )}
              </div>
            ) : null}
            <div className="single-card-input card-input-full">
              <label htmlFor="">
                {
                  onboarding_trans.profile["contact-card-section"].position[
                    lang
                  ]
                }
              </label>
              <input
                type="text"
                name="title"
                placeholder="N° of licence"
                id="post_title"
                onChange={HandleChange}
                defaultValue={onboardingUser?.title?.[lang]}
              />
            </div>
            <div className="single-card-input card-input-full">
              <label htmlFor="">
              Type d'adhésion
              </label>
              <input
                type="text"
                name="temporary_password"
                placeholder="Type d'adhésion"
                id="temporary_password"
                onChange={HandleChange}
                defaultValue={onboardingUser?.temporary_password}
              />
            </div>

           
            
          </div>
        </div>
        <div className="button-container">
          <button
            className={safe ? "btn-fields btn-active" : "btn-fields"}
            id="btn-next"
            onClick={handleSubmit}
            disabled={!safe}
          >
            {edit ? (
              <img
                src="/assets/img/loaders/loading.gif"
                style={{
                  display: loading ? undefined : "none",
                  padding: "2px",
                }}
                width="23px"
                alt=""
                className="ml-[45%]"
              />
            ) : null}
            {loading
              ? null
              : edit
              ? onboarding_trans?.profile.button[lang]
              : onboarding_trans?.button_next[lang]}
          </button>
        </div>
      </section>
      <div className="mt-18" />
    </>
  );
};

export default Details;
export { Details as DetailsOnboarding };
