import React, { useState } from "react";

const PopModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div style={{height: "auto"}}>
          <button onClick={() => setIsOpen(true)}>Open Modal</button>
          {isOpen && (
            <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
              <div
                style={{
                  background: 'white',
                  height: '80vh',
                  overflow: 'scroll',
                  transition: 'transform 0.3s ease-out',
                  transform: 'translateY(100%)'
                }}
              >
                Modal content goes here. You can scroll through the content by setting the overflow property to 'scroll'.
              </div>
              <button onClick={() => setIsOpen(false)}>Close Modal</button>
            </div>
          )}
        </div>
      );
}

export default PopModal