import React, { useEffect, useState } from "react";
import { useGlobalState } from "..";
import AddNotFound from "./AddNotFound";
import Carousel from "./Carousel";
import Edit from "./Edit";

const Images = () => {
  const [user] = useGlobalState("user");
  const [config] = useGlobalState("config");
  const [data, setData] = useState([]);
  const [logged, setLogged] = useGlobalState("isLogged");

  useEffect(() => {
    if (config.restrict_images) {
      setData([
        ...config.images,
        ...user.images.filter((image) => {
          if (image.is_admin) {
            return image;
          }
        }),
      ]);
    } else {
      setData([...user.images]);
    }
  }, [user.images, config.images]);

  const [lang] = useGlobalState("lang_tran");
  const [onboarding_trans] = useGlobalState("onboarding_trans");

  const button = {
    fr: "Ajouter vos images",
    en: "Add your images",
  };
  return (
    <>
      {(!config.restrict_images && user.images.length > 0 && config.show_images !== false) ||
      config.restrict_images ? (
        <Carousel items={2} values={data} title="Images" type={1} />
      ) : logged ? (
        <section>
          <div className="edit-section" style={{ marginTop: "3vh" }}>
            <div className="title">
              {onboarding_trans.profile["images-section"].title[lang]}
            </div>
            <Edit value={5} />
          </div>
          <AddNotFound text={button[lang]} action={5} />
        </section>
      ) : null}
    </>
  );
};

export default Images;
