import React, { useEffect, useState } from "react";
import { useGlobalState } from "../..";
import Close from "../../componants/Close";
import Header from "../../componants/onboarding/Header";
import AddLinks from "../../componants/onboarding/Links/AddLinks";
import CorpImage from "../../componants/onboarding/Links/CorpImage";
import AppServices from "../../services/AppServices";

const Links = ({ edit }) => {
  const [show, setShow] = useState(false);
  const [onboarding, setOnboarding] = useGlobalState("onboarding");
  const [onboardingUser, setOnboardingUser] = useGlobalState("onboarding_user");
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");
  const [safe, setSafe] = useState(false);
  const [config] = useGlobalState("config");
  const [lang] = useGlobalState("lang_tran");
  const [trans] = useGlobalState("onboarding_trans");
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (config.restrict_links) {
      setLinks([
        ...config.links,
        ...onboardingUser.links.filter((link) => {
          if (link.is_admin) {
            return link;
          }
        }),
      ]);
    } else {
      setLinks(
        onboardingUser.links.length > 0
          ? [...onboardingUser.links]
          : [
              {
                thumb: "",
                url: "",
                title: {
                  en: "",
                  fr: "",
                },
                description: {
                  en: "",
                  fr: "",
                },
              },
            ]
      );
      if (!(onboardingUser.links.length > 0)) {
        setOnboardingUser({
          ...onboardingUser,
          links: [
            {
              thumb: "",
              url: "",
              title: {
                en: "",
                fr: "",
              },
              description: {
                en: "",
                fr: "",
              },
            },
          ],
        });
      }
    }
  }, [config.links, JSON.stringify(onboardingUser.links)]);

  const SafeCheck = () => {
    const filter = onboardingUser.links.map((link) => {
      if (link.url.length === 0 || link?.title[lang].length === 0) {
        return false;
      } else {
        return true;
      }
    });
    if (filter.includes(false)) {
      setSafe(false);
    } else {
      setSafe(true);
    }
  };

  useEffect(() => {
    if (editComponantId === 4 || !edit) {
      SafeCheck();
    }
  }, [onboardingUser.links]);

  const onAdd = () => {
    setOnboardingUser({
      ...onboardingUser,
      links: [
        ...onboardingUser.links,
        {
          thumb: "",
          url: "",
          title: {
            en: "",
            fr: "",
          },
          description: {
            en: "",
            fr: "",
          },
        },
      ],
    });
  };

  const onCrop = (id, img) => {
    onboardingUser.links[id] = {
      ...onboardingUser.links[id],
      thumb: img,
    };
    setOnboardingUser({
      ...onboardingUser,
      links: [...onboardingUser.links],
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    AppServices.post(
      {
        action: 1,
        data: {
          onboarding_step: edit ? undefined : 8,
          links: onboardingUser.links,
        },
      },
      "/onboarding"
    ).then((response) => {
      setLoading(false);
      if (edit) {
        seteditComponantId(-1);
      } else {
        setOnboarding({
          ...onboarding,
          step: onboarding.step + 1,
        });
      }
    });
  };

  const handleDelete = (id) => {
    if (id > 0) {
      onboardingUser.links.splice(id, 1);
      setOnboardingUser({
        ...onboardingUser,
      });
    }
  };

  const addLien = {
    fr: "Ajouter un lien",
    en: "Add a link",
  };
  return (
    <>
      <CorpImage show={show} onUpload={setShow} onCropState={onCrop} />
      {edit ? <Close /> : <Header />}
      <section className="fields-content" style={{ zIndex: 0 }}>
        <div className="field-title ">
          {trans.profile["links-section"].title[lang]}
        </div>
        <div className="field-description ">
          {trans.profile["links-section"].subtitle[lang]}
        </div>
        <div>
          <div
            className="links-list-container "
            id="links-list"
            style={{
              height: "auto",
              display: "block",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {links.map((val, i) => {
              return (
                <AddLinks
                  onUpload={setShow}
                  id={i}
                  removeLink={handleDelete}
                  value={val}
                  locked={config.restrict_links}
                  data={onboardingUser}
                />
              );
            })}
          </div>
          <div className="mt-40" />
          {!config.restrict_links ? (
            <>
              <div className="icon-button-container" onClick={onAdd}>
                <div className="button-icon">
                  <img
                    src="../../assets/img/icons/plus-circle.svg"
                    alt=""
                    srcSet=""
                  />
                </div>
                <div className="button-text">{addLien[lang]}</div>
              </div>
              <div className="mb-20" />
              <div className="button-container">
                <button
                  className={safe ? "btn-fields btn-active" : "btn-fields"}
                  id="btn-next-links"
                  onClick={handleSubmit}
                  disabled={!safe}
                >
                  {edit ? (
                    <img
                      src="/assets/img/loaders/loading.gif"
                      style={{
                        display: loading ? undefined : "none",
                        padding: "2px",
                      }}
                      width="23px"
                      alt=""
                      className="ml-[45%]"
                    />
                  ) : null}
                  {loading
                    ? null
                    : edit
                    ? trans?.profile.button[lang]
                    : trans?.button_next[lang]}
                </button>
              </div>
            </>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default Links;
export { Links as LinksOnboarding };
