import { useState } from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { v4 } from "uuid";

export default function CopyToClipboard({ value , color }) {
    const [copied, setCopied] = useState(false);
    async function handleClick() {
        _copy(value);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 700);
    }
    return (
        <span
            onClick={() => handleClick()}
            style={{
                display: "inline-block",
                flexShrink: 0,
                cursor: "pointer",
            }}
            title="Copier dans le presse-papier"
        >
            {copied ? (
                <CheckRoundedIcon fontSize="small" 
                style={{ color: color }}
                />
            ) : (
                <ContentCopyIcon fontSize="small" 
                style={{ color: color }}
                />
            )}
        </span>
    );
}

function _copy(text) {
    const id = "_copy_input" + v4();
    let input = document.createElement("input");
    input.value = text;
    input.id = id;
    input.style.position = "absolute";
    input.style.top = "-100px";
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    document.querySelector(`#${id}`)?.remove();
}
