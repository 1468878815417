import React, { useEffect, useState } from "react";
import { useGlobalState } from "../..";
import Close from "../../componants/Close";
import Header from "../../componants/onboarding/Header";
import Image from "../../componants/onboarding/Image/Image";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AppServices from "../../services/AppServices";
import ProductItem from "../../componants/ProductItem";
import AddProductModal from "../../componants/Modal/AddProductModal";

const ProductServices = ({ edit }) => {
  const [onboardingUser, setOnboardingUser] = useGlobalState("onboarding_user");
  const [user, setUser] = useGlobalState("user");
  const [data, setData] = useState([]);
  const [safe, setSafe] = useState(false);
  const [onboarding, setOnboarding] = useGlobalState("onboarding");
  const [config] = useGlobalState("config");
  const [addPop, setAddPop] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  useEffect(() => {
    const filteredProducts = onboardingUser?.products.filter(
      (product) => product?.pictures 
    );

    setData([...filteredProducts]);
    setProduct([...filteredProducts]);
  }, [onboardingUser]);

  useEffect(() => {
    if (data.length > 0 || data.length === 0) {
      setSafe(true);
    } else {
      setSafe(false);
    }
  }, [data]);

  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");

  const onDelete = (index) => {
    data.splice(index, 1);
    setData([...data]);
  };

  const [lang, setLang] = useGlobalState("lang_tran");
  const [onboarding_trans] = useGlobalState("onboarding_trans");

  function getTranslations() {
    const translations = {
      en: {
        title: "Products and services",
        description: `Highlight your products and services and list all the platforms where your meetings can get them`,
      },
      fr: {
        title: "Produits et services",
        description: `Mettez en valeur vos produits et services et listez l'ensemble des plateformes sur lesquelles vos rencontres peuvent les obtenir`,
      },
    };
    return translations[lang];
  }

  const { title, description } = getTranslations();
  const [indexId, setIndexId] = useState(0);
  const [changeTitle, setChangeTitle] = useState(false);
  const handleAddnewProduct = () => {
    setAddPop(true);
    setOnboardingUser({
      ...onboardingUser,
      products: [
        ...onboardingUser.products,
        {
          title: {
            en: "",
            fr: "",
          },
          price: {
            amount: 0,
            currency: "MAD",
          },
          links: {
            url: "",
            store: "6405b55177dbb3e4441f7985",
          },
          pictures: [],
        },
      ],
    });
    setIndexId(data.length);
    setIsEditing(true);
    setChangeTitle(false);
  };

  const handleDelete = (index) => {
    setData(
      data.filter((item, i) => {
        return i !== index;
      })
    );
    // update onBordingUser
    setOnboardingUser({
      ...onboardingUser,
      products: data.filter((item, i) => {
        return i !== index;
      }),
    });
  };

  const handleSubmit = () => {
    if (edit) {
      setLoading(true);
      AppServices.post(
        {
          action: 5,
          data: {
            products: data,
            onboarding_step: edit ? undefined : 6,
          },
        },
        "/app"
      )
        .then((res) => {
          setLoading(false);
          seteditComponantId(-2);
          // udate user
          setUser({
            ...user,
            products: data,
          });
        })
        .catch((err) => {});
    } else {
      setOnboarding({
        ...onboarding,
        step: onboarding.step + 1,
      });
    }
  };

  useEffect(() => {
    if (config.restrict_products) {
      setProduct([
        ...config.products,
        ...onboardingUser.products.filter((product) => {
          if (product.is_admin) {
            return product;
          }
        }),
      ]);
    } else {
      const filteredProducts = onboardingUser?.products.filter(
        (product) => product?.pictures && product?.links.length > 0
      );
      setProduct([...filteredProducts]);
    }
  }, [config, onboardingUser]);

  return (
    <>
      {edit ? <Close /> : <Header />}
      <section className="fields-content">
        <div className="field-title">{title}</div>
        <div
          className="text-[17px] font-[500] mt-[1rem] "
          style={{ wordWrap: "break-word" }}
        >
          {description}
        </div>
        <div className="flex flex-col gap-5 mt-[4rem]">
          {product?.map((item, index) => (
            <ProductItem
              key={index}
              data={item}
              index={index}
              onDelete={onDelete}
              setIndexId={setIndexId}
              setAddPop={setAddPop}
              setIsEditing={setIsEditing}
              setChangeTitle={setChangeTitle}
              handleDelete={() => handleDelete(index)}
              locked={config.restrict_products}
            />
          ))}
        </div>
        {(!config.restrict_products && (
          <div className="flex gap-2 mt-[2rem] " onClick={handleAddnewProduct}>
            <AiOutlinePlusCircle className="text-2xl cursor-pointer text-primaryP" />
            <span className="cursor-pointer text-[#795656] font-[500]">
              {
                onboarding_trans.profile["products-and-services-section"]
                  .button[lang]
              }
            </span>
          </div>
        )) ||
          null}
        <div className="h-[20rem]" />
        {(!config.restrict_products && edit) || !edit ? (
          <div className="button-container">
            <button
              className={safe ? "btn-fields btn-active" : "btn-fields"}
              onClick={handleSubmit}
              id="btn-next"
              disabled={!safe}
            >
              {edit ? (
                <img
                  src="/assets/img/loaders/loading.gif"
                  style={{
                    display: loading ? undefined : "none",
                    padding: "2px",
                  }}
                  width="23px"
                  alt=""
                  className="ml-[45%]"
                />
              ) : null}
              {loading
                ? null
                : edit
                ? onboarding_trans?.profile.button[lang]
                : onboarding_trans?.button_next[lang]}
            </button>
          </div>
        ) : null}
        {/* add Sauvegarder button  */}
        {addPop && (
          <AddProductModal
            addPop={addPop}
            setAddPop={setAddPop}
            isEditing={isEditing}
            indexId={indexId}
            dataprev={data}
            setDataPrev={setData}
            edit={edit}
            setChangeTitle={setChangeTitle}
            changeTitle={changeTitle}
          />
        )}
      </section>
    </>
  );
};

export default ProductServices;
export { ProductServices as ProductServicesOnboarding };
