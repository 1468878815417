import { useState } from "react";
import "../styles/phonepickes.scss";

export default function PhonePicker(props) {
  const [phoneNumber, setPhoneNumber] = useState({ code: "+212", number: "" });

  const { prifix, onChange, name, handlePrifixChange, completer, errors } =
    props;

  const handleCodeChange = (event) => {
    setPhoneNumber({ ...phoneNumber, code: event.target.value });
  };

  const handleNumberChange = (event) => {
    setPhoneNumber({ ...phoneNumber, number: event.target.value });
  };

  return (
    <div className="PhonePiker">
      <div className="prefix">
        <input
          id="phone-pickerr"
          className={errors.phone ? "form-inputt inputErrorr " : "form-inputt"}
          value={prifix}
          onChange={(handleCodeChange, handlePrifixChange)}
        />
      </div>

      <div className="phoneNumber">
        <input
          id="phone-pickerr"
          className={errors.phone ? "form-inputt inputErrorr " : "form-inputt"}
          onChange={onChange}
          name={name}
        />
      </div>
    </div>
  );
}
