import React, { useEffect, useState } from "react";
import { useGlobalState } from "../..";
import Close from "../../componants/Close";
import Header from "../../componants/onboarding/Header";
import AppServices from "../../services/AppServices";

const About = ({ edit }) => {
  const [lang] = useGlobalState("lang_tran");
  const [onboarding_trans] = useGlobalState("onboarding_trans");
  const [onboarding, setOnboarding] = useGlobalState("onboarding");
  const [onboardingUser, setOnboardingUser] = useGlobalState("onboarding_user");
  const [config, setConfig] = useGlobalState("config");
  const [loading, setLoading] = useState(false);
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");
  const [safe, setSafe] = useState(false);

  useEffect(() => {
    const langCheck = onboardingUser?.about?.[lang]?.length > 0;
    if (editComponantId === 0 || !edit) {
      if (langCheck) {
        setSafe(true);
      } else {
        setSafe(false);
      }
    }
  }, [onboardingUser.about]);

  const handleSubmit = () => {
    setLoading(true);
    AppServices.post(
      {
        action: 1,
        data: {
          onboarding_step: edit ? undefined : 4,
          about: onboardingUser.about,
        },
      },
      "/onboarding"
    ).then((response) => {
      setLoading(false);
      if (edit) {
        seteditComponantId(-1);
      } else {
        setOnboarding({
          ...onboarding,
          step: onboarding.step + 1,
        });
      }
    });
  };

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setOnboardingUser((prevState) => {
      return {
        ...prevState,
        [name]: {
          ...(prevState[name] || {}),
          [lang]: value,
          [lang === "en" ? "fr" : "en"]:
            prevState[name]?.[lang === "en" ? "fr" : "en"] || "",
        },
      };
    });
  };

  const bonjour = {
    en: "Hello, …",
    fr: "Bonjour, …",
  };
  return (
    <>
      {edit ? <Close /> : <Header />}
      <section className="fields-content">
        <div className="field-title ">
          {onboarding_trans.profile["about-section"].title[lang]}
        </div>
        <div className="field-description ">
          {onboarding_trans.profile["about-section"].subtitle[lang]}
        </div>
        <div className="mt-40" />
        <div>
          {/* <div className="locked-about">
                <img src="../../assets/img/icons/lock.svg" alt="" srcset="" />
            </div> */}
          <textarea
            name="about"
            id="about"
            className="long-textarea "
            placeholder={bonjour[lang]}
            cols={30}
            rows={10}
            defaultValue={
              config?.restrict_about
                ? config?.about[lang] || ""
                : (onboardingUser?.about && onboardingUser.about[lang]) || "" // set a default value if about[lang] doesn't exist
            }
            disabled={config.restrict_about}
            onChange={HandleChange}
          />
        </div>
        <div className="button-container">
          {!config.restrict_about || !edit ? (
            <button
              className={
                safe || config.restrict_about
                  ? "btn-fields btn-active"
                  : "btn-fields"
              }
              id="btn-next"
              onClick={handleSubmit}
              disabled={!safe && !config.restrict_about}
            >
              {edit ? (
                <img
                  src="/assets/img/loaders/loading.gif"
                  style={{
                    display: loading ? undefined : "none",
                    padding: "2px",
                  }}
                  width="23px"
                  alt=""
                  className="ml-[45%]"
                />
              ) : null}
              {loading
                ? null
                : edit
                ? onboarding_trans?.profile.button[lang]
                : onboarding_trans?.button_next[lang]}
            </button>
          ) : null}
        </div>
      </section>
    </>
  );
};
export default About;
export { About as AboutOnboarding };
