import React from "react";

const VideosDropDown = ({ id, handleDropDown, locked }) => {
  return (
    <>
      {!locked ? (
        <dropvideo
          className="dropdown-social-list-container"
          style={{
            display: "block",
            height: "96px",
            marginTop: "6vh",
            position: "absolute",
          }}
        >
          <div
            className="single-social-dropdown"
            onClick={() => handleDropDown(id, 1)}
          >
            <div className="icon-social" name="type">
              <img src="/assets/img/icons/youtube.svg" alt="" srcSet="" />
            </div>
            <div className="text-social"> Youtube </div>
          </div>
          <div className="divider divider-social" />
          <div
            className="single-social-dropdown"
            onClick={() => handleDropDown(id, 2)}
          >
            <div className="icon-social">
              <img src="/assets/img/icons/vimeo.svg" alt="" srcSet="" />
            </div>
            <div className="text-social"> Vimeo </div>
          </div>
        </dropvideo>
      ) : null}
    </>
  );
};

export default VideosDropDown;
