import React, { useEffect, useState } from "react";
import "../../styles/notificationpopup.scss";
import { BsArrowRight } from "react-icons/bs";
import closesvg from "../../assets/closesss.svg";
import PhonePicker from "../PhonePicker";
import AppServices from "../../services/AppServices";
import { FaCameraRetro } from "react-icons/fa";
import NotificationDone from "./NotificationDone";
import { useParams } from "react-router";
import useTracking from "../../hooks/useTracking";
import {
  completerFormSchema,
  validateCompleterForm,
} from "../Validations/validatecompleter";
import { useGlobalState } from "../..";

const errorsSchema = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
};
const NotificationPopup = ({
  show,
  close,
  handleClick,
  traking,
  resId,
  user,
  dataProfile,
  dataRes,
}) => {
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);

  const [stream, setStream] = useState(null);
  const [open, setOpen] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [uploadPercentStr, setUploadPercentStr] = useState("0%");
  const [done, setDone] = useState(false);
  const [config, setConfig] = useState({});
  const { id } = useParams();
  const [completer, setcompleter] = useState({
    card: image?.url,
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    identified_in: "",
    tracking: "",
    identification_methode: 0,
  });

  const [prifix, setPrifix] = useState("+212");

  const handlePrifixChange = (e) => {
    setPrifix(e.target.value);
  };

  const handleChange = (e) => {
    setcompleter((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const updated_by_user = dataRes?.updated_by_user;
  const updated_in_auth = dataRes?.updated_in_auth;
  const [updatedbyuser, setUpdatedByUser] = useState(updated_by_user);
  const [updatedinauth, setUpdatedInauth] = useState(updated_in_auth);

  useEffect(() => {
    if (!open) {
      setStream(null);
    }
  }, [open]);

  function openCamera() {
    if (open) return;
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then((s) => {
        setStream(s);
        setOpen(true);
        setShowIcon(true);
      })
      .catch((err) => setError(err));
  }

  function closeCamera() {
    if (!stream) return;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    setStream(null);
    setOpen(false);
  }

  function handleTakePicture() {
    if (!stream) return;
    const video = document.querySelector("video");
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      const file = new File([blob], "picture.jpg", {
        type: "image/jpeg",
      });

      upload(file);
    });

    // close the camera after taking the pic
    setOpen(false);
    setShowIcon(false);
    closeCamera();
  }

  function upload(file) {
    if (uploadPercent > 0) return;
    if (!file) return;

    AppServices.upload(
      file,
      "file",
      "/upload",
      {
        "Content-Type": file.type,
      },
      handleUploadProgress
    )
      .then((res) => {
        setUploadPercent(0);
        setUploadPercentStr("0%");
        // setImage
        setImage(res);
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  useEffect(() => {
    if (image?.url !== undefined) {
      if (
        !updatedbyuser &&
        !updatedinauth &&
        !updated_by_user &&
        !updated_in_auth
      ) {
        createCompleter(completer);
        setcompleter({
          card: "",
          email: "",
          first_name: "",
          last_name: "",
          phone: "",
          identified_in: "",
          tracking: "",
          identification_methode: 0,
        });
      } else {
        setDone(true);
      }
    }
  }, [image]);

  function handleUploadProgress(e) {
    let percentage = (e.progress * 100).toFixed(2);
    const roundCircum = 2 * 70 * Math.PI;
    const svgPercentage = (percentage * roundCircum) / 100;
    setUploadPercent(svgPercentage);
    setUploadPercentStr(Math.floor(percentage - 1) + "%");
  }

  const handleClose = () => {
    close();
    setDone(false);
  };

  const [branding] = useGlobalState("branding");

  const createCompleter = (data) => {
    AppServices.post(
      {
        action: 0,
        data: {
          id: localStorage.getItem("completerId"),
          card: image?.url,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          phone: data.phone ? prifix + "-" + data.phone : "",
          identified_in: user?._id,
          tracking: traking?.id,
          identification_methode: 0,
          updated_by_user: true,
          logo: branding?.logo
            ? branding?.logo
            : undefined,
          mailto: branding?.mailto ? branding?.mailto : undefined,
          domain: branding?.domain ? branding?.domain : undefined,
          server: branding?.server ? branding?.server : undefined,
          footer: "",
        },
      },
      "/identify"
    )
      .then((res) => {
        res.status === 200 && setDone(true);

        // update updatedbyuser
        setUpdatedByUser(true);

        // clear data
        setcompleter({
          card: "",
          email: "",
          first_name: "",
          last_name: "",
          phone: "",
          identified_in: "",
          tracking: "",
          identification_methode: 0,
        });
        // setImage to null
        setImage("");
        // setPrifix st default
        setPrifix("+212");
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  async function isCompleterValid(completer) {
    setErrors({ ...errorsSchema });
    try {
      await completerFormSchema.validate(completer);
      return true;
    } catch ({ path, message }) {
      setErrors((prev) => ({
        ...prev,
        [path]: message,
      }));
      return false;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateCompleterForm(completer);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (
        !updatedbyuser &&
        !updatedinauth &&
        !updated_by_user &&
        !updated_in_auth
      ) {
        createCompleter(completer);
        setcompleter({
          card: "",
          email: "",
          first_name: "",
          last_name: "",
          phone: "",
          identified_in: "",
          tracking: "",
          identification_methode: 0,
        });
      } else {
        setDone(true);
      }

      setImage("");
    }

    // Reset the form or perform any other cleanup here
  };

  const [lang] = useGlobalState("lang_tran");
  const [trans] = useGlobalState("onboarding_trans");
  return (
    <div
      className={
        show && user.form ? "popup-background" : "popup-background--hide"
      }
    >
      <div
        className={`NotificationPopup ${
          show && user.form ? "slide-up" : "slide-down NotificationPopup--hide "
        }`}
      >
        <img
          src={closesvg}
          className="close-icon"
          alt="hello"
          onClick={handleClose}
        />
        {!done ? (
          <div className="NotificationPopup__content">
            <div className="NotificationPopup__content__title">
              <div className="avatar-p">
                <img
                  src={
                    user.profile_picture
                      ? `${process.env.REACT_APP_IMAGE_PATH}/${user.profile_picture}`
                      : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
                  }
                  alt="avatar"
                  className="avatar"
                />
              </div>

              <p className="pargh">
                {trans.profile["share-information-form"].title[lang]}{" "}
                {user.first_name} !
              </p>
            </div>
            <div className="NotificationPopup__content__body">
              <div className="inputWrapper">
                <input
                  type="text"
                  placeholder={
                    trans.profile["share-information-form"].pre[lang]
                  }
                  className={errors.first_name ? "input inputError" : "input"}
                  name="first_name"
                  onChange={handleChange}
                  value={completer?.first_name}
                />

                {errors.first_name && (
                  <p className="errorPopUP">{errors.first_name}</p>
                )}
              </div>
              <div className="inputWrapper">
                <input
                  type="text"
                  placeholder={
                    trans.profile["share-information-form"].nom[lang]
                  }
                  className={errors.last_name ? "input inputError" : "input"}
                  name="last_name"
                  onChange={handleChange}
                  value={completer?.last_name}
                />

                {errors.last_name && (
                  <p className="errorPopUP">{errors.last_name}</p>
                )}
              </div>
              <div className="inputWrapper">
                <input
                  type="email"
                  placeholder="Email"
                  className={errors.email ? "input inputError" : "input"}
                  name="email"
                  onChange={handleChange}
                  value={completer?.email}
                />

                {errors.email && <p className="errorPopUP">{errors.email}</p>}
              </div>
              <div className="inputWrapper">
                <PhonePicker
                  name="phone"
                  onChange={handleChange}
                  handlePrifixChange={handlePrifixChange}
                  prifix={prifix}
                  completer={completer}
                  errors={errors}
                />

                {errors.phone && <p className="errorPopUP">{errors.phone}</p>}
              </div>
              <div className="buttonWrapper">
                <button className="btn btn-primary" onClick={handleSubmit}>
                  {trans.profile["share-information-form"].button[lang]}
                </button>
              </div>
              {dataProfile.procpect_identification === 1 ? (
                <div className="textWrapper" onClick={openCamera}>
                  <p className="text">
                    {trans.profile["share-information-form"].partager[lang]}
                  </p>
                  <BsArrowRight className="icon" />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="Box">
              {showIcon ? (
                <FaCameraRetro
                  className="camera-icon z-[999]"
                  onClick={handleTakePicture}
                />
              ) : (
                ""
              )}

              <input
                type="file"
                className="file-input"
                onChange={(e) => upload(e.target.files[0])}
              />
              <div className="camera">
                {open ? (
                  <video
                    autoPlay
                    playsInline
                    muted
                    ref={(ref) => {
                      if (ref) {
                        ref.srcObject = stream;
                      }
                    }}
                    className="z-[998]"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="NotificationPopup__content">
            <NotificationDone close={handleClose} data={user} />
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationPopup;
