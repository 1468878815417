import React, { useEffect, useState } from "react";
import { useGlobalState } from "..";
import useVCard from "../hooks/useVCard";
import useWindowScrollPosition from "../hooks/useWindowScrollPosition";
import AppServices from "../services/AppServices";
import NotificationPopup from "./Modal/NotificationPopup";

const FloatButton = ({ id, traking, user, dataProfile }) => {
  const scrollPosition = useWindowScrollPosition();

  const [dataRes, setDataRes] = useState("");
  const [lang] = useGlobalState("lang_tran");
  const [trans] = useGlobalState("onboarding_trans");
  const [branding] = useGlobalState("branding")


  const createCompleter = (data) => {
    console.log("branding", branding)
    AppServices.post(
      {
        action: 1,
        data: {
          card: "",
          email: "",
          first_name: "",
          last_name: "",
          phone: "",
          identified_in: user?._id,
          tracking: traking?.id,
          identification_methode: 0,
          user_token: localStorage.getItem("user_token"),
          checked: branding?.whatsapp_notifications != (undefined || null) ? !branding.whatsapp_notifications : false
        },
      },
      "/identify"
    )
      .then((res) => {
        // console.log(res);
        setDataRes(res);
        // stored in localstorege
        localStorage.setItem("completerId", res.id);
        // store updated_by_user
        localStorage.setItem("updated_by_user", res.updated_by_user);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const [loading, setLoading] = useState(false);
  const handleClick = useVCard(
    () => id,
    setLoading,
    "MainButton",
    traking,
    user,
    createCompleter
  );

  const [popup, setPopup] = useState(false);
  const handlePopupOpen = () => {
    handleClick();

    // setTimeout for 500ms
    setTimeout(() => {
      setPopup(true);
    }, 1500);
  };

  const [button, setButton] = useState({
    fontSize: 15,
    visibility: "visible",
    transition:
      "visibility 0s ease 0s, opacity 0.2s linear 0s, color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s",
    width: "100%",
    marginRight: 21,
    background: "black",
    color: "white",
    border: "none",
  });

  const shared_style = {
    fontSize: "15px",
    visibility: "visible",
    opacity: 1,
    transition:
      "visibility 0s, opacity 0.2s linear, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    width: "100%",
    marginRight: "21px",
  };

  const button_styles = {
    style1: {
      ...shared_style,
      background: "white",
      color: "black",
      border: "none",
    },
    style2: {
      ...shared_style,
      background: "black",
      color: "white",
    },
    style3: {
      ...shared_style,
      visibility: "hidden",
      opacity: 0,
    },
  };

  useEffect(() => {
    setTimeout(() => {
      try {
        var element_ = document.getElementById("show-btn");
        var a = element_.getBoundingClientRect();

        var footer_ = document.getElementById("footer-start");
        var f = footer_.getBoundingClientRect();

        if (a.y + 60 < window.scrollY) {
          if (f.y - f.height - 80 < window.scrollY) {
            setButton(button_styles.style1);
          } else {
            setButton(button_styles.style2);
          }
        } else {
          setButton(button_styles.style3);
        }
      } catch (error) {
        // console.log("error");
      }
    }, 0);
  }, [scrollPosition]);

  return (
    <>
      <section className="contact-button  max-w-[586px]">
        <button
          className="btn btn-primary flex text-center items-center justify-center"
          style={button}
          id="btn-get-contact-2"
          onClick={handlePopupOpen}
        >
          <img
            src="/assets/img/loaders/loading.gif"
            style={{ display: loading ? undefined : "none" }}
            width="23px"
            alt="hello"
          />
          {loading ? null : trans.addToContact[lang]}
        </button>
      </section>
      <NotificationPopup
        handleClick={handleClick}
        show={popup}
        close={() => setPopup(false)}
        traking={traking}
        user={user}
        dataProfile={dataProfile}
        dataRes={dataRes}
      />
    </>
  );
};

export default FloatButton;
