import React, { useEffect, useState } from "react";
import { useGlobalState } from "../..";
import AppServices from "../../services/AppServices";

const ConfirmEmail = () => {
  const [email, setEmail] = useState("Loading...");
  const [onboarding, setOnboarding] = useGlobalState("onboarding");
  const [resend, setResend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lang] = useGlobalState("lang_tran");
  const [trans] = useGlobalState("onboarding_trans");
  const [message, setMessage] = useState(
    trans["email-verification-section"].title2[lang]
  );
  useEffect(() => {
    AppServices.post(
      {
        action: 2,
      },
      "/onboarding"
    )
      .then((response) => {
        setEmail(response.data[0].email);
      })
      .catch(() => {
        window.location = "/login";
      });
  }, []);

  const SendConfirmationEmail = () => {
    setLoading(true);
    AppServices.post(
      {
        action: 0,
      },
      "/email"
    ).then((response) => {
      setLoading(false);
      setMessage(trans["email-verification-section"].title[lang]);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setResend(true);
    }, 3000);
  }, []);

  const back = () => {
    AppServices.post(
      {
        action: 1,
        data: {
          onboarding_step: -1,
          email: "",
        },
      },
      "/onboarding"
    ).then((response) => {
      setOnboarding({
        ...onboarding,
        step: onboarding.step - 1,
      });
    });
  };

  return (
    <>
      <header className="header-onboarding">
        <img
          src="../../assets/img/icons/arrow-back.svg"
          alt=""
          srcSet=""
          onClick={back}
        />
      </header>
      <section className="fields-content">
        <div className="field-title ">
          {trans["email-verification-section"].title1[lang]}
        </div>
        <div className="mt-40" style={{ marginTop: 100 }} />
        <div className="field-description text-success " id="response">
          ✅ {message} {email}.
        </div>
        <div className="field-description ">
          {trans["email-verification-section"].subtitle[lang]}
        </div>
        <div>
          <button
            className={resend ? "btn-active btn-fields" : "btn-fields"}
            disabled={!resend}
            id="btn-next"
            style={{
              display: "flex",
              gap: "4px",
              justifyContent: "center",
              alignItems: "center",
              bottom: "92px",
            }}
            onClick={SendConfirmationEmail}
          >
            <img
              src="/assets/img/loaders/loading.gif"
              style={{ display: loading ? undefined : "none" }}
              width="23px"
            />
            {trans["email-verification-section"].button[lang]}
          </button>
        </div>
      </section>
    </>
  );
};

export default ConfirmEmail;
