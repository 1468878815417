import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGlobalState } from "../..";
import LoadingPage from "../../componants/LoadingPage";
import AppServices from "../../services/AppServices";
import About from "./About";
import Auth from "./Auth";
import Calendly from "./Calendly";
import ConfirmEmail from "./ConfirmEmail";
import Details from "./Details";
import Images from "./Images";
import Links from "./Links";
import ProductServices from "./ProductServices";
import ProfessionalInfo from "./ProfessionalInfo";
import Socials from "./Socials";
import Videos from "./Videos";

const Home = () => {
  const [onboarding, setOnboarding] = useGlobalState("onboarding");
  const [onboardingUser, setOnboardingUser] = useGlobalState("onboarding_user");
  const [config, setConfig] = useGlobalState("config");
  const [lang, setLang] = useGlobalState("lang_tran");
  const [currentOnboarding, setCurrentOnboarding] = useState(<LoadingPage />);
  const [branding, setBranding] = useGlobalState("branding");

  const { id } = useParams();
  const steps = [
    <Auth />,
    <ConfirmEmail />,
    <Details />,
    <ProfessionalInfo />,
    <About />,
    <Socials />,
    <ProductServices />,
    <Calendly />,
    <Links />,
    <Images />,
    <Videos />,
  ];

  useEffect(() => {
    AppServices.post(
      {
        action: 11,
        data: {
          id: id,
        },
      },
      "/app"
    ).then((response) => {
      console.log("branding", response.data[0]);
      setBranding(response?.data[0]);
    });
  }, []);

  useEffect(() => {
    AppServices.post(
      {
        action: 0,
        data: {
          id: id,
        },
      },
      "/app"
    ).then((response) => {
      if (response.profile_activated) {
        setConfig(response["profile"][0]);
      }
      delete response["profile"];
      setOnboardingUser(response);
      setLang(
        response?.language
          ? response?.language
          : localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "fr"
      );
    });

    if (onboarding.step !== -2) {
      setCurrentOnboarding(steps[onboarding.step + 1]);
    }
  }, [onboarding.step]);

  useEffect(() => {
    // console.log("token", localStorage.getItem('token'))

    AppServices.post(
      {
        action: 0,
        data: {
          id: id,
        },
      },
      "/collaborators"
    ).then((response) => {
      response = response.data[0];
      if (
        (localStorage.getItem("token") === null || undefined || "") &&
        response.onboarding_step > 0
      ) {
        window.location = "/login";
      }

      if (
        localStorage.getItem("collaborator_id") !== id &&
        response.onboarding_step > 0
      ) {
        localStorage.clear();
        window.location = `/login`;
      }
      if (response.onboarding_step === 10 && response.confirmed) {
        window.location = `/profile/${id}`;
      }

      setOnboarding({
        ...onboarding,
        step: response.onboarding_step,
        confirmed: response.confirmed,
      });
    });
  }, []);

  return currentOnboarding;
};

export default Home;
