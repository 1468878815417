import React from "react";

const WhatsappSocials = ({ value, locked, HandleChange }) => {
  return (
    <div className="whatsapp-underline">
      <div
        className="underline-input"
        style={{
          borderBottom:
            !locked || value.wasEmpty ? "0.7px solid #ccb7b7" : "none",
        }}
      >
        <input
          type="text"
          className="url"
          onChange={HandleChange}
          name="url"
          placeholder="+"
          value={"+"}
          disabled={true}
          style={{ width: "20px" }}
        />
      </div>
      <div className="number-wrapper-whatsapp">
        <div
          className="underline-input"
          style={{
            borderBottom:
              !locked || value.wasEmpty ? "0.7px solid #ccb7b7" : "none",
          }}
        >
          <input
            type="text"
            className="url"
            onChange={HandleChange}
            name="prefix"
            placeholder="212"
            defaultValue={value.url.split("-")[0]}
            style={{ width: "4.5vh" }}
            inputMode="numeric"
            pattern="[0-9]*"
          />
        </div>
        <div
          className="underline-input inputWhatssapinput"
          style={{
            borderBottom:
              !locked || value.wasEmpty ? "0.7px solid #ccb7b7" : "none",
          }}
        >
          <input
            type="text"
            className="url"
            onChange={HandleChange}
            name="number"
            style={{ width: "12vh" }}
            placeholder="60000000"
            pattern="[0-9]*"
            inputMode="numeric"
            defaultValue={
              value.url.split("-")[1] !== undefined
                ? value.url.split("-")[1]
                : ""
            }
          />
        </div>
      </div>
    </div>
  );
};

export default WhatsappSocials;
