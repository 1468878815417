import React, { useEffect, useState } from "react";
import { useGlobalState } from "../..";
import Close from "../../componants/Close";
import Header from "../../componants/onboarding/Header";
import useSuggestPlaces from "../../hooks/useSuggestPlaces";
import AppServices from "../../services/AppServices";

const ProfessionalInfo = ({ edit }) => {
  const [onboarding, setOnboarding] = useGlobalState("onboarding");
  const [onboardingUser, setOnboardingUser] = useGlobalState("onboarding_user");
  const [safe, setSafe] = useState(false);
  const [config] = useGlobalState("config");

  const HandleChange = (e) => {
    if (
      e.target.name === "professional_email" &&
      config.restrict_contact_email_ext
    ) {
      setOnboardingUser({
        ...onboardingUser,
        [e.target.name]: e.target.value + "@" + config.contact_email_ext,
      });
    } else {
      setOnboardingUser({
        ...onboardingUser,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    if (editComponantId === 1 || !edit) {
      if (
        onboardingUser.professional_email.length > 0 ||
        onboardingUser.website.length > 0 ||
        onboardingUser.address.length > 0
      ) {
        setSafe(true);
      } else {
        setSafe(false);
      }
    }
  }, [
    onboardingUser.professional_email,
    onboardingUser.website,
    onboardingUser.address,
  ]);

  const suggestPlaces = useSuggestPlaces(document.getElementById("address"));

  useEffect(() => {
    setOnboardingUser({
      ...onboardingUser,
      address: suggestPlaces,
    });
  }, [suggestPlaces]);

  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    setLoading(true);
    AppServices.post(
      {
        action: 1,
        data: {
          professional_email: onboardingUser.professional_email,
          website: onboardingUser.website,
          address: onboardingUser.address,
          onboarding_step: edit ? undefined : config.show_about ? 3 : 4,
        },
      },
      "/onboarding"
    ).then((response) => {
      setLoading(false);
      if (edit) {
        seteditComponantId(-1);
      } else {
        setOnboarding({
          ...onboarding,
          step: onboarding.step + (config.show_about ? 1 : 2),
        });
      }
    });
  };

  const [lang] = useGlobalState("lang_tran");
  const [translate] = useGlobalState("onboarding_trans");

  const AdresseEmail = {
    fr: "Adresse email",
    en: "Email address",
  };

  const SiteWeb = {
    fr: "Site web",
    en: "Website",
  };

  return (
    <>
      {edit ? <Close /> : <Header />}
      <section className="fields-content">
        <div className="field-title ">
          {translate.profile["contact-information-section"].title[lang]}
        </div>
        <div className="field-description ">
          {translate.profile["contact-information-section"].subtitle[lang]}
        </div>
        <div className="mt-40" />
        <div>
          <div className="list-edit-contact ">
            <div className="single-edit-contact">
              <div className="icon-container">
                <img src="../../assets/img/icons/email.svg" alt="" srcSet="" />
              </div>
              <div className="input-container">
                <input
                  type="email"
                  name="professional_email"
                  placeholder={AdresseEmail[lang]}
                  onChange={HandleChange}
                  defaultValue={
                    config.restrict_contact_email_ext
                      ? onboardingUser.professional_email.split("@")[0]
                      : onboardingUser.professional_email
                  }
                />
                {config.restrict_contact_email_ext ? (
                  <span
                    className="input-ext"
                    id="mail_ext"
                    style={{ marginTop: "-9px" }}
                  >
                    @{config.contact_email_ext}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="divider" />
            {config.show_website ? (
              <div className="single-edit-contact">
                <div className="icon-container">
                  <img
                    src="../../assets/img/icons/website.svg"
                    alt=""
                    srcSet=""
                  />
                </div>
                {config.restrict_website ? (
                  <div className="locked-config-website">
                    {config.website}
                    <img
                      src="../../assets/img/icons/lock.svg"
                      alt=""
                      srcset=""
                    />
                  </div>
                ) : (
                  <div className="input-container">
                    <input
                      type="text"
                      name="website"
                      id="website"
                      placeholder={SiteWeb[lang]}
                      onChange={HandleChange}
                      defaultValue={onboardingUser.website}
                    />
                  </div>
                )}
              </div>
            ) : null}
            {config.show_website ? <div className="divider" /> : null}
            {config.show_address ? (
              <div className="single-edit-contact">
                <div className="icon-container">
                  <img
                    src="../../assets/img/icons/address.svg"
                    alt=""
                    srcSet=""
                  />
                </div>
                {config.restrict_address ? (
                  <div className="locked-config-website">
                    {config.address}
                    <img
                      src="../../assets/img/icons/lock.svg"
                      alt=""
                      srcset=""
                    />
                  </div>
                ) : (
                  <div className="input-container">
                    <input
                      type="text"
                      className="input-textarea pac-target-input"
                      name="address"
                      id="address"
                      rows={2}
                      required=""
                      placeholder="Adresse"
                      autoComplete="off"
                      onChange={HandleChange}
                      defaultValue={onboardingUser.address}
                    />
                  </div>
                )}
              </div>
            ) : null}
            {config.show_address ? <div className="divider" /> : null}
          </div>
        </div>
        <div className="button-container">
          <button
            className={safe ? "btn-fields btn-active" : "btn-fields"}
            onClick={handleSubmit}
            id="btn-next"
            disabled={!safe}
          >
            {edit ? (
              <img
                src="/assets/img/loaders/loading.gif"
                style={{
                  display: loading ? undefined : "none",
                  padding: "2px",
                }}
                width="23px"
                alt=""
                className="ml-[45%]"
              />
            ) : null}
            {loading
              ? null
              : edit
              ? translate?.profile.button[lang]
              : translate?.button_next[lang]}
          </button>
        </div>
      </section>
      <div className="pac-container pac-logo" style={{ display: "none" }} />
    </>
  );
};

export default ProfessionalInfo;
export { ProfessionalInfo as ProfessionalInfoOnboarding };
