import React from "react";

const PhoneDetails = ({ k, prefix, number}) => {
    return (
        <>
            {k > 0 ? <div className="divider-home" /> : null}
            <div
                className="single-contact-item"
                onClick={() => window.open(`tel:+${prefix}${number}`, '_self')}
            >
                <div className="contact-icon-text">
                <div className="contact-item-icon">
                    <img src={`/assets/img/socials/phone.svg`} alt="" srcSet="" />
                </div>
                <div className="contact-item-text">+{prefix}{number}</div>
                </div>
                <div className="contact-item-arrow">
                <img src="../assets/img/icons/arrow-right.svg" alt="" srcSet="" />
                </div>
            </div></>
    )
}

export default PhoneDetails