import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./views/onboarding/Home";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { createGlobalState } from "react-hooks-global-state";
import MainLoading from "./componants/MainLoading";
import Profile from "./views/Profile";
import Login from "./views/Login";
import Confirm from "./views/Confirm";
import PopModal from "./componants/PopModal";
import Loading from "./componants/Loading";
import ForgetPassword from "./views/ForgetPassword";

const root = ReactDOM.createRoot(document.getElementById("root"));

const { setGlobalState, useGlobalState } = createGlobalState({
  onboarding_user: {
    _id: "",
    about: {
      en: "",
      fr: "",
    },
    active: true,
    appointment: "",
    collaborator_id: "",
    company: {en: "", fr: ""},
    confirmed: true,
    created_on: "",
    professional_email: "",
    element_order: [1, 2, 3, 4, 5, 6, 7, 8],
    address: "",
    email: "",
    website: "",
    first_name: "",
    images: [],
    last_name: "",
    links: [
      {
        thumb: "",
        url: "",
        title: "",
        description: "",
      },
    ],
    onboarding_step: 0,
    phone: [
      {
        prefix: "212",
        number: "",
      },
    ],
    profile_id: "",
    profile_picture: "",
    socials: [
      {
        url: "",
        social_id: "637235ad60136a9a3e26f78e",
      },
    ],
    user_token: "",
    videos: [],
    about: "",
    title: "",
    contact_lock: {
      created_on: "2023-03-06 20:23:07.915000",
      password: "",
    },
    contact_locked: false,
    cover: {
      url: "",
      x: 0,
      y: 0,
    },
    products: [],
  },
  user: {
    _id: "",
    about: {
      en: "",
      fr: "",
    },
    active: true,
    appointment: "",
    collaborator_id: "",
    company: {en: "", fr: ""},
    confirmed: true,
    created_on: "",
    element_order: [1, 2, 3, 4, 5, 6, 7, 8],
    address: "",
    email: "",
    website: "",
    first_name: "",
    images: [],
    last_name: "",
    links: [],
    onboarding_step: 0,
    phone: [],
    profile_id: "",
    profile_picture: "",
    socials: [],
    user_token: "",
    videos: [],
    about: "",
    title: "",
    slug: "",
    products: [],
    contact_lock: {},
    contact_locked: false,
    cover: {
      url: "",
      x: 0,
      y: 0,
    },
  },
  config: {
    _id: "",
    about: {
      en: "",
      fr: "",
    },
    active: true,
    address: "",
    appointment: "",
    company_name: {en: "", fr: ""},
    contact_email_ext: "",
    created_on: "",
    images: [],
    links: [],
    logo: "",
    name: "",
    professional_email: "",
    onboarding_name: "",
    register_email_ext: "",
    restrict_about: false,
    restrict_address: false,
    restrict_appointment: false,
    restrict_company_name: false,
    restrict_contact_email_ext: false,
    restrict_images: false,
    restrict_links: false,
    restrict_register_email_ext: false,
    restrict_socials: false,
    restrict_videos: false,
    restrict_website: false,
    restrict_products: false,
    show_about: true,
    show_address: true,
    show_appointment: true,
    show_company_name: true,
    show_onboarding_name: false,
    show_website: true,
    cover: {
      url: "",
      x: 0,
      y: 0,
    },
    socials: [
      {
        url: "",
        social_id: "637235ad60136a9a3e26f78e",
      },
    ],
    updated_on: "2022-11-10 18:10:44.867000",
    videos: [],
    website: "",
    products: [
      {
        links: [
          {
            url: "",
            store: "6405b55177dbb3e4441f7985",
          },
        ],
      },
    ],
  },
  onboarding: {
    step: -2,
  },
  socials: {
    "637235ac60136a9a3e26f783": {
      icon: "youtube.svg",
      label: "Youtube",
      notoriety_order: 2,
    },
    "637235ad60136a9a3e26f784": {
      icon: "whatsapp.svg",
      label: "Whatsapp",
      notoriety_order: 3,
    },
    "637235ad60136a9a3e26f785": {
      icon: "twitter.svg",
      label: "Twitter",
      notoriety_order: 7,
    },
    "637235ad60136a9a3e26f786": {
      icon: "twitch.svg",
      label: "Twitch",
      notoriety_order: 100,
    },
    "637235ad60136a9a3e26f787": {
      icon: "tumblr.svg",
      label: "Tumblr",
      notoriety_order: 100,
    },
    "637235ad60136a9a3e26f788": {
      icon: "tik_tok.svg",
      label: "Tiktok",
      notoriety_order: 6,
    },
    "637235ad60136a9a3e26f789": {
      icon: "telegram.svg",
      label: "Telegram",
      notoriety_order: 8,
    },
    "637235ad60136a9a3e26f78a": {
      icon: "slack.svg",
      label: "Slack",
      notoriety_order: 100,
    },
    "637235ad60136a9a3e26f78b": {
      icon: "skype.svg",
      label: "Skype",
      notoriety_order: 9,
    },
    "637235ad60136a9a3e26f78c": {
      icon: "pinterest.svg",
      label: "Pinterest",
      notoriety_order: 100,
    },
    "637235ad60136a9a3e26f78d": {
      icon: "medium.svg",
      label: "Medium",
      notoriety_order: 100,
    },
    "637235ad60136a9a3e26f78e": {
      icon: "linkedin.svg",
      label: "Linkedin",
      notoriety_order: 5,
    },
    "637235ad60136a9a3e26f78f": {
      icon: "instagram.svg",
      label: "Instagram",
      notoriety_order: 4,
    },
    "637235ad60136a9a3e26f790": {
      icon: "Huawei Appgallery.svg",
      label: "Huawei Appgallery",
      notoriety_order: 100,
    },
    "637235ad60136a9a3e26f791": {
      icon: "google-play.svg",
      label: "Google Play",
      notoriety_order: 100,
    },
    "637235ad60136a9a3e26f792": {
      icon: "google_my_business.svg",
      label: "Google My Business",
      notoriety_order: 100,
    },
    "637235ae60136a9a3e26f793": {
      icon: "gitlab.svg",
      label: "Gitlab",
      notoriety_order: 100,
    },
    "637235ae60136a9a3e26f794": {
      icon: "github.svg",
      label: "Github",
      notoriety_order: 100,
    },
    "637235ae60136a9a3e26f795": {
      icon: "flickr.svg",
      label: "Flickr",
      notoriety_order: 100,
    },
    "637235ae60136a9a3e26f796": {
      icon: "figma.svg",
      label: "Figma",
      notoriety_order: 100,
    },
    "637235ae60136a9a3e26f797": {
      icon: "facebook.svg",
      label: "Facebook",
      notoriety_order: 1,
    },
    "637235ae60136a9a3e26f798": {
      icon: "dropbox.svg",
      label: "Dropbox",
      notoriety_order: 100,
    },
    "637235ae60136a9a3e26f799": {
      icon: "dribbble.svg",
      label: "Dribbble",
      notoriety_order: 100,
    },
    "637235ae60136a9a3e26f79a": {
      icon: "discord.svg",
      label: "Discord",
      notoriety_order: 100,
    },
    "637235ae60136a9a3e26f79b": {
      icon: "behance.svg",
      label: "Behance",
      notoriety_order: 100,
    },
    "637235ae60136a9a3e26f79c": {
      icon: "appstore.svg",
      label: "Appstore",
      notoriety_order: 100,
    },
    "637235ae60136a9a3e26f79d": {
      icon: "adobe_portfolio.svg",
      label: "Adobe Portfolio",
      notoriety_order: 100,
    },
    "637235ae60136a9a3e26f79e": {
      icon: "wechat.svg",
      label: "WeChat",
      notoriety_order: 10,
    },
    "63cea5001c807b79c823e5f5": {
      icon: "imdb.png",
      label: "IMDb",
      notoriety_order: 10,
    },
    "63d7ba59fedeb3dc04055be3": {
      icon: "snapchat.jpg",
      label: "SnapChat",
      notoriety_order: 10,
    },
    "63d7ba8afedeb3dc04055be4": {
      icon: "tripadvisor.jpg",
      label: "TripAdvisor",
      notoriety_order: 10,
    },
  },
  orders: {
    "6405b55177dbb3e4441f7985": {
      created_on: "2023-03-06 10:41:37.291000",
      icon: "/assets/logo/glovo",
      label: "Glovo",
      store: true,
    },
    "640667f3621af85e8b8c22f7": {
      created_on: "2023-03-06 23:23:47.079000",
      icon: "/assets/logo/jumia",
      label: "Jumia",
      store: true,
    },
    "64066811621af85e8b8c22f8": {
      created_on: "2023-03-06 23:24:17.570000",
      icon: "/assets/logo/kaalix",
      label: "Kaalix",
      store: true,
    },
    "64066830621af85e8b8c22f9": {
      created_on: "2023-03-06 23:24:48.336000",
      icon: "/assets/logo/lalivraison",
      label: "La Livraison",
      store: true,
    },
    "6406683e621af85e8b8c22fa": {
      created_on: "2023-03-06 23:25:02.940000",
      icon: "/assets/logo/amazon",
      label: "LAmazon",
      store: true,
    },
    "6406684c621af85e8b8c22fb": {
      created_on: "2023-03-06 23:25:16.019000",
      icon: "/assets/logo/ubereats",
      label: "Uber eats",
      store: true,
    },
    "6406685e621af85e8b8c22fc": {
      created_on: "2023-03-06 23:25:34.161000",
      icon: "/assets/logo/leboncoin",
      label: "Leboncoin",
      store: true,
    },
    "6406686a621af85e8b8c22fd": {
      created_on: "2023-03-06 23:25:46.762000",
      icon: "/assets/logo/deliveroo",
      label: "Deliveroo",
      store: true,
    },
    "64066874621af85e8b8c22fe": {
      created_on: "2023-03-06 23:25:56.740000",
      icon: "/assets/logo/taiga",
      label: "Taiga",
      store: true,
    },
    "64066882621af85e8b8c22ff": {
      created_on: "2023-03-06 23:26:10.545000",
      icon: "/assets/logo/fnac",
      label: "Fnac",
      store: true,
    },
    "64066892621af85e8b8c2300": {
      created_on: "2023-03-06 23:26:26.851000",
      icon: "/assets/logo/avito",
      label: "Avito",
      store: true,
    },
    "640668a4621af85e8b8c2301": {
      created_on: "2023-03-06 23:26:44.794000",
      icon: "/assets/logo/iherb",
      label: "iHerb",
      store: true,
    },
    "640668b0621af85e8b8c2302": {
      created_on: "2023-03-06 23:26:56.083000",
      icon: "/assets/logo/aliexpress",
      label: "Aliexpress",
      store: true,
    },
    "640668ba621af85e8b8c2303": {
      created_on: "2023-03-06 23:27:06.864000",
      icon: "/assets/logo/cdiscounts",
      label: "cdiscount",
      store: true,
    },
    "640668c4621af85e8b8c2304": {
      created_on: "2023-03-06 23:27:16.839000",
      icon: "/assets/logo/ubuy",
      label: "Ubuy",
      store: true,
    },
    "640668cf621af85e8b8c2305": {
      created_on: "2023-03-06 23:27:27.532000",
      icon: "/assets/logo/boutika",
      label: "Boutika",
      store: true,
    },
    "640668dc621af85e8b8c2306": {
      created_on: "2023-03-06 23:27:40.692000",
      icon: "/assets/logo/shein",
      label: "Shein",
      store: true,
    },
    "640668e7621af85e8b8c2307": {
      created_on: "2023-03-06 23:27:51.856000",
      icon: "/assets/logo/veepee",
      label: "Veepee",
      store: true,
    },
    "640668f9621af85e8b8c2308": {
      created_on: "2023-03-06 23:28:09.391000",
      icon: "/assets/logo/boulanger",
      label: "Boulanger",
      store: true,
    },
    "6406690e621af85e8b8c2309": {
      created_on: "2023-03-06 23:28:30.465000",
      icon: "/assets/logo/goprot",
      label: "GoProt",
      store: true,
    },
    "6406691c621af85e8b8c230a": {
      created_on: "2023-03-06 23:28:44.734000",
      icon: "/assets/logo/whatsapp",
      label: "Whatsapp",
      store: true,
    },
    "64066931621af85e8b8c230b": {
      created_on: "2023-03-06 23:29:05.590000",
      icon: "/assets/logo/autre",
      label: "Autre",
      store: true,
    },
  },
  dropdown_id: -1,
  image: {
    image: null,
    id: 0,
  },
  links: [
    {
      thumb: "",
      url: "",
      title: "",
      description: "",
    },
  ],
  editComponantId: -1,
  isLogged: true,
  socials_data: [],
  loading: true,
  loading_popup: false,
  lang_tran: localStorage.getItem("lang") || "fr",
  contact_lock: {
    password: "",
  },
  contact_locked: false,
  cover: {
    url: "",
    x: 0,
    y: 0,
  },
  onboarding_trans: {
    bonus: {
      date: {
        fr: "Date fin adhésion",
        en: "Membership end date"
      },
      membership: {
        fr: "Type d'adhésion",
        en: "Membership type"
      }
    },
    lirePlus: {
      fr: "Lire plus",
      en: "Read more",
    },
    delete: {
      fr: "Supprimer",
      en: "Delete",
    },
    titre: {
      fr: "Titre*",
      en: "Title*",
    },
    "password-": {
      fr: "Mot de passe",
      en: "Password",
    },
    button_next: {
      fr: "Suivant",
      en: "Next",
    },
    button_end: {
      fr: "Terminer",
      en: "Finish",
    },
    "password-confirm": {
      fr: "Confirmer le mot de passe",
      en: "Confirm password",
    },
    password_confirm_error: {
      fr: "Les mots de passe ne correspondent pas",
      en: "Passwords do not match",
    },
    createAccoutnTitle: {
      fr: "Création de compte",
      en: "Create account",
    },
    createAccoutnDesc: {
      fr: "Saisissez un email professionnel et un mot de passe pour vos futures connexions à votre espace popcard",
      en: "Enter a professional email and password for your future connections to your popcard space",
    },
    "title-section": {
      fr: "Bienvenue sur",
      en: "Welcome to",
    },
    "subtitle-section": {
      fr: "Vous êtes l’heureux propriétaire d’une carte de visite nouvelle génération.",
      en: "You are the proud owner of a new generation business card.",
      button: {
        fr: "Démarrer la configuration",
        en: "Start configuration",
      },
    },
    auth_description: {
      fr: "Avec Popcard, partagez vos coordonnées et informations professionnelles avec permettre au membre en un seul geste.",
      en: "With Popcard, share your contact details and professional information with allow the member in a single gesture.",
    },
    "authentification-section": {
      fr: "Saisissez un email professionnel et un mot de passe pour vos futures connexions à votre espace popcard",
      en: "Enter a business email and password for your future logins to your popcard space",
      button: {
        fr: "Suivant",
        en: "Next",
      },
    },
    "verification-section": {
      title: {
        fr: "Un email vient de vous être envoyée à l’adresse :",
        en: "An email has been sent to:",
      },
      subtitle: {
        fr: "Merci de vérifier votre boîte de réception (Principale/Spam) pour confirmer votre email et poursuivre le paramétrage de votre Popcard.",
        en: "Please check your inbox (Main/Spam) to confirm your email and continue setting up your Popcard.",
      },
      button: {
        fr: "Renvoyer le mail",
        en: "Resend email",
      },
    },
    "email-verification-section": {
      "verification-section": {
        fr: "Email déjà utilisé. Veuillez réessayer avec un autre email.",
        en: "Email already in use. Please try again with another email.",
      },
      title1: {
        fr: "Verification de mail",
        en: "Email verification",
      },
      title2: {
        fr: "Un email vient de vous être envoyée à l’adresse :",
        en: "An email has been sent to:",
      },
      title: {
        fr: "Un nouvel email vient de vous être envoyée à l'adresse :",
        en: "A new email has just been sent to:",
      },
      subtitle: {
        fr: "Merci de vérifier votre boîte de réception (Principale/Spam) pour confirmer votre email et poursuivre le paramétrage de votre Popcard.",
        en: "Please check your inbox (Main/Spam) to confirm your email and continue setting up your Popcard.",
      },
      button: {
        fr: "Renvoyer le mail",
        en: "Resend email",
      },
    },
    profile: {
      "share-information-form": {
        title: {
          fr: "Partager vos informations avec",
          en: "Share your information with",
        },
        pre: {
          fr: "Prénom",
          en: "First name",
        },
        nom: {
          fr: "Nom",
          en: "Last name",
        },
        button: {
          fr: "Partager",
          en: "Share",
        },
        partager: {
          fr: "Pressé(e)? Partagez une photo de votre carte de visite en quelques secondes",
          en: "In a hurry? Share a picture of your business card in seconds",
        },
      },
      "edit-information-login": {
        bonjour: {
          fr: "Bonjour",
          en: "Hello",
        },
        ressaye: {
          fr: "n’est associée à aucun compte. Veuillez réessayer",
          en: "is not associated with any account. Please try again",
        },
        topr: {
          fr: "Un mot de passe temporaire vient de vous être envoyé par mail à l’adresse",
          en: "A temporary password has just been sent to you by email to the address",
        },
        subtitle: {
          fr: "Veuillez saisir le nom d'utilisateur rattaché à votre compte.",
          en: "Please enter the username attached to your account",
        },
        retour: {
          fr: "Retour",
          en: "Back",
        },
        buttonSend: {
          fr: "Envoyer la demande",
          en: "Send request",
        },
        pleaseLogin: {
          fr: "Veuillez vous authentifier afin de modifier vos informations.",
          en: "Please login in order to modify your information.",
        },
        pass: {
          fr: "Mot de passe",
          en: "Password",
        },
        "pass-oublie": {
          fr: "Mot de passe oublié ?",
          en: "Forgot password?",
        },
      },
      "identification-popup": {
        title: {
          fr: "Code d'identification",
          en: "Identification code",
        },
        subtitle: {
          fr: "Saisir le mot de passe temporaire",
          en: "Enter the temporary password",
        },
        error: {
          fr: "Le mot de passe est incorrect !",
          en: "Password incorrect!",
        },
        button: {
          fr: "Valider",
          en: "Submit",
        },
      },
      "about-section": {
        title: {
          fr: "Avantages",
          en: "Benefits",
        },
        subtitle: {
          fr: "Saisissez les avantages de votre établissement",
          en: "Enter the advantages of your establishment",
        },
      },
      "contact-card-section": {
        title: {
          fr: "Fiche de contact",
          en: "Contact card",
        },
        subtitle: {
          fr: "Mettre à jour les informations du membre",
          en: "Update the member's details",
        },
        "first-name": {
          fr: "Prénom",
          en: "First name",
        },
        "last-name": {
          fr: "Nom",
          en: "Last name",
        },
        "mobile-number": {
          fr: "Numéro mobile",
          en: "Mobile number",
        },
        "home-number": {
          fr: "Numéro fixe",
          en: "Home number",
        },
        company: {
          fr: "Entreprise",
          en: "Company",
        },
        position: {
          fr: "N° de licence",
          en: "N° of licence",
        },
      },
      "social-media-section": {
        title: {
          fr: "Réseaux sociaux",
          en: "Social media",
        },
        subtitle: {
          fr: "Choisissez parmi une multitude de réseaux sociaux pour mettre en valeur ce qui compte le plus pour vous",
          en: "Choose from a multitude of social networks to showcase what matters most to you",
        },
        button: {
          fr: "Ajouter un réseau social",
          en: "Add a social network",
        },
      },
      "appointment-section": {
        title: {
          fr: "Prise de rendez-vous",
          en: "Appointment booking",
        },
        subtitle: {
          fr: "Offrez la possibilité à vos rencontres de réserver un créneau disponible sur votre agenda en quelques clics",
          en: "Offer your encounters the possibility to reserve an available slot on your calendar in a few clicks",
        },
        how: {
          fr: "Tutoriel pour obtenir votre lien ",
          en: "How to get your  link",
        },
        appuyer: {
          fr: "Appuyer ici pour prendre un rendez-vous avec",
          en: "Click here to book an appointment with",
        },
      },
      "links-section": {
        title: {
          fr: "Liens",
          en: "Links",
        },
        subtitle: {
          fr: "Saisissez un lien vers un site ou un document hebergé pour permettre à vos rencontres d’y accéder en un clic",
          en: "Enter a link to a site or hosted document to allow your meetings to access it with one click",
        },
        button: {
          fr: "Uploader un aperçu",
          en: "Upload a preview",
        },
      },
      "products-and-services-section": {
        title: {
          fr: "Produits et services",
          en: "Products and services",
        },
        subtitle: {
          fr: "Mettez en valeur vos produits et services et listez l'ensemble des plateformes sur lesquelles vos rencontres peuvent les obtenir",
          en: "Highlight your products and services and list all the platforms on which your encounters can get them",
        },
        button: {
          fr: "Ajouter un nouveau produit/service",
          en: "Add a new product/service",
        },
        addProductModal: {
          titleAdd: {
            fr: "Ajouter un produit/service",
            en: "Add a product/service",
          },
          titleEdit: {
            fr: "Modifier un produit/service",
            en: "Edit a product/service",
          },
          name: {
            fr: "Nom du produit/service",
            en: "Product/service name",
          },
          prix: {
            fr: "Prix",
            en: "Price",
          },
          image: {
            fr: "Photos (3 max.)",
            en: "Photos (3 max.)",
          },
          link: {
            fr: "Lien de vente",
            en: "Sales link",
          },
          button: {
            fr: "Ajouter un nouveau lien de vente",
            en: "Add a new sales link",
          },
        },
      },
      "images-section": {
        title: {
          fr: "Images",
          en: "Images",
        },
        subtitle: {
          fr: "Exprimez votre personnalité et démontrez votre savoir-faire à travers cette gallerie d’images",
          en: "Express your personality and demonstrate your expertise through this gallery of images",
        },
        button: {
          fr: "Uploader une image",
          en: "Upload a picture",
        },
      },
      "Videos-section": {
        title: {
          fr: "Vidéos",
          en: "Videos",
        },
        subtitle: {
          fr: "Saisissez un lien vers les vidéos que vous souhaitez mettre en valeur auprès du membre",
          en: "Enter a link to the videos you want to share with your member",
        },
        button: {
          fr: "Ajouter un vidéo",
          en: "Add a video",
        },
      },
      "contact-information-section": {
        title: {
          fr: "Coordonnées",
          en: "Contact Information",
        },
        subtitle: {
          fr: "Saisissez les coordonnées professionnelles que vous souhaitez partager avec le membre",
          en: "Enter the business contact information that you want to share with your member",
        },
      },
      setting: {
        title: {
          fr: "Accéder aux Paramètres",
          en: "Go to Settings",
        },
      },
      mainCard: {
        title: {
          fr: "Souhaitez-vous modifier vos informations ?",
          en: "Would you like to modify your information?",
        },
      },
      buttonLogin: {
        fr: "Se connecter",
        en: "Login",
      },
      buttonLogout: {
        fr: "Se déconnecter",
        en: "Logout",
      },
      button: {
        fr: "Enregistrer",
        en: "Save",
      },
    },
    footer: {
      fr: "Cette carte appartient à {{name}}, membre de MADAĒF GOLFS. L'accès aux golfs est autorisé sous réserve de présentation de cette dernière.",
      en: "This card belongs to {{name}}, member of MADAĒF GOLFS. Access to the golf courses is authorized subject to presentation of the latter.",
      button: {
        fr: "Obtenir ma popcard",
        en: "Get my popcard",
      },
      title: {
        fr: "Tous droits réservés",
        en: "All rights reserved",
      },
    },
    addToContact: {
      fr: "Ajouter aux contacts",
      en: "Add to contacts",
    },
    settings: {
      title: {
        fr: "Paramètres",
        en: "Settings",
      },
      "password-change-section": {
        title: {
          fr: "Redéfinir le mot de passe",
          en: "Change your password",
        },
        subtitle: {
          fr: "Merci de saisir votre ancien mot de passe avant d’en choisir un nouveau.",
          en: "Please enter your old password before choosing a new one.",
        },
        actuel: {
          fr: "Mot de passe actuel*",
          en: "Current password*",
        },
        nouveau: {
          fr: "Nouveau mot de passe*",
          en: "New password*",
        },
        confirmer: {
          fr: "Confirmer le nouveau mot de passe*",
          en: "Confrim new password*",
        },
        incorrect: {
          fr: "Mot de passe incorrect !",
          en: "Password incorrect!",
        },
        different: {
          fr: "Les mots de passe ne correspondent pas !",
          en: "Passwords don't match",
        },
        button: {
          fr: "Sauvgarder",
          en: "Save",
        },
      },
      "custom-link-section": {
        title: {
          fr: "Définir un lien public",
          en: "Set a public link",
        },
        lienactuel: {
          fr: "Lien actuel",
          en: "Current link",
        },
        partager: {
          fr: "Partager avec une rencontre",
          en: "Share with an encounters",
        },
        copier: {
          fr: "Copier l'URL",
          en: "Copy URL",
        },
        other: {
          fr: "Autres",
          en: "Other",
        },
        subtitle: {
          fr: "Transformer votre lien crypté en lien public vous permet d’atteindre une plus large audience",
          en: "Turning your encrypted link into a public link allows you to reach a wider audience",
        },
        lien: {
          fr: "Lien personnalisé",
          en: "Customized link",
        },
        error: {
          fr: "Ce lien est déjà pris!",
          en: "This link is already taken!",
        },
        success: {
          fr: "Lien disponible!",
          en: "This link is available!",
        },
        button: {
          fr: "Rétablir le lien",
          en: "Reset link",
        },
      },
      "temporary-code-section": {
        title: {
          fr: "Protéger la fiche contact avec un mot de passe temporaire.",
          en: "Protect the contact card with a temporary password.",
        },
        active: {
          fr: "La protection est activée.",
          en: "Protection activated.",
        },
        inactive: {
          fr: "La protection est désactivée.",
          en: "Protection inactive.",
        },
        subtitle: {
          fr: "Votre code temporaire",
          en: "Your temporary code",
        },
      },
    },

    uploaderPic: {
      upload: {
        fr: "Prendre une photo",
        en: "Take a picture",
      },
      telecharger: {
        fr: "Choisissez une image existante",
        en: "Choose an existing image",
      },
      annuler: {
        fr: "Supprimer l'image de couverture",
        en: "Delete cover image",
      },
    },
  },
  refresh: 0,
  nowUtc: "",
  branding: {},
});

const App = () => {
  return (
    <BrowserRouter>
      <Loading />
      <div>
        <Routes>
          <Route element={<MainLoading />} exact path="/" />
          <Route element={<MainLoading />} path="/:id" />
          <Route element={<Home />} path="/onboarding/:id" />
          <Route element={<Profile />} path="/profile/:id" />
          <Route element={<Login />} path="/login" />
          <Route element={<ForgetPassword />} path="/forget-password" />
          <Route element={<Confirm />} path="/confirm/:id" />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

root.render(<App />);

export { setGlobalState, useGlobalState };
