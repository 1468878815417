import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGlobalState } from "../..";
import Close from "../../componants/Close";
import LottieLang from "../../componants/LottieLang";
import Header from "../../componants/onboarding/Header";
import Fields from "../../componants/onboarding/Videos/Fields";
import AppServices from "../../services/AppServices";

const Videos = ({ edit }) => {
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");
  const [onboardingUser, setOnboardingUser] = useGlobalState("onboarding_user");
  const [safe, setSafe] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [config] = useGlobalState("config");
  const [lang] = useGlobalState("lang_tran");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (config.restrict_videos) {
      setData([
        ...config.videos,
        ...onboardingUser.videos.filter((image) => {
          if (image.is_admin) {
            return image;
          }
        }),
      ]);
    } else {
      setData(
        onboardingUser.videos.length > 0
          ? [...onboardingUser.videos]
          : [
              {
                type: 1,
                url: "",
                description: {
                  en: "",
                  fr: "",
                },
              },
            ]
      );
    }
  }, [onboardingUser.videos, config.videos]);

  const SafeCheck = () => {
    const filter = data.map((video) => {
      if (video.url.length === 0) {
        return false;
      } else {
        return true;
      }
    });
    if (filter.includes(false)) {
      setSafe(false);
    } else {
      setSafe(true);
    }
  };

  useEffect(() => {
    if (editComponantId === 6 || !edit) {
      SafeCheck();
    }
  }, [data]);

  const onAdd = () => {
    setData([
      ...data,
      {
        type: 1,
        url: "",
        description: {
          en: "",
          fr: "",
        },
      },
    ]);
  };

  const handleChange = (e, i) => {
    setData((prevState) => {
      const newData = [...prevState];
      const { name, value } = e.target;
      if (name === "url") {
        newData[i][name] = value;
      } else {
        newData[i].description = {
          ...newData[i].description,
          [lang]: value,
          [lang === "en" ? "fr" : "en"]: newData[i].description[
            lang === "en" ? "fr" : "en"
          ]
            ? newData[i].description[lang === "en" ? "fr" : "en"]
            : "",
        };
      }
      return newData;
    });
  };

  const handleDropDown = (i, value) => {
    data[i] = {
      ...data[i],
      type: value,
    };

    setData([...data]);
  };

  const onRemove = (id) => {
    data.splice(id, 1);
    setData([...data]);
  };

  const [step1, setStep1] = useState(false);
  const handleLastStep = () => {
    if (edit) {
      setLoading(true);
    }
    AppServices.post(
      {
        action: 1,
        data: {
          onboarding_step: 10,
          videos: data.length == 1 && data[0].url === "" ? undefined : data,
        },
      },
      "/onboarding"
    ).then((response) => {
      setLoading(false);
      if (edit) {
        seteditComponantId(-1);
      } else {
        setStep1(true);
      }
    });
  };

  const handleSubmit = () => {
    if (!edit) {
      AppServices.post(
        {
          action: 2,
        },
        "/email"
      ).then((response) => {
        handleLastStep();
      });
    } else {
      handleLastStep();
    }
  };

  const handleStepLang = () => {
    window.location = `/profile/${id}`;
  };

  const [onboarding_trans] = useGlobalState("onboarding_trans");
  const title2 = {
    en: "You can change your display language with one click",
    fr: "Vous pouvez modifier la langue d'affichage en un clic",
  };

  const button = {
    en: "Finish",
    fr: "Terminer",
  };
  return (
    <>
      {edit ? <Close /> : <Header />}
      <section className="fields-content">
        <div className="field-title ">
          {onboarding_trans.profile["Videos-section"].title[lang]}
        </div>
        <div className="field-description ">
          {onboarding_trans.profile["Videos-section"].subtitle[lang]}
        </div>
        <div className="mt-40" />
        <div className="list-social-container " />
        <div className="mt-40" />
        <div style={{ marginBottom: "12rem" }}>
          <div
            id="list-videos-container"
            style={{
              height: "auto",
              display: "block",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {data.map((val, i) => {
              return (
                <Fields
                  id={i}
                  data={val}
                  removeField={onRemove}
                  fieldsChange={handleChange}
                  handleDropDown={handleDropDown}
                  locked={config.restrict_videos}
                  edit={edit}
                />
              );
            })}
            {!config.restrict_videos ? (
              <div
                className="icon-button-container"
                style={{ marginTop: "2.5rem" }}
              >
                <div className="button-icon">
                  <img
                    src="../../assets/img/icons/plus-circle.svg"
                    alt=""
                    srcSet=""
                  />
                </div>
                <div className="button-text" onClick={onAdd}>
                  {onboarding_trans.profile["Videos-section"].button[lang]}
                </div>
              </div>
            ) : null}
          </div>

          {(!config.restrict_videos && edit) || !edit ? (
            <div className="button-container">
              <button
                // className={safe ? "btn-fields btn-active" : "btn-fields"}
                onClick={handleSubmit}
                className={"btn-fields btn-active"}
                id="btn-next"
                // disabled={!safe}
              >
                {edit ? (
                  <img
                    src="/assets/img/loaders/loading.gif"
                    style={{
                      display: loading ? undefined : "none",
                      padding: "2px",
                    }}
                    width="23px"
                    alt=""
                    className="ml-[45%]"
                  />
                ) : null}
                {loading
                  ? null
                  : edit
                  ? onboarding_trans.profile.button[lang]
                  : onboarding_trans.button_end[lang]}
              </button>
            </div>
          ) : null}
        </div>
      </section>
      <div className="absolute top-0 bg-[#FFFAF5] w-full z-[99999999]">
        {step1 && (
          <div className="flex flex-col items-center  mt-[5rem]">
            <div className="flex flex-col items-center mb-[2rem]">
              <img
                src="../../assets/img/logo-black.svg"
                style={{ margin: "0 10px" }}
                alt=""
                srcSet=""
                className="w-[139px] object-contain"
              />

              <div className="text-[21px] max-w-[239px] mt-[3rem] text-center">
                {title2[lang]}
              </div>
            </div>

            <LottieLang />
            <div className="button-container">
              <button className="btn-welcome" onClick={handleStepLang}>
                {button[lang]}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Videos;
export { Videos as VideosOnboarding };
