import React, { useEffect, useState } from "react";
import { useGlobalState } from "..";
import AppServices from "../services/AppServices";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";

const SettingPass = (props) => {
  const { setStep2, setStep1 } = props;

  const [lang] = useGlobalState("lang_tran");
  const [trans] = useGlobalState("onboarding_trans");
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmError, setConfirmError] = useState(false);
  const [currentError, setCurrentError] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisibleC, setPasswordVisibleC] = useState(false);
  const [safePass, setSafePass] = useState(false);

  useEffect(() => {
    if (
      currentPassword?.length >= 6 &&
      newPassword?.length >= 6 &&
      confirmPassword?.length >= 6
    ) {
      setSafePass(true);
    } else {
      setSafePass(false);
    }
  }, [currentPassword, newPassword, confirmPassword]);

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setConfirmError(true);
    } else {
      setConfirmError(false);
    }
  }, [confirmPassword]);
  
  function handleSubmitPass(event) {
    event.preventDefault();
    // check if the confirm password matches the new password
    if (newPassword !== confirmPassword) {
      setConfirmError(true);
    } else {
      // do something with the passwords
      AppServices.post(
        {
          action: 2,
          data: {
            password: currentPassword,
            new_password: newPassword,
          },
        },
        "/app"
      )
        .then((response) => {
          // reset the form
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setConfirmError(false);
          setCurrentError(false);
          seteditComponantId(-1);
          // set steps false
          setStep1(false);
          setStep2(false);
        })
        .catch((error) => {
          setCurrentError(true);
        });
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const togglePasswordVisibilityC = () => {
    setPasswordVisibleC(!passwordVisibleC);
  };

  return (
    <section className="fields-content overflow-hidden">
      <img
        src="../../assets/img/icons/arrow-back.svg"
        alt=""
        srcSet=""
        onClick={() => {
          setStep2(false);
        }}
        className="cursor-pointer absolute top-0 left-0 mt-[40px] ml-[20px]"
      />
      <div className="field-title">
        {trans.settings["password-change-section"].title[lang]}{" "}
      </div>
      <div className="mt-[1rem] text-[17px] font-[500]">
        {trans.settings["password-change-section"].subtitle[lang]}
      </div>

      {currentError && (
        <div className="text-[14px] text-[#F25252] mt-[0.5rem]">
          {trans.settings["password-change-section"].incorrect[lang]}
        </div>
      )}
      {confirmError && (
        <div className="text-[14px] text-[#F25252] mt-[0.5rem]">
          {trans.settings["password-change-section"].different[lang]}
        </div>
      )}
      <form
        onSubmit={handleSubmitPass}
        className="form-pass flex flex-col items-center gap-4"
      >
        <div className="password-input-container">
          <label
            htmlFor="currentPassword"
            className={`
                    ${currentError ? "text-red-500" : "text-black"}
                  `}
          >
            {trans.settings["password-change-section"].actuel[lang]}
          </label>
          <input
            type={passwordVisible ? "text" : "password"}
            id="currentPassword"
            value={currentPassword}
            className={currentError ? "input-error" : "input__field"}
            onChange={(event) => setCurrentPassword(event.target.value)}
          />
          <div className="password-toggle" onClick={togglePasswordVisibility}>
            {passwordVisible ? (
              <RiEyeOffLine className="text-[#795656]" />
            ) : (
              <RiEyeLine className="text-[#795656]" />
            )}
          </div>
        </div>
        <div className="password-input-container">
          <label htmlFor="newPassword">
            {trans.settings["password-change-section"].nouveau[lang]}
          </label>
          <input
            type={passwordVisibleC ? "text" : "password"}
            id="newPassword"
            value={newPassword}
            className="input__field"
            onChange={(event) => setNewPassword(event.target.value)}
          />
          <div className="password-toggle" onClick={togglePasswordVisibilityC}>
            {passwordVisibleC ? (
              <RiEyeOffLine className="text-[#795656]" />
            ) : (
              <RiEyeLine className="text-[#795656]" />
            )}
          </div>
        </div>
        <div className="password-input-container">
          <label
            htmlFor="confirmPassword"
            className={`
                    ${confirmError ? "text-red-500" : "text-black"}
                  `}
          >
            {trans.settings["password-change-section"].confirmer[lang]}
          </label>
          <input
            type={passwordVisibleC ? "text" : "password"}
            id="confirmPassword"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            className={confirmError ? "input-error" : "input__field"}
          />
          <div className="password-toggle" onClick={togglePasswordVisibilityC}>
            {passwordVisibleC ? (
              <RiEyeOffLine className="text-[#795656]" />
            ) : (
              <RiEyeLine className="text-[#795656]" />
            )}
          </div>
        </div>
        <div className="button-container">
          <button
            className={safePass ? "btn-fields btn-active" : "btn-fields"}
            id="btn-next"
            // onClick={handleSubmit}
            disabled={!safePass}
          >
            {trans.profile.button[lang]}
          </button>
        </div>
      </form>
    </section>
  );
};

export default SettingPass;
