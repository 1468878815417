import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGlobalState } from "../..";
import LottieCard from "../../componants/LottieCard";
import LottieLang from "../../componants/LottieLang";
import LanguageSelector from "../../componants/UI/LanguageSelector";
import AppServices from "../../services/AppServices";

const Auth = () => {
  const { id } = useParams();
  const [popup, setPopup] = useState(false);
  const [removePopup, setRemovePopup] = useState(false);
  const [popupOpened, setPopupOpened] = useState(false);
  const [safeToPass, setSafeToPass] = useState(false);
  const [onboarding, setOnboarding] = useGlobalState("onboarding");
  const [step1, setStep1] = useState(true);
  const [data, setData] = useState({
    collaborator_id: id,
    email: "",
    password: "",
    confirm_password: -1,
  });
  const [error, setError] = useState(false);
  const [userData, setOnboardingUser] = useGlobalState("onboarding_user");
  const [lang] = useGlobalState("lang_tran");
  const [onboarding_trans] = useGlobalState("onboarding_trans");
  const [branding] = useGlobalState("branding");
  useEffect(() => {
    setData({
      ...data,
      email: userData.email,
    });
  }, [userData.email]);

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      setPopup(true);
      setPopupOpened(true);
    }, 300);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!popup && popupOpened) {
        setRemovePopup(true);
        setStep1(false);
      }
    }, 300);
  }, [popup, popupOpened]);

  const handleSubmit = () => {
    localStorage.clear();
    AppServices.post(
      {
        action: 0,
        data: data,
      },
      "/onboarding"
    )
      .then((response) => {
        AppServices.post(
          {
            action: 0
          },
          "/email"
        ).then((response) => {
          // console.log(response);
        });
        setOnboarding({
          ...onboarding,
          step: onboarding.step + 1,
        });

        Object.keys(response).map((val) => {
          localStorage.setItem(val, response[val]);
        });
        handleSubmitStep1();
      })
      .catch((error) => {
        setError(true);
      });
  };

  useEffect(() => {
    console.log(
      "check",
      data.password == data.confirm_password,
      validateEmail(data.email),
      data.password.length >= 8
    );
    if (data.password == data.confirm_password && data.password.length >= 6) {
      setSafeToPass(true);
    } else {
      setSafeToPass(false);
    }
  }, [data]);

  const handleChange = (e) => {
    if (e.target.name === "email") {
      setData({
        ...data,
        [e.target.name]:
          e.target.value.toLowerCase() +
          (config.restrict_register_email_ext && e.target.name === "email"
            ? "@" + config.register_email_ext
            : ""),
      });
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const title = {
    en: "Select your setup Language",
    fr: "Sélectionner votre langue de paramétrage",
  };

  const title2 = {
    en: "You can change your display language with one click",
    fr: "Vous pouvez changer votre langue d'affichage en un clic",
  };

  const button = {
    en: "Continue",
    fr: "Continuer",
  };
  const [config, setConfig] = useGlobalState("config");

  const [step2, setStep2] = useState(false);

  const [stepCheck, setStepCheck] = useState(false);
  const handleSubmitStep1 = () => {
    AppServices.post(
      {
        action: 1,
        data: {
          language: lang === "en" ? "en" : "fr",
        },
      },
      "/onboarding"
    ).then((response) => {
      // console.log(response);

      setOnboardingUser({
        ...userData,
        language: lang === "en" ? "en" : "fr",
      });
    });
  };

  // get from local storage that email checked
  const emailChecked = localStorage.getItem("email_checked");

  // useEffect(() => {
  //   if (emailChecked === "true" || emailChecked === "") {
  //     setStep1(false);
  //     setStep2(false);
  //   }
  // }, [emailChecked]);

  const [stepon, setStepon] = useState(0);
  const handleSubmitStep2 = () => {
    setPopup(false);
    setStep1(false);
    setStep2(false);
    setStepCheck(true);
    setStepon(-2);
  };

  // get from local storage onboarding step
  let onboardingStep = localStorage.getItem("onboarding_step");
  useEffect(() => {
    onboardingStep = localStorage.getItem("onboarding_step");
    if (onboardingStep === "-2") {
      setStepon(-2);
      setStep1(false);
      setStep2(false);
    }
  }, [onboardingStep]);

  return (
    <>
      <section
        className="popup-action"
        id="welcome"
        style={
          popup
            ? { display: "block", opacity: 1, top: 0, zIndex: 999999999 }
            : null
        }
      >
        {step1 && (
          <div className="flex flex-col items-center  mt-[8rem]">
            <div className="flex flex-col items-center mb-[6rem]">
              <img
                src={`${
                  branding?.logo
                    ? branding?.logo
                    : "../../assets/img/logo-black.svg"
                }`}
                style={{ margin: "0 10px" }}
                alt=""
                srcSet=""
                className="w-[139px] object-contain"
              />

              <div className="text-[20px] mt-[5rem] text-center">
                {title[lang]}
              </div>
            </div>

            <LanguageSelector />
            <div className="button-container">
              <button
                className="btn-welcome"
                onClick={() => {
                  setStep1(false);
                  setStep2(true);
                }}
              >
                {button[lang]}
              </button>
            </div>
          </div>
        )}
        {step2 && (
          <section className="fields-content">
            <div className="field-title flex">
              {onboarding_trans["title-section"][lang]}
              <img
                src={`${
                  branding?.logo
                    ? branding?.logo
                    : "../../assets/img/logo-black.svg"
                }`}
                style={{ margin: "0 10px" }}
                alt=""
                srcSet=""
                className="w-[129px] object-contain"
              />
              👋🏻
            </div>
            <div className="field-description">
              {onboarding_trans["subtitle-section"][lang]}{" "}
              {config.show_onboarding_name ? (
                <p>
                  offerte par{" "}
                  <span className="span-bold">{config.onboarding_name}</span>
                </p>
              ) : null}
              <br />
              <br />
              {onboarding_trans["auth_description"][lang]}
            </div>
            <LottieCard />
            <div className="button-container">
              <button className="btn-welcome" onClick={handleSubmitStep2}>
                {onboarding_trans["subtitle-section"].button[lang]}
              </button>
            </div>
          </section>
        )}
        {!step1 && !step2 && (
          <section className="fields-content">
            <div className="field-title ">
              {onboarding_trans.createAccoutnTitle[lang]}
            </div>
            <div className="field-description ">
              {onboarding_trans.createAccoutnDesc[lang]}
            </div>
            <div className="mt-40" />
            {error ? (
              <div className="avatar-description text-dander">
                🚫
                {
                  onboarding_trans["email-verification-section"][
                    "verification-section"
                  ][lang]
                }
              </div>
            ) : null}
            <input
              type="hidden"
              name="colab_id"
              defaultValue="vfeootmaIi3fjLktHy"
            />
            <div className="single-onboarding-input">
              <label htmlFor="">Email</label>
              {userData?.email ? (
                <span className="input-txt-disabled">
                  {userData?.email}
                  <img
                    src="/assets/img/icons/lock.svg"
                    alt=""
                    srcSet=""
                    style={{ marginLeft: 10 }}
                  />
                </span>
              ) : (
                <>
                  <div className="restric-ext">
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      className="fields-step-1 rounded-border-restrict"
                      placeholder=""
                      id="email"
                    />
                    {config.restrict_register_email_ext ? (
                      <span className="input-ext" id="mail_ext">
                        @{config.register_email_ext}
                      </span>
                    ) : null}
                  </div>
                </>
              )}
            </div>
            <div className="mt-18" />
            <div className="single-onboarding-input">
              <label htmlFor="">{onboarding_trans["password-"][lang]}</label>
              <input
                type="password"
                name="password"
                onChange={handleChange}
                className="fields-step-1"
                placeholder=""
                id="password"
              />
            </div>
            <div className="mt-18" />
            <div className="single-onboarding-input">
              <label htmlFor="">
                {onboarding_trans["password-confirm"][lang]}
              </label>
              <input
                type="password"
                name="confirm_password"
                onChange={handleChange}
                className="fields-step-1"
                placeholder=""
                id="confirmPassword"
              />
            </div>
            <div className="button-container" style={{ zIndex: 0 }}>
              <button
                className={`btn-fields ${safeToPass ? "btn-active" : null}`}
                id="btn-next"
                onClick={handleSubmit}
                disabled={!safeToPass}
              >
                {onboarding_trans.button_next[lang]}
              </button>
            </div>
          </section>
        )}
      </section>

      {!step1 && !step2 && (
        <section className="fields-content">
          <div className="field-title ">
            {onboarding_trans.createAccoutnTitle[lang]}
          </div>
          <div className="field-description ">
            {onboarding_trans.createAccoutnDesc[lang]}
          </div>
          <div className="mt-40" />
          {error ? (
            <div className="avatar-description text-dander">
              🚫
              {
                onboarding_trans["email-verification-section"][
                  "verification-section"
                ][lang]
              }
            </div>
          ) : null}
          <input
            type="hidden"
            name="colab_id"
            defaultValue="vfeootmaIi3fjLktHy"
          />
          <div className="single-onboarding-input">
            <label htmlFor="">Email</label>
            {userData?.email ? (
              <span className="input-txt-disabled">
                {userData?.email}
                <img
                  src="/assets/img/icons/lock.svg"
                  alt=""
                  srcSet=""
                  style={{ marginLeft: 10 }}
                />
              </span>
            ) : (
              <>
                <div className="restric-ext">
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    className="fields-step-1 rounded-border-restrict"
                    placeholder=""
                    id="email"
                  />
                  {config.restrict_register_email_ext ? (
                    <span className="input-ext" id="mail_ext">
                      @{config.register_email_ext}
                    </span>
                  ) : null}
                </div>
              </>
            )}
          </div>
          <div className="mt-18" />
          <div className="single-onboarding-input">
            <label htmlFor="">{onboarding_trans["password-"][lang]}</label>
            <input
              type="password"
              name="password"
              onChange={handleChange}
              className="fields-step-1"
              placeholder=""
              id="password"
            />
          </div>
          <div className="mt-18" />
          <div className="single-onboarding-input">
            <label htmlFor="">
              {onboarding_trans["password-confirm"][lang]}
            </label>
            <input
              type="password"
              name="confirm_password"
              onChange={handleChange}
              className="fields-step-1"
              placeholder=""
              id="confirmPassword"
            />
          </div>
          <div className="button-container" style={{ zIndex: 0 }}>
            <button
              className={`btn-fields ${safeToPass ? "btn-active" : null}`}
              id="btn-next"
              onClick={handleSubmit}
              disabled={!safeToPass}
            >
              {onboarding_trans.button_next[lang]}
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export default Auth;
