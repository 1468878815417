import React, { useEffect, useState } from "react";
import { useGlobalState } from "../..";
import Close from "../../componants/Close";
import Header from "../../componants/onboarding/Header";
import Image from "../../componants/onboarding/Image/Image";
import AppServices from "../../services/AppServices";

const Images = ({ edit }) => {
  const [onboardingUser, setOnboardingUser] = useGlobalState("onboarding_user");
  const [data, setData] = useState([]);
  const [safe, setSafe] = useState(false);
  const [onboarding, setOnboarding] = useGlobalState("onboarding");
  const [config] = useGlobalState("config");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (config.restrict_images) {
      setData([
        ...config.images,
        ...onboardingUser.images.filter((image) => {
          if (image.is_admin) {
            return image;
          }
        }),
      ]);
    } else {
      setData([...onboardingUser.images]);
    }
  }, [onboardingUser.images, config.images]);

  useEffect(() => {
    setSafe(true);
  }, [data]);

  const onAdd = (e) => {
    AppServices.upload(e.target.files[0], "file", "/upload", {
      "Content-Type": e.target.files[0].type,
    }).then((response) => {
      setData([
        ...data,
        {
          image: response.url,
        },
      ]);
    });
  };

  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");

  const handleSubmit = () => {
    setLoading(true);
    AppServices.post(
      {
        action: 1,
        data: {
          onboarding_step: edit ? undefined : 9,
          images: data,
        },
      },
      "/onboarding"
    ).then((response) => {
      setLoading(false);
      if (edit) {
        seteditComponantId(-1);
      } else {
        setOnboarding({
          ...onboarding,
          step: onboarding.step + 1,
        });
      }
    });
  };

  const onDelete = (index) => {
    data.splice(index, 1);
    setData([...data]);
  };

  const [lang] = useGlobalState("lang_tran");
  const [onboarding_trans] = useGlobalState("onboarding_trans");
  return (
    <>
      {edit ? <Close /> : <Header />}
      <section className="fields-content">
        <div className="field-title ">Images</div>
        <div className="field-description ">
          {onboarding_trans.profile["images-section"].subtitle[lang]}
        </div>
        {!config.restrict_images ? (
          <div className="links-list-container " style={{ marginTop: 30 }}>
            <div className="single-link">
              <div
                className="link-thumbnail-container"
                style={{ width: "100%" }}
              >
                <div id="images-form">
                  <input
                    type="file"
                    className="d-none"
                    accept="image/*"
                    name="image"
                    id="file_image"
                    onChange={onAdd}
                  />
                </div>
                <label
                  className="thumbnail-text file-label"
                  htmlFor="file_image"
                  id="labelFile"
                >
                  {onboarding_trans.profile["images-section"].button[lang]}
                </label>
                <div className="thumbnail-icon">
                  <img
                    src="../../assets/img/icons/plus-circle.svg"
                    alt=""
                    srcSet=""
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="uploaded-images" id="images-area">
          {data.map((val, i) => {
            return (
              <Image
                img={`${process.env.REACT_APP_IMAGE_PATH}${val.image}`}
                onRemove={onDelete}
                id={i}
                lock={config.restrict_images}
              />
            );
          })}
        </div>
        <div className="mt-40" />
        {!config.restrict_images ? (
          <div className="button-container">
            <button
              className={safe ? "btn-fields btn-active" : "btn-fields"}
              id="btn-next"
              onClick={handleSubmit}
              disabled={!safe}
            >
              {edit ? (
                <img
                  src="/assets/img/loaders/loading.gif"
                  style={{
                    display: loading ? undefined : "none",
                    padding: "2px",
                  }}
                  width="23px"
                  alt=""
                  className="ml-[45%]"
                />
              ) : null}
              {loading
                ? null
                : edit
                ? onboarding_trans?.profile.button[lang]
                : onboarding_trans?.button_next[lang]}
            </button>
          </div>
        ) : null}
      </section>
    </>
  );
};

export default Images;
export { Images as ImagesOnboarding };
