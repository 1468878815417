import React from "react";
import { useGlobalState } from "..";
import useWindowOpen from "../hooks/useWindowOpen";

const Footer = ({ logged }) => {
  const [user] = useGlobalState("user");
  const openWindow = useWindowOpen;
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");
  const [lang] = useGlobalState("lang_tran");
  const [translate] = useGlobalState("onboarding_trans");
  const [branding] = useGlobalState("branding");
  const [config] = useGlobalState("config")
  return (
    <>
      <div id="footer-start"></div>
      <footer>
        <img
          src={"/assets/img/footer-logo.svg"}
          className="footer-logo"
          alt="PopCard"
          srcSet=""
          id="footer-start"
        />
        
          {/* <div className="footer-text">   
          {translate.footer[lang].replace("{{name}}", user.first_name)}
          </div> */}
          {!logged ? <div className="footer-buttons">
          <button
            className="btn-black-outline ml-0"
            onClick={() => {
              seteditComponantId(9);
            }}
          >
            Se connecter
          </button>
        </div>: null}
        

        <div className="copy-right-text">
          {"www.madaefgolfs.com"}
        </div>
      </footer>
    </>
  );
};

export default Footer;
