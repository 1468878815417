import React from "react";
import { useGlobalState } from "..";
import useTracking from "../hooks/useTracking";
import useWindowOpen from "../hooks/useWindowOpen";

const LinkContainer = ({ val, id }) => {
  const windowOpen = useWindowOpen;
  const { sendTracking, trackResult } = useTracking({
    action: 101,
    collaborator_id: id,
    componant: "Links",
    url: val.url,
    title: val.title,
  });
  const [lang] = useGlobalState("lang_tran");
  return (
    <div
      className="links-item"
      onClick={() => {
        sendTracking();
        windowOpen(val.url);
      }}
    >
      <div className="link-img-text">
        <div className="link-img">
          <div style={{ backgroundColor: "white" }}>
            <img
              src={
                val.thumb
                  ? `${process.env.REACT_APP_IMAGE_PATH}${val.thumb}`
                  : "/assets/img/icons/url.svg"
              }
              width={val.thumb ? "51px" : "25px"}
              height="51px"
              alt="img"
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
      <div className="link-texts">
        <div className="link-title">{val.title[lang]}</div>
        <div className="link-description">{val.description[lang]}</div>
      </div>
      <div className="link-item-arrow">
        <img src="../assets/img/icons/arrow-right.svg" alt="" srcSet="" />
      </div>
    </div>
  );
};

export default LinkContainer;
