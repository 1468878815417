import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoadingPage from "../componants/LoadingPage";
import AppServices from "../services/AppServices";

const Confirm = () => {
    const { id } = useParams()
    useEffect(() => {
        AppServices.post({
            action: 0,
            data: {
                id: id,
            }
        }, '/confirm').then((response) => {
            Object.keys(response).map((val) => {
                localStorage.setItem(val, response[val])
            })
            window.location = `/onboarding/${response.collaborator_id}`
        })
    }, [])
    
    return (
        <LoadingPage />
    )
}

export default Confirm