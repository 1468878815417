import React from "react";
import { useGlobalState } from "../..";

const DropDown = ({ id, setData, data, setSocial }) => {
  const [orders, setOrders] = useGlobalState("orders");

  const HandleChange = (store) => {
    data[id] = {
      ...data[id],
      store: store,
    };

    setData(data);
    setSocial(orders[store].label);
  };

  return (
    <div
      className="dropdown-social-list-containerr  z-[999] link-id-1743"
      style={{ display: "block" }}
    >
      {Object.keys(orders).map((val, i) => {
        return (
          <>
            <dropsocial
              className="single-social-dropdown"
              name={val}
              onClick={() => HandleChange(val)}
            >
              <dropsocial
                className="icon-social"
                onClick={() => HandleChange(val)}
              >
                <img
                  src={`../..${orders[val].icon}.png`}
                  alt={orders[val].label}
                  onClick={() => HandleChange(val)}
                />
              </dropsocial>
              <dropsocial
                className="text-social"
                onClick={() => HandleChange(val)}
              >
                {orders[val].label}
              </dropsocial>
            </dropsocial>
            <div className="divider divider-social" />
          </>
        );
      })}
    </div>
  );
};

export default DropDown;
