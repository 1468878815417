import React, { useEffect, useState } from "react";
import { useGlobalState } from "../..";
import AppServices from "../../services/AppServices";
import Image from "../onboarding/Image/Image";
import Fields from "../onboarding/Product/Fields";
import ImageProduct from "../onboarding/Product/ImageProduct";
import FoodFields from "../UI/FoodFields";

const AddProductModal = (props) => {
  const {
    setDataPrev,
    setAddPop,
    isEditing,
    edit,
    indexId,
    dataprev,
    changeTitle,
    setChangeTitle,
  } = props;
  const [user, setUser] = useGlobalState("user");
  const [onboardingUser, setOnboardingUser] = useGlobalState("onboarding_user");
  const [devise, setDevise] = useState(
    onboardingUser?.products[indexId].price?.currency || "MAD"
  );
  const [safe, setSafe] = useState(true);
  const [lang] = useGlobalState("lang_tran");
  const [onboarding_trans] = useGlobalState("onboarding_trans");
  const [data, setData] = useState(
    isEditing
      ? onboardingUser?.products[indexId]?.links.length > 0
        ? onboardingUser?.products[indexId]?.links
        : [
            {
              url: "",
              store: "6405b55177dbb3e4441f7985",
            },
          ]
      : [
          {
            url: "",
            store: "6405b55177dbb3e4441f7985",
          },
        ]
  );
  const [datapic, setDataPic] = useState([]);

  const [config] = useGlobalState("config");
  const [saveIsEmpty, setSaveIsEmpty] = useState(false);

  useEffect(() => {
    isEditing
      ? setDataPic([...onboardingUser.products[indexId].pictures])
      : setDataPic([]);
  }, [onboardingUser]);

  const onAddd = (e) => {
    AppServices.upload(e.target.files[0], "file", "/upload", {
      "Content-Type": e.target.files[0].type,
    }).then((response) => {
      const updatedProducts = [...onboardingUser.products];
      updatedProducts[indexId].pictures.push(response.url);
      setOnboardingUser({
        ...onboardingUser,
        products: updatedProducts,
      });
      setDataPic([...datapic, response.url]);
    });
  };

  const onDelete = (index) => {
    setDataPic(datapic.filter((_, i) => i !== index));

    // update onboardingUser
    const updatedProducts = [...onboardingUser.products];
    updatedProducts[indexId].pictures.splice(index, 1);
    setOnboardingUser({
      ...onboardingUser,
      products: updatedProducts,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newProduct = {
      title: onboardingUser?.products[indexId].title,
      price: {
        amount: parseFloat(onboardingUser?.products[indexId].price.amount)
          ? parseFloat(onboardingUser?.products[indexId].price.amount)
          : 0,
        currency: devise,
      },
      links: data,
      pictures: datapic,
    };

    const updatedProducts = [...onboardingUser?.products];

    if (isEditing) {
      // Update existing product
      updatedProducts[indexId] = newProduct;
    } else {
      // Add new product
      updatedProducts.push(newProduct);
    }

    AppServices.post(
      {
        action: 1,
        data: {
          products: updatedProducts,
        },
      },
      "/onboarding"
    )
      .then((res) => {
        setAddPop(false);
        setOnboardingUser({
          ...onboardingUser,
          products: updatedProducts,
        });
        // update user
        setUser({
          ...user,
          products: updatedProducts,
        });

        setDataPrev({
          ...onboardingUser,
          products: updatedProducts,
        });
        // update setDataPic

        setDataPic([...datapic, res.url]);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const [checksafe, setCheckSafe] = useState(false);

  useEffect(() => {
    // setSafe false if data is empty
    const isFoodFieldEmpty = data.some((field) => field.url === "");
    console.log(
      "onboardingUser from useeffect",
      onboardingUser?.products[indexId]
    );
    if (
      onboardingUser?.products[indexId]?.title[lang] === "" ||
      datapic.length === 0 ||
      isFoodFieldEmpty
    ) {
      setSafe(false);
      setCheckSafe(true);
    } else {
      setSafe(true);
      setCheckSafe(false);
    }
  }, [onboardingUser.products[indexId], data, datapic]);

  // useEffect(() => {
  //   if (!checksafe) {
  //     setSafe(false);
  //   }
  // }, [checksafe, onboardingUser]);

  useEffect(() => {
    if (isEditing) {
      setSafe(true);
      setCheckSafe(true);
      if (onboardingUser?.products[indexId]?.price?.amount !== 0) {
        setSafe(true);
        setCheckSafe(true);
      }
    }
  }, [onboardingUser]);

  return (
    <div className="fixed inset-0 bg-[#00000060] z-[99999999] flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-md w-[90%] max-h-[550px] relative overflow-auto">
        <button
          className="text-gray-400 hover:text-gray-500 focus:outline-none absolute right-[1rem]"
          onClick={() => setAddPop(false)}
        >
          <img
            src="/assets/img/icons/close.svg"
            alt="close"
            className="max-w-[17px] object-contain"
          />
        </button>
        <div className="flex items-center justify-between mb-4 mt-[1.5rem]">
          <h2 className="text-[20px] font-[500] text-primaryP">
            {changeTitle
              ? onboarding_trans.profile["products-and-services-section"]
                  .addProductModal.titleEdit[lang]
              : onboarding_trans.profile["products-and-services-section"]
                  .addProductModal.titleAdd[lang]}
          </h2>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2 father_input">
            <label htmlFor="name" className="text-[14px] font-[500] text-black">
              {
                onboarding_trans.profile["products-and-services-section"]
                  .addProductModal.name[lang]
              }
            </label>
            <input
              type="text"
              name="title"
              id="name"
              className="input-custom-Product"
              defaultValue={
                isEditing ? onboardingUser?.products[indexId]?.title[lang] : ""
              }
              onChange={(e) => {
                const newProducts = [...onboardingUser.products];
                newProducts[indexId] = {
                  ...newProducts[indexId],
                  title: {
                    [lang]: e.target.value,
                    [lang === "fr" ? "en" : "fr"]:
                      onboardingUser?.products[indexId]?.title[
                        lang === "fr" ? "en" : "fr"
                      ],
                  },
                };
                setOnboardingUser({
                  ...onboardingUser,
                  products: newProducts,
                });
              }}
            />
          </div>
          <div className="flex gap-5 items-center mt-[-2rem]">
            <div className="flex flex-col gap-2  father_input">
              <label
                htmlFor="price"
                className="text-[14px] font-[500] text-black"
              >
                {
                  onboarding_trans.profile["products-and-services-section"]
                    .addProductModal.prix[lang]
                }
              </label>
              <input
                type="text"
                name="price"
                id="price"
                className="input-custom-Product"
                defaultValue={
                  isEditing
                    ? onboardingUser?.products[indexId]?.price.amount
                    : ""
                }
                onChange={(e) => {
                  const newProducts = [...onboardingUser.products];
                  newProducts[indexId] = {
                    ...newProducts[indexId],
                    price: {
                      ...newProducts[indexId].price,
                      amount: e.target.value ?? 0,
                    },
                  };
                  setOnboardingUser({
                    ...onboardingUser,
                    products: newProducts,
                  });
                }}
              />
            </div>
            <Fields
              data={onboardingUser?.products[indexId]?.price.currency}
              setDevise={setDevise}
              devise={devise}
            />
          </div>
        </div>
        <label className="text-[14px] font-[500] text-black">
          {
            onboarding_trans.profile["products-and-services-section"]
              .addProductModal.image[lang]
          }
        </label>
        {datapic.length > 0 ? (
          ""
        ) : (
          <div className="links-list-container " style={{ marginTop: 10 }}>
            <div className="single-link">
              <input
                type="file"
                className="d-none"
                accept="image/*"
                name="pictures"
                id="file_imagee"
                onChange={onAddd}
              />
              <label className="w-full" htmlFor="file_imagee" id="labelFile">
                <div
                  className="link-thumbnail-container"
                  style={{ width: "100%" }}
                >
                  <div id="images-form">
                    <input
                      type="file"
                      className="d-none"
                      accept="image/*"
                      name="pictures"
                      id="file_imagee"
                      onChange={onAddd}
                    />
                  </div>
                  <label
                    className="thumbnail-text file-label"
                    htmlFor="file_imagee"
                    id="labelFile"
                  >
                    {onboarding_trans.profile["images-section"].button[lang]}
                  </label>
                  <div className="thumbnail-icon">
                    <img
                      src="../../assets/img/icons/plus-circle.svg"
                      alt=""
                      srcSet=""
                    />
                  </div>
                </div>
              </label>
            </div>
          </div>
        )}

        <div className="uploaded-images" id="images-area">
          {datapic.map((val, i) => {
            return (
              <ImageProduct
                img={`${process.env.REACT_APP_IMAGE_PATH}${val}`}
                onRemove={onDelete}
                id={i}
                lock={config.restrict_products}
              />
            );
          })}
          {datapic.length > 2 || datapic.length === 0 ? (
            ""
          ) : (
            <div className="links-list-containerr " style={{ marginTop: 30 }}>
              <div className="single-linkk">
                <div
                  className="link-thumbnail-containerr"
                  style={{ width: "100%" }}
                >
                  <div id="images-form">
                    <input
                      type="file"
                      className="d-none"
                      accept="image/*"
                      name="pictures"
                      id="file_imagee"
                      onChange={onAddd}
                    />
                  </div>
                  <label
                    className="thumbnail-text file-label"
                    htmlFor="file_imagee"
                    id="labelFile"
                  >
                    {onboarding_trans.profile["images-section"].button[lang]}
                  </label>
                  <div className="thumbnail-icon">
                    <img
                      src="../../assets/img/icons/plus-circle.svg"
                      alt=""
                      srcSet=""
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="list-social-container mt-[-9.5rem] "
          id="list-social-container"
          style={{
            height: "auto",
            display: "block",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <label
            htmlFor="link"
            className="text-[14px] font-[500] text-black mb-[1rem]"
          >
            {
              onboarding_trans.profile["products-and-services-section"]
                .addProductModal.link[lang]
            }
          </label>
          {data.map((val, i) => {
            return (
              <FoodFields
                id={i}
                isEditing={isEditing}
                data={data}
                setData={setData}
                value={val}
                configRestriction={config.restrict_products}
                setSafe={setSafe}
                checksafe={checksafe}
              />
            );
          })}
        </div>
        <div className="mt-40" />
        <div
          className="icon-button-container"
          id="_add_new_social_link"
          onClick={() => {
            setData([
              ...data,
              {
                url: "",
                store: "6405b55177dbb3e4441f7985",
              },
            ]);
          }}
        >
          {!config.restrict_socials ? (
            <>
              <div className="button-icon">
                <img
                  src="../../assets/img/icons/plus-circle.svg"
                  alt=""
                  srcSet=""
                />
              </div>
              <div className="button-text">
                {
                  onboarding_trans.profile["products-and-services-section"]
                    .addProductModal.button[lang]
                }
              </div>
            </>
          ) : null}
        </div>

        <div className="mt-40" />
        {!config.restrict_products ? (
          <div className="button_container_poduct">
            <button
              className={safe ? "btn-fields btn-active" : "btn-fields"}
              id="btn-next"
              onClick={handleSubmit}
              disabled={!safe}
            >
              {onboarding_trans.profile.button[lang]}
            </button>
          </div>
        ) : null}
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default AddProductModal;
