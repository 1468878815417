import React, { useEffect, useState } from "react";
import { useLocation, useNavigation, useParams } from "react-router-dom";
import { useGlobalState } from "..";
import useTracking from "../hooks/useTracking";
import AppServices from "../services/AppServices";
import LoadingPage from "./LoadingPage";

const MainLoading = () => {
  const { id } = useParams();
  const [onboarding, setOnboarding] = useGlobalState("onboarding");
  const [data, setData] = useState({
    "U1tsS5lENooFs2eF6p": "IW41PCB3HRXSIHcyny"
  })

  const collaborator_redirections = (id) => {
    console.log("keys", Object.keys(data).includes(id), Object.keys(data))
    if (Object.keys(data).includes(id)){
      return data[id]
    }else{
      return id
    }

  }

  const extractMainDomain = (url) => {
    var domain;
    if (url.indexOf("://") > -1) {
      domain = url.split('/')[2];
    }
    else {
      domain = url.split('/')[0];
    }
    domain = domain.split(':')[0];
    var parts = domain.split('.');
    var mainDomain = parts[parts.length - 2] + '.' + parts[parts.length - 1];
  
    return "https://" + mainDomain;
  }
  
  

  useEffect(() => {

    if (!id) {
      window.location = extractMainDomain(window.location.hostname);
    } else {
      AppServices.post(
        {
          action: 0,
          data: {
            id: id,
          },
        },
        "/collaborators"
      ).then((response) => {
        if (!response.data[0].active) {
          window.location = extractMainDomain(window.location.hostname);
        } else if (response.data[0].onboarding_step === 10) {
          window.location = `/profile/${collaborator_redirections(id)}`;
        } else {
          setOnboarding({
            ...onboarding,
            step: response.data[0].onboarding_step,
          });
          window.location = `/onboarding/${id}`;
        }
      });
    }
  }, []);

  return <LoadingPage />;
};

export default MainLoading;
