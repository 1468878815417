import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "../styles/Switch.scss";
import useTracking from "../hooks/useTracking";
import { useGlobalState } from "..";
import AppServices from "../services/AppServices";
import useWindowOpen from "../hooks/useWindowOpen";

const CarrouselProductPreview = ({
  images,
  currentImage,
  setCurrentImage,
  popup,
  setPopup,
}) => {
  const [user] = useGlobalState("user");
  const [lang] = useGlobalState("lang_tran");
  const { id } = useParams();
  const [favicons, setFavicons] = useState([]);
  const findproduct = images.find((item, index) => index === currentImage);

  const [orders] = useGlobalState("orders");
  const [currentLink, setCurrentLink] = useState(-1);
  const { sendTracking, trackResult } = useTracking({
    action: 170,
    collaborator_id: id,
    componant: "LinksProduct",
    title: findproduct?.title,
    url: findproduct?.links[currentLink]?.url,
    social_id: orders[findproduct?.links[currentLink]?.store]?.label,
  });


  function addHttp(url) {
    if (!/^(f|ht)tps?:\/\//i.test(url)) {
      url = `http://${url}`;
    }
    return url;
  }

  const [url, setUrl] = useState("");
  useEffect(() => {
    if (findproduct?.links && findproduct?.links.length > 0) {
      const initialUrl = findproduct.links[0].url;
      setUrl(addHttp(initialUrl));
    }
  }, [findproduct]);

  useEffect(() => {
    if (findproduct?.links && findproduct?.links.length > 0) {
      findproduct.links.forEach((link, index) => {
        if (link.store === "64066931621af85e8b8c230b") {
          const url = addHttp(link.url);
          AppServices.post(
            {
              action: 9,
              data: {
                url: url,
              },
            },
            "/app"
          ).then((res) => {
            setFavicons((prevFavicons) => {
              const newFavicons = [...prevFavicons];
              if (res?.favicon) {
                newFavicons[index] = res.favicon;
              }
              return newFavicons;
            });
          });
        }
      });
    }
  }, [findproduct]);

  const commander = {
    fr: "Commander sur:",
    en: "Order on:",
  };

  const openWindow = useWindowOpen;

  useEffect(() => {
    if (currentLink !== -1) {
      console.log("Send Tracking");
      console.log("currentLink", currentLink);
      console.log("currentImage", currentImage);
      console.log("findproduct", findproduct);
      console.log("title", findproduct?.title[lang]);
      console.log(
        "titleLink by store order",
        findproduct?.links[currentLink]?.store
      );
      console.log("url", findproduct?.links[currentLink]?.url);
      sendTracking();
    }
  }, [currentLink]);
  return (
    <div className=" w-[100%] h-[100vh] fixed top-0 z-[998] back">
      <div className="w-[100%] h-[100vh] z-[999] flex justify-center items-center">
        <div className="w-[90%] z-[999]  bg-white top-0 rounded-lg flex flex-col justify-center items-center relative">
          <div
            className="w-[100%] h-[100%] mt-[-20px] top-0 flex justify-center items-center"
            id="swiperPrevImagg"
          >
            <Swiper
              modules={[Pagination, Navigation]}
              slidesPerView={
                images.length > 1 ? 1 : images.length === 1 ? 1 : 1
              }
              keyboard={true}
              mousewheel={true}
              pagination={{ enabled: true, clickable: true }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              className="
                swiper-carousel top-0 mt-0
              "
              id="swiperPrevfather"
            >
              {findproduct?.pictures?.map((item, index) => (
                <SwiperSlide key={index} id="swiperPrevImae">
                  <div className="w-[100%] h-[235.51px]   top-0 left-0 z-[999] flex justify-center items-center relative">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_PATH}` + item}
                      alt="imagee"
                      id="productImagePrev"
                      className="w-[100%] h-[100%]  rounded-t-lg object-cover "
                    />

                    <div className="back-carousel-containerr"></div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {/* price */}
          <div className="absolute top-48 z-[999999] right-5 text-white text-[20px]">
            {findproduct?.price.amount === 0 || !findproduct?.price.amount
              ? ""
              : findproduct?.price.amount}{" "}
            <span className="text-[16px] font-normal">
              {findproduct?.price.amount === 0 || !findproduct?.price.amount
                ? ""
                : findproduct?.price.currency}
            </span>
          </div>
          {/* end price */}
          <div className="w-[90%] z-[999] h-[10%] flex justify-between items-center flex-col">
            <div className="w-[100%] h-[100%] flex justify-start items-center">
              <p
                className="text-[#000000] font-[500] text-[18px] w-[100%] mt-[10px]"
                style={{ wordWrap: "break-word" }}
              >
                {findproduct?.title[lang]}
              </p>
            </div>
            <div className="w-[100%] h-[100%] flex mt-[1rem] items-center">
              <h1 className="text-[18px] font-[400] text-[#4E3131] ">
                {commander[lang]}
              </h1>
            </div>
            {/* show order by store*/}
            <div className="flex gap-2  flex-wrap w-full mt-2 mb-2">
              {findproduct?.links?.map((item, index) => (
                <div
                  className="flex flex-col justify-between"
                  key={index}
                  onClick={() => {
                    setCurrentLink(index);
                  }}
                >
                  <div className="text-[#101010] font-[400] text-[14px]">
                    {/* look in orders by store */}
                    {orders[item.store]?.label === "Autre" ? (
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => openWindow(item.url)}
                        onChange={() => setUrl(item.url)}
                      >
                        <img
                          src={
                            favicons[index] !== undefined
                              ? `${process.env.REACT_APP_IMAGE_PATH}${favicons[index]}`
                              : `../..${orders[item.store]?.icon}.svg`
                          }
                          alt={orders[item.store]?.label}
                          className="max-w-[65px] h-[65px] shadow-lg object-contain p-4 rounded-lg"
                        />
                      </a>
                    ) : orders[item.store]?.label === "Whatsapp" ? (
                      <a
                        href={`https://api.whatsapp.com/send?phone=${item.url}&text=J'aimerais commander ${findproduct.title}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={`../..${orders[item.store]?.icon}.png`}
                          alt={orders[item.store]?.label}
                          className="max-w-[65px] h-[65px] shadow-lg object-contain rounded-lg"
                        />
                      </a>
                    ) : (
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => openWindow(item.url)}
                      >
                        <img
                          src={`../..${orders[item.store]?.icon}.png`}
                          alt={orders[item.store]?.label}
                          className="max-w-[65px] h-[65px] shadow-lg object-contain rounded-lg"
                        />
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-[#00000068] w-[100%] h-[100vh]  fixed top-0"
        onClick={() => {
          setPopup(false);
        }}
      />
    </div>
  );
};

export default CarrouselProductPreview;
