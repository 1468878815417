import React, { useCallback, useEffect, useRef, useState } from "react";
import { useGlobalState } from "..";
import { add, getTime } from "date-fns";
import AppServices from "../services/AppServices";
import Switch from "./UI/Switch";
import CopyToClipboard from "./CopyToClipboard";

const SettingCode = () => {
  const [user, setUser] = useGlobalState("user");
  const [lang] = useGlobalState("lang_tran");
  const [trans] = useGlobalState("onboarding_trans");
  const [refresh, setRefresh] = useGlobalState("refresh");
  const [firstCheck, setFirstCheck] = useState(false);
  const [now, setNow] = useGlobalState("nowUtc");

  const [activeLock, setActiveLock] = useState(user?.contact_locked);
  const [countdown, setCountdown] = useState(-1.5);

  const changeLock = (current_status) => {
    AppServices.post(
      {
        action: 5,
        data: {
          contact_locked: current_status,
        },
      },
      "/app"
    ).then((response) => {
      setRefresh(refresh + 1);
    });
  };

  useEffect(() => {
    const seconds = dateDifferenceInSeconds(user?.contact_lock?.created_on);

    if (seconds && !firstCheck) {
      console.log(seconds);
      if (seconds >= 900 && user?.contact_locked) {
        changeLock(true);
        generateLock("from user contact lock");
        setCountdown(900);
      } else {
        setCountdown(
          secondsLeftToReach15Minutes(user?.contact_lock?.created_on)
        );
      }
      setFirstCheck(true);
    }
  }, [user.contact_lock]);

  const tickRef = useRef(false);
  useEffect(() => {
    if (tickRef.current) return;

    let intervalId;

    if (countdown > 0) {
      intervalId = setInterval(() => {
        tickRef.current = true;
        setCountdown((prevCountdown) => prevCountdown - 1);
        tickRef.current = false;
      }, 1000);
    } else if (countdown !== -1.5 && user?.contact_locked === true) {
      changeLock(true);
      generateLock("from countdown");
      setCountdown(900);
    }

    return () => clearInterval(intervalId);
  }, [countdown]);

  const secondsLeftToReach15Minutes = (createdOn) => {
    const isoDateString = createdOn?.replace(" ", "T");
    const createdDate = new Date(isoDateString);
    const nowUTC = new Date(now);
    const timeDifference = nowUTC - createdDate;
    const elapsedSeconds = Math.floor(timeDifference / 1000);
    const totalSeconds = 15 * 60;
    const secondsLeft = totalSeconds - elapsedSeconds;

    return secondsLeft > 0 ? secondsLeft : 0;
  };

  function secondsToFormat(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  }

  const dateDifferenceInSeconds = (createdOn) => {
    const isoDateString = createdOn?.replace(" ", "T");
    const createdDate = new Date(isoDateString);
    const nowUTC = new Date(now);
    const timeDifference = nowUTC - createdDate;
    return Math.floor(timeDifference / 1000);
  };

  const generateLock = (s) => {
    AppServices.post(
      {
        action: 4,
      },
      "/app"
    ).then((response) => {
      setRefresh(refresh + 1);
      setCountdown(900);
    });
  };

  const handleContactLockChange = () => {
    if (!activeLock) {
      generateLock();
    }
    changeLock(!activeLock);
    setActiveLock(!activeLock);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex gap-5">
          <img
            src="/assets/img/icons/v-icon.svg"
            alt=""
            className="setting-icon"
          />
          <div className="text-[17px] max-w-[250px]">
            {trans.settings["temporary-code-section"].title[lang]}
          </div>
        </div>
        <Switch
          checked={activeLock}
          onChange={handleContactLockChange}
          name="checkedA"
          inputProps={{ "aria-label": "controlled" }}
          value={activeLock}
        />
      </div>
      <div className="mt-[-10px] ml-[43px] overflow-hidden">
        {activeLock ? (
          <div className="text-[16px] text-[#29A254]">
            {trans.settings["temporary-code-section"].active[lang]}
          </div>
        ) : (
          <div className="text-[16px] text-[#909391]">
            {trans.settings["temporary-code-section"].inactive[lang]}
          </div>
        )}
        {/* Votre code temporaire */}
        {activeLock && (
          <>
            <div className="text-[18px] text-[#4E3131] mt-1">
              {trans.settings["temporary-code-section"].subtitle[lang]}
            </div>
            <div className=" text-black mt-1 flex justify-between">
              <div className="text-[22px] flex items-center gap-2">
                <CopyToClipboard value={user?.contact_lock?.password} />
                <span className="text-black mt-2">
                  {user?.contact_lock?.password?.replace(/\d{2}(?=\d)/g, "$& ")}
                </span>
              </div>

              <p className="flex items-center">
                ({secondsToFormat(countdown)}
                <img
                  src="/assets/img/icons/clock.svg"
                  alt=""
                  className="w-[15px] h-[15px] ml-1"
                />
                )
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SettingCode;
