import { configure } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../..";
import WhatsappSocials from "../WhatsappSocials";
import DropDown from "./DropDown";

const FoodFields = ({
  id,
  setData,
  data,
  value,
  safeCheck,
  locked,
  configRestriction,
  edit,
  setSafe,
  checksafe,
}) => {
  const [orders, setOrders] = useGlobalState("orders");
  const [showDropDown, setShowDropDown] = useGlobalState("dropdown_id");
  const [currentSocialMedia, setCurrentSocialMedia] = useState("Linkedin");
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");
  const [onboardingUser, setOnboardingUser] = useGlobalState("onboarding_user");

  const handleDelete = (id) => {
    data.splice(id, 1);
    setData([...data]);
  };

  useEffect(() => {
    setCurrentSocialMedia(value ? orders[value.store]?.label : "Jumia");
  }, [value]);

  
  const HandleChange = (e) => {
    const newData = [...data]; // create a copy of the data array
    newData[id] = {
      ...newData[id],
      [e.target.name]: e.target.value,
    };
    setData(newData); // update the state with the new array
    safeCheck();
  };

  useEffect(() => {
    // Check if all input values are filled
    // console.log("data", data);
    const filter = data.map((link) => {
      if (link.url === "") {
        return false;
      } else {
        return true;
      }
    });
    if (filter.includes(false)) {
      setSafe(false);
    } else if (!checksafe) {
      setSafe(true);
    }
  }, [data]);

  useEffect(() => {
    setShowDropDown(-1);
  }, [currentSocialMedia]);

  const handleWhatsappChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    data[id] = {
      ...data[id],
      url:
        name === "prefix"
          ? value + "-" + data[id].url.split("-")[1]
          : data[id].url.split("-")[0] + "-" + value,
    };
    setData(data);
    safeCheck();
  };

  useEffect(() => {
    if (editComponantId === 12 || !edit) {
      function handleClick(e) {
        if (
          e.target.localName !== "dropsocial" &&
          e.target.localName !== "dropvideo"
        ) {
          setShowDropDown(false);
        }
      }
      document.body.addEventListener("click", handleClick);

      return () => {
        document.body.removeEventListener("click", handleClick);
      };
    }
  }, []);

  return (
    <div className="single-social " id="">
      <dropsocial className="social-section-delete-info">
        <div className="social-header">
          <dropsocial
            className="drop-social"
            onClick={() => setShowDropDown(id)}
          >
            <dropsocial
              className="text-drop-social-1"
              onClick={() => setShowDropDown(id)}
            >
              {" "}
              {currentSocialMedia}{" "}
            </dropsocial>
            <dropsocial
              className="arrow-drop-social"
              onClick={() => setShowDropDown(id)}
            >
              {!locked && !configRestriction ? (
                <img
                  src="../../assets/img/icons/arrow-down.svg"
                  alt=""
                  onClick={() => setShowDropDown(id)}
                />
              ) : (
                <img src="../../assets/img/icons/lock.svg" alt="" srcset="" />
              )}
            </dropsocial>
          </dropsocial>
        </div>
        {!locked && !configRestriction ? (
          <dropsocial className="section-top-buttons">
            <dropsocial
              className="btn-delete"
              onClick={() => {
                handleDelete(id);
              }}
            >
              <img src="../../assets/img/icons/delete.svg" alt="" srcSet="" />
            </dropsocial>
            {/* <dropsocial className="btn-drag">
                        
                        <img
                        src="../../assets/img/icons/drag-icon.svg"
                        alt=""
                        srcSet=""
                        />
                    </dropsocial> */}
          </dropsocial>
        ) : null}
        {id === showDropDown && !locked && !configRestriction ? (
          <DropDown
            id={id}
            setData={setData}
            data={data}
            setSocial={setCurrentSocialMedia}
          />
        ) : null}
      </dropsocial>
      <div className="mt-15" />

      {locked && !value.wasEmpty ? (
        <>
          <div
            className="underline-input"
            style={{
              borderBottom:
                !locked || value.wasEmpty ? "0.7px solid #ccb7b7" : "none",
            }}
          >
            {value?.url}
          </div>
        </>
      ) : data[id]?.store === "6406691c621af85e8b8c230a" ? (
        <WhatsappSocials
          value={value}
          locked={locked}
          HandleChange={handleWhatsappChange}
        />
      ) : (
        <div
          className="underline-input"
          style={{
            borderBottom:
              !locked || value.wasEmpty ? "0.7px solid #ccb7b7" : "none",
          }}
        >
          <input
            type="text"
            className="url"
            onChange={HandleChange}
            name="url"
            placeholder="www"
            defaultValue={value?.url}
          />
        </div>
      )}
      <div className="mt-15" />
    </div>
  );
};

export default FoodFields;
