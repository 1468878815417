import React, { useEffect } from "react";
import { useGlobalState } from "../../..";
import Deviser from "../../Deviser";

const AddLinks = ({ onUpload, id, removeLink, value, data, locked }) => {
  const [lang] = useGlobalState("lang_tran");
  const [trans] = useGlobalState("onboarding_trans")
  const [, setData] = useGlobalState("onboarding_user");
  const [image, setImage] = useGlobalState("image");
  const fileToDataUri = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onload = (event) => {
      setImage({
        id: id,
        image: event.target.result,
      });
      onUpload(true);
    };
    reader.readAsDataURL(file);
  };

  const removeImage = () => {
    data.links[id] = {
      ...data.links[id],
      thumb: "",
    };
    setData({
      ...data,
      links: [...data.links],
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => {
      const newLinks = [...prevState.links];
      const link = { ...newLinks[id] };

      if (name === "title") {
        link.title = {
          ...link.title,
          [lang]: value,
          [lang === "en" ? "fr" : "en"]: link.title[lang === "en" ? "fr" : "en"]
            ? link.title[lang === "en" ? "fr" : "en"]
            : "",
        };
      } else if (name === "description") {
        link.description = {
          ...link.description,
          [lang]: e.target.value,
          [lang === "en" ? "fr" : "en"]: link.description[
            lang === "en" ? "fr" : "en"
          ]
            ? link.description[lang === "en" ? "fr" : "en"]
            : "",
        };
      } else {
        link[name] = value;
      }

      newLinks[id] = link;
      return { ...prevState, links: newLinks };
    });
  };

  return (
    <div className="single-link-wrapper" id="link-0">
      <div className="section-top-buttons" style={{ marginTop: "20px" }}>
        {locked ? (
          <>
            <img src="../../assets/img/icons/lock.svg" alt="" srcset="" />
          </>
        ) : (
          <>
            <div
              className="btn-delete"
              onClick={() => removeLink(id)}
              style={{ gap: 0, gridGap: 0, marginBottom: "2vh" }}
            >
              <img src="../../assets/img/icons/delete.svg" alt="" srcSet="" />
            </div>
            <div className="btn-drag">
              {/* <img
                    src="../../assets/img/icons/drag-icon.svg"
                    alt=""
                    srcSet=""
                /> */}
            </div>
          </>
        )}
      </div>
      <div className="single-link">
        <div
          className="link-thumbnail-container link-thumbnail-container"
          style={{
            background: `url(${process.env.REACT_APP_IMAGE_PATH}${value.thumb})`,
            border: value.thumb !== "" ? "none" : null,
          }}
        >
          {!locked ? (
            value.thumb === "" ? (
              <>
                <input
                  type="file"
                  accept="image/*"
                  className="files"
                  name="images"
                  onChange={fileToDataUri}
                  style={{ display: "none" }}
                  id="file"
                />
                <label
                  htmlFor="file"
                  id="label-0"
                  className="thumbnail-text file-label thumbnail-text-0"
                >
                  {trans.profile["links-section"].button[lang]}
                </label>
                <div className="thumbnail-icon">
                  <img
                    src="../../assets/img/icons/plus-circle.svg"
                    alt=""
                    srcSet=""
                  />
                </div>
              </>
            ) : (
              <div className="delete-wrapper" onClick={removeImage}>
                <div className="delete-section">{trans.delete[lang]}</div>
              </div>
            )
          ) : null}
        </div>
        <div className="link-form">
          <input
            type="text"
            style={{ padding: locked ? "5px 0px" : undefined }}
            name="url"
            className="url"
            defaultValue={value.url}
            placeholder="URL*"
            disabled={locked}
            onChange={handleChange}
          />
          <Deviser locked={locked} />
          <input
            type="text"
            name="title"
            className="title"
            style={{ padding: locked ? "5px 0px" : undefined }}
            defaultValue={value.title[lang]}
            onkeyup="toggleButtonNextLinks()"
            placeholder={trans.titre[lang]}
            disabled={locked}
            onChange={handleChange}
          />
          <Deviser locked={locked} />
          <textarea
            name="description"
            defaultValue={value.description[lang]}
            className="input-textarea1 area-links description"
            placeholder="Description"
            spellCheck="false"
            disabled={locked}
            onChange={handleChange}
          />
          <Deviser locked={locked} />
        </div>
      </div>
    </div>
  );
};

export default AddLinks;
