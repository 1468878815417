import React from "react";
import { useGlobalState } from "../../..";

const DropDown = ({ id, setData, data, setSocial }) => {
  const [socials, setSocials] = useGlobalState("socials");

  const HandleChange = (social_id) => {
    data[id] = {
      ...data[id],
      social_id: social_id,
    };

    setData(data);
    setSocial(socials[social_id].label);
  };

  return (
    <div
      className="dropdown-social-list-container link-id-1743"
      style={{ display: "block" }}
    >
      {Object.keys(socials).map((val, i) => {
        return (
          <>
            <dropsocial
              className="single-social-dropdown"
              name={val}
              onClick={() => HandleChange(val)}
            >
              <dropsocial
                className="icon-social"
                onClick={() => HandleChange(val)}
              >
                <img
                  src={`../../assets/img/socials/${socials[val].icon}`}
                  alt={socials[val].label}
                  onClick={() => HandleChange(val)}
                />
              </dropsocial>
              <dropsocial
                className="text-social"
                onClick={() => HandleChange(val)}
              >
                {socials[val].label}
              </dropsocial>
            </dropsocial>
            <div className="divider divider-social" />
          </>
        );
      })}
    </div>
  );
};

export default DropDown;
