import { useState, useRef, useEffect } from "react";
import debounce from "lodash/debounce";
import { useGlobalState } from "..";

const Identification = (props) => {
  const { setShow, expactedCode, handleClick, setPopup } = props;
  const [code, setCode] = useState(["", "", "", ""]);
  const inputRefs = useRef([]);
  const [codeError, setCodeError] = useState(false);
  const [lang] = useGlobalState("lang_tran");
  const [trans] = useGlobalState("onboarding_trans");
  const [now, setNow] = useGlobalState("nowUtc");
  const [user, setUser] = useGlobalState("user");

  const handleChange = (index, event) => {
    const { value } = event.target;
    setCode((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = value;
      return newArray;
    });

    // Move focus to next input if current input is filled
    if (value !== "" && index < inputRefs.current.length - 1) {
      setTimeout(() => {
        inputRefs.current[index + 1].focus();
      }, 10);
    }

    // Move focus to previous input if current input is empty
    if (value === "" && index > 0) {
      setTimeout(() => {
        inputRefs.current[index - 1].focus();
      }, 10);
    }
  };

  const dateDifferenceInSeconds = (createdOn) => {
    const isoDateString = createdOn.replace(" ", "T");
    const createdDate = new Date(isoDateString);
    const nowUTC = new Date(now);
    // alert(`created on date ${createdDate}`)
    const timeDifference = nowUTC - createdDate;
    console.log(timeDifference);
    return Math.floor(timeDifference / 1000);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if code matches expected value
    const expectedCodeArray = expactedCode.split("");
    const isMatching = code.every(
      (digit, index) => digit === expectedCodeArray[index]
    );

    // Check if createdOn is within 15 minutes of current time
    const diffInMinutes = dateDifferenceInSeconds(
      user?.contact_lock?.created_on
    );
    const isWithinFifteenMinutes = diffInMinutes < 900;
    // alert(`is 15min ${isWithinFifteenMinutes} utc date: ${now} created_on ${user?.contact_lock?.created_on}`)
    if (isMatching && isWithinFifteenMinutes) {
      handleClick();
      setShow(false);

      // setTimeout for 1500ms
      setTimeout(() => {
        setPopup(true);
      }, 1500);
    }

    // Set error class if code doesn't match and all input values are filled
    const isAllInputFilled = code.every((digit) => digit !== "");
    let error = false;
    if (isAllInputFilled) {
      if (!isMatching) {
        error = true;
      } else if (!isWithinFifteenMinutes) {
        error = true;
      }
    }

    setCodeError(error);
    inputRefs.current.forEach((inputRef) => {
      if (error) {
        inputRef.classList.add("identification-login-error");
      } else {
        inputRef.classList.remove("identification-login-error");
      }
    });

    // Reset code if it doesn't match expected value or is not within 15 minutes
    if (!isMatching || !isWithinFifteenMinutes) {
      setCode(["", "", "", ""]);
      inputRefs.current[0].focus();
    }
  };

  return (
    <div className="fixed top-[30%] text-center w-[90%]  flex items-center justify-center bg-white  z-50 p-8 rounded-xl">
      <form onSubmit={handleSubmit}>
        <label>
          <span className="text-[24px]">
            {trans.profile["identification-popup"].title[lang]}{" "}
          </span>
          <br />
          <div className="text-[14px] text-[#4E3131] mb-3">
            {codeError ? (
              <span className="text-red-500">
                {trans.profile["identification-popup"].error[lang]}
              </span>
            ) : (
              trans.profile["identification-popup"].subtitle[lang]
            )}
          </div>
          <div className="flex gap-2 mb-3 justify-center">
            {[...Array(4)].map((_, index) => (
              <input
                key={index}
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength="1"
                className="identification-login"
                style={{ borderColor: "#CBD5E0" }}
                value={code[index]}
                onChange={(event) => handleChange(index, event)}
                onKeyDown={(event) => {
                  // Move focus to next input on numeric key press
                  if (
                    /^[0-9]$/.test(event.key) &&
                    index < inputRefs.current.length - 1
                  ) {
                    inputRefs.current[index + 1].focus();
                  }
                }}
                ref={(el) => (inputRefs.current[index] = el)}
                disabled={index > 0 && code[index - 1] === ""}
              />
            ))}
          </div>
        </label>
        <br />
        <button
          type="submit"
          className="px-4 py-4 rounded-lg w-full bg-black text-white mt-4"
        >
          {trans.profile["identification-popup"].button[lang]}
        </button>
      </form>
    </div>
  );
};

export default Identification;
