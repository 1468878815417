import React, { useEffect, useState } from "react";
import { useGlobalState } from "..";
import AppServices from "../services/AppServices";
import Setting from "./Setting";

const EditButtons = ({ onUpload }) => {
  const [pop, setPop] = useState(false);
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");
  const [image, setImage] = useGlobalState("image");

  const [lang] = useGlobalState("lang_tran");
  const [translate] = useGlobalState("onboarding_trans");
  const [now, setNow] = useGlobalState("nowUtc");
  const metterButton = {
    fr: "Mettre à jour le membre",
    en: "Update Member",
  };

  const getUtcDate = () => {
    AppServices.post(
      {
        action: 10,
      },
      "/app"
    ).then((response) => {
      const now = new Date(response.utc);
      setNow(now);
    });
  };

  useEffect(() => {
    getUtcDate();
  }, [pop]);
  return (
    <section class="contact-buttons">
      <button
        class="btn btn-primary flex-center"
        onClick={() => {
          window.localStorage.clear()
          window.location.reload()
        }}
      >
        <span className="max-w-[120px]">
          {translate.profile.buttonLogout[lang]}
        </span>
      </button>

      <button
        class="btn btn-outline flex-center"
        onClick={() => {
          seteditComponantId(8);
        }}
        style={{ background: "transparent" }}
      >
        <span className="max-w-[110px]">{metterButton[lang]}</span>
      </button>
      {pop && (
        <div className="fixed top-0 h-[100%] w-full bg-[#FFFAF5] z-[999999] left-0 overflow-auto animate-slideUp">
          <Setting setPop={setPop} />
        </div>
      )}
    </section>
  );
};

export default EditButtons;
