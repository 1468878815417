import React from "react";

const Phone = ({data, setData, data_id, onDelete, value}) => {
    const HandleChange = (e) => {
        const index = parseInt(e.target.dataset.id)
        var phone = data.phone

        phone[index] = {
            ...phone[index],
            [e.target.name]: e.target.value
        }

        setData({
            ...data,
            phone: phone
        })

    }
    return (
        <div className="inputs mt-3" id="phone-11622">
            <input
                type="text"
                className="pre"
                maxLength={1}
                data-id={data_id}
                defaultValue="+"
                onChange={HandleChange}
                name="plus"
                id="mobile_number"
                disabled="true"
            />
            <input
                type="number"
                className="pre-pre"
                data-id={data_id}
                onChange={HandleChange}
                style={{
                    borderBottom: "0.7px solid #ccb7b7"
                }}
                name="prefix"
                maxLength={10}
                defaultValue={value.prefix}
                placeholder={212}
            />
            <input
                type="number"
                className="final"
                name="number"
                style={{
                    borderBottom: "0.7px solid #ccb7b7"
                }}
                maxLength={20}
                data-id={data_id}
                onChange={HandleChange}
                pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                defaultValue={value.number}
                placeholder="6 01 02 03 04"
            />
            <div className="btn-delete ml-x">
            <img src="../../assets/img/icons/delete.svg" alt="" srcSet="" data-id={data_id} onClick={onDelete}/>
            </div>
        </div>
        
    )
}

export default Phone