import React from "react";
import { useGlobalState } from "../..";
import AppServices from "../../services/AppServices";

const Header = () => {
    const [onboarding, setOnboarding] = useGlobalState('onboarding')

    const ChangeOnboardingStep = (step) => {
        AppServices.post({
            action: 1,
            data: {
                onboarding_step: onboarding.step + step
            }
        }, '/onboarding').then((response) => {
            setOnboarding({
                ...onboarding,
                step: onboarding.step + step
            })
        })
    }

    return (
        <header className="details-header">
            <img
                src="../../assets/img/icons/arrow-back.svg"
                alt=""
                srcSet=""
                onClick={() => {
                    ChangeOnboardingStep(-1)
                }}
            />
            {onboarding.step !== 9 ? <p
            className="skip"
            onClick={() => {
                ChangeOnboardingStep(1)
            }}
            >
            Skip
            </p> : null}
        </header>
    )
}


export default Header