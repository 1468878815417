import React from "react";
import { useGlobalState } from "..";

const Close = (props) => {
  const { setStep1, setStep2, setPop } = props;
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");
  return (
    <div
      className="popup-header"
      onClick={() => {
        seteditComponantId(-2)
        setStep1(false)
        setStep2(false)
        setPop(false)
      }}
    >
      <img
        src="../../assets/img/icons/close.svg"
        alt=""
        srcSet=""
      />
    </div>
  );
};

export default Close;
