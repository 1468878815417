import React from "react";
import { useGlobalState } from "../..";
import AppServices from "../../services/AppServices";
import Close from "../Close";
import OrderElements from "./OrderElemets";

const Order = () => {
    const [onboardingUser, setOnboardingUser] = useGlobalState('onboarding_user')
    const [editComponantId, seteditComponantId] = useGlobalState('editComponantId')

    const defaultOrder = [
        'Avantages',
        'Coordonnées',
        'Réseaux sociaux',
        'Produits et services',
        'Prendre un rendez-vous',
        'Liens',
        'Images',
        'Vidéos'
    ]

    const handleChange = () => {
        AppServices.post({
            action: 1,
            data: {
                element_order: onboardingUser.element_order
            }
        }, '/onboarding').then((response) => {
            seteditComponantId(-1)
        })
    }

    const swapElements = (arr, i1, i2) => {
        [arr[i1], arr[i2]] = [arr[i2], arr[i1]];
        return arr
      }

    const ChangeOrder = (index, step) => {
        var newOrder = swapElements(onboardingUser.element_order, index, index + step)

        setOnboardingUser({
            ...onboardingUser,
            element_order: [
                ...newOrder
            ]
        })


    }

    return (
        <>
            <Close  />
            <section className="fields-content">
            <div className="field-title" >
                Réorganiser les champs
            </div>
            <div className="field-description" >
                Déplacez les blocs dans l'ordre que vous souhaitez afficher vos informations
            </div>
            <div className="mt-40" />
            <div className="arrange-container" style={{ marginBottom: "30rem" }}>  
                {onboardingUser.element_order.map((val, i) => {
                    return <OrderElements text={defaultOrder[val - 1]} index={i} ChangeOrder={ChangeOrder}/>
                })}          
                

            </div>
            <div className="" style={{ height: "6rem" }} />
            <div className="button-container">
                <button
                    className="btn-fields btn-active"
                    id="btn-next-arrange"
                    style={{ position: "fixed !important", bottom: "5vh !important" }}
                    onClick={handleChange}
                >
                Sauvegarder
                </button>
            </div>
            </section>
        </>

    )
}

export default Order