import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import CarrouselImagePreview from "./CarrouselImagePreview";
import Edit from "./Edit";
import { useParams } from "react-router-dom";
import useTracking from "../hooks/useTracking";
import ReactPlayer from "react-player";
import { useGlobalState } from "..";

const Carousel = (props) => {
  const [lang, setLang] = useGlobalState("lang_tran");
  const [currentImage, setCurrentImage] = useState(-1);
  const [currentVideo, setCurrentVideo] = useState(-1);
  const [videoUrl, setVideoUrl] = useState("");
  const { id } = useParams();
  const { sendTracking, trackResult } = useTracking({
    action: 150,
    collaborator_id: id,
    componant: "Video",
    url: videoUrl,
  });
  useEffect(() => {
    if (videoUrl !== "") {
      sendTracking();
    }
  }, [currentVideo]);
  const handleSendTracking = () => {};


  return (
    <>
      {currentImage !== -1 ? (
        <CarrouselImagePreview
          images={props.values}
          currentImage={currentImage}
          setCurrentImage={setCurrentImage}
        />
      ) : null}
      <section className="contact" data-id={3}>
        <div className="edit-section">
          <div className="title">{props.title}</div>
          <Edit value={props.type === 1 ? 5 : 6} />
        </div>
        <Swiper
          modules={[Pagination, Navigation]}
          spaceBetween={10}
          slidesPerView={props.items}
          keyboard={true}
          mousewheel={true}
          cssMode={true}
          pagination={{ enabled: true, clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          className="swiper-carousel"
        >
          {props.values.map((val, i) => {
            return (
              <SwiperSlide
                defaultValue={i}
                onClick={() => {
                  if (props.type != 2) {
                    setCurrentImage(i);
                  }
                }}
              >
                {props.type === 2 ? (
                  <div className="video-item">
                    {/* <iframe
                      width="100%"
                      height="281px"
                      src={
                        val.url.includes("youtu")
                          ? "https://www.youtube.com/embed/" +
                            (val.url.includes("youtu.be")
                              ? val.url.split("/")[3]
                              : val.url.split("v=")[1]?.split("&")[0])
                          : "https://player.vimeo.com/video/" +
                            val.url.split("/")[3]
                      }
                      title={val.description}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe> */}
                    <ReactPlayer
                      url={
                        val.url.includes("youtu")
                          ? "https://www.youtube.com/embed/" +
                            (val.url.includes("youtu.be")
                              ? val.url.split("/")[3]
                              : val.url.split("v=")[1]?.split("&")[0])
                          : "https://player.vimeo.com/video/" +
                            val.url.split("/")[3]
                      }
                      width="100%"
                      height="281px"
                      controls={true}
                      onPlay={() => {
                        setCurrentVideo(i);
                        setVideoUrl(
                          val.url.includes("youtu")
                            ? "https://www.youtube.com/embed/" +
                                (val.url.includes("youtu.be")
                                  ? val.url.split("/")[3]
                                  : val.url.split("v=")[1]?.split("&")[0])
                            : "https://player.vimeo.com/video/" +
                                val.url.split("/")[3]
                        );
                        handleSendTracking();
                      }}
                    />
                    <div className="video-description">{val.description[lang]}</div>
                  </div>
                ) : (
                  <img
                    class="image-carousel-container"
                    alt={i}
                    src={`${process.env.REACT_APP_IMAGE_PATH}` + val.image}
                  />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>
    </>
  );
};

export default Carousel;
