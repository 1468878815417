import React, { useEffect, useState } from "react";
import { useGlobalState } from "..";
import AppServices from "../services/AppServices";

const useLogo = (domain) => {
  const [logo, setLogo] = useState(null);
  const [checkLogo, setCheckLogo] = useState(false);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await AppServices.post(
          {
            action: 12,
            data: {
              domain: domain,
            },
          },
          "/app"
        );

        setLogo(response.logo);
        if (response.logo === null) {
          setCheckLogo(true);
        }
      } catch (error) {
        console.error("Error fetching logo:", error);
      }
    };

    fetchLogo();
  }, [domain]);

  return [logo, checkLogo];
};
const LoadingPage = () => {
  const [branding] = useGlobalState("branding");

  // get the current domain
  const domain = window.location.hostname;

  const [logo, checkLogo] = useLogo(domain);

  return (
    <>
        
          <div className="loader-wrapper-1">
            <img src={"/assets/img/logo.svg"} alt="PopCard logo" className="logo-loading" />
          </div>
        
    </>
  );
};

export default LoadingPage;
