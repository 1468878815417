import React, { useEffect, useState } from "react";
import { useGlobalState } from "..";
import ContentLoader from "react-content-loader";
import Edit from "./Edit";
import DetailsContainer from "./DetailsContainer";
import PhoneDetails from "./PhoneDetails";
import moment from "moment";


const Memebership = () => {
    const [user] = useGlobalState('user')
    const [config] = useGlobalState('config')
    const [detailsFields, setDetailsFields] = useState([])
    const [loading, setLoading] = useGlobalState('loading')
    const [branding] = useGlobalState("branding")


    const [lang] = useGlobalState("lang_tran");
    const [trans] = useGlobalState('onboarding_trans')
    return (
      <>{user?.temporary_password ? <section className="contact" data-id={2}>
            <div className="edit-section">
                <div className="title">{trans.bonus.membership[lang]}</div>
            </div>

        <div className="contact-list">
      <>
        <div
          className="single-contact-item"
        >
          <div className="contact-icon-text">
            <div className="contact-item-icon">
              <img
                src={`../assets/img/socials/membership.png`}
                width={"25px"}
                alt=""
                srcSet=""
              />
            </div>
            <div className="contact-item-text">{user?.temporary_password}</div>
          </div>
        </div>
      </>
    
        </div>
    </section>: null }</> 
    )
}

export default Memebership