import React, { useEffect, useState } from "react";
import { useGlobalState } from "../..";
import Close from "../../componants/Close";
import Header from "../../componants/onboarding/Header";
import AppServices from "../../services/AppServices";

const Calendly = ({ edit }) => {
  const [onboarding, setOnboarding] = useGlobalState("onboarding");
  const [onboardingUser, setOnboardingUser] = useGlobalState("onboarding_user");
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");
  const [safe, setSafe] = useState(true);
  const [config] = useGlobalState("config");
  const [loading, setLoading] = useState(false);

  // useEffect(() => {

  //         if (editComponantId === 2 || !edit){
  //             if ("appointment" in onboardingUser && onboardingUser?.appointment.length > 0){
  //                 setSafe(true)
  //             }else{
  //                 setSafe(false)
  //             }
  //         }
  // }, [onboardingUser.appointment])

  const handleSubmit = () => {
    setLoading(true);
    AppServices.post(
      {
        action: 1,
        data: {
          appointment: onboardingUser.appointment,
          onboarding_step: edit ? undefined : 7,
        },
      },
      "/onboarding"
    ).then((response) => {
      setLoading(false);
      if (edit) {
        seteditComponantId(-1);
      } else {
        setOnboarding({
          ...onboarding,
          step: onboarding.step + 1,
        });
      }
    });
  };
  const [lang, setLang] = useGlobalState("lang_tran");
  const [onboarding_trans] = useGlobalState("onboarding_trans");
  return (
    <>
      {edit ? <Close /> : <Header />}
      <section className="fields-content">
        <div className="field-title ">
          {onboarding_trans.profile["appointment-section"].title[lang]}
        </div>
        <div className="field-description ">
          {onboarding_trans.profile["appointment-section"].subtitle[lang]}
        </div>
        <div className="mt-40" />
        <div>
          <div className="list-social-container ">
            <div className="single-social">
              <div className="drop-social">
                <div className="text-drop-social">Calendly</div>
                <div className="arrow-drop-social flex-center">
                  <img src="../../assets/img/icons/lock.svg" alt="" srcSet="" />
                </div>
              </div>
              <div className="mt-15" />
              <div
                className="underline-input"
                style={{ border: config.restrict_appointment ? "0px" : "" }}
              >
                <input
                  type="text"
                  name="url"
                  defaultValue={
                    config.restrict_appointment
                      ? config.appointment
                      : onboardingUser.appointment
                  }
                  disabled={config.restrict_appointment}
                  onChange={(e) =>
                    setOnboardingUser({
                      ...onboardingUser,
                      appointment: e.target.value,
                    })
                  }
                  placeholder="URL Calendly"
                  id="calendar-link"
                />
              </div>
            </div>
          </div>
          <div className="mt-60" />
          <div
            className="video-preview-container "
            style={{ marginBottom: "10rem" }}
          >
            <div className="video-preview-title">
              {onboarding_trans.profile["appointment-section"].how[lang]}{" "}
              <a href="http://calendly.com">Calendly.com</a>
            </div>
            <div className="video-preview">
              <iframe
                width="100%"
                height={220}
                src="https://www.youtube.com/embed/MB6Wp72s4Ow"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen=""
              />
            </div>
          </div>

          {!config.restrict_appointment ? (
            <div className="button-container">
              <button
                className={safe ? "btn-fields btn-active" : "btn-fields"}
                id="btn-next"
                disabled={!safe}
                onClick={handleSubmit}
              >
                {edit ? (
                  <img
                    src="/assets/img/loaders/loading.gif"
                    style={{
                      display: loading ? undefined : "none",
                      padding: "2px",
                    }}
                    width="23px"
                    alt=""
                    className="ml-[45%]"
                  />
                ) : null}
                {loading
                  ? null
                  : edit
                  ? onboarding_trans?.profile.button[lang]
                  : onboarding_trans?.button_next[lang]}
              </button>
            </div>
          ) : null}
        </div>
      </section>
      <div className="mt-18" />
    </>
  );
};

export default Calendly;
export { Calendly as CalendlyOnboarding };
