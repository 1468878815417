import React, { useEffect } from "react";
import { useGlobalState } from "..";

const Loading = () => {
  const [loading, setLoading] = useGlobalState("loading_popup");
  const [branding] = useGlobalState("branding");

  return (
    <div
      style={{
        height: "100%",
        position: "fixed",
        width: "100%",
        background: "#000000ad",
        display: loading ? "block" : "none",
      }}
    >
      {branding?.logo ? (
        <div className="loading-container">
          <img
            src={branding?.logo}
            alt="PopCard logo"
            className="logo-loading"
          />
        </div>
      ) : (
        <div className="loading-container">
          <img
            src="/assets/img/logo.svg"
            alt="PopCard logo"
            className="logo-loading"
          />
        </div>
      )}
    </div>
  );
};

export default Loading;
