import React from "react";

const DeviseDropDown = ({ id, handleDropDown, locked, setDevise }) => {
  return (
    <>
      {!locked ? (
        <dropvideo
          className="dropdown-social-list-container max-w-[130px]"
          style={{
            display: "block",
            height: "96px",
            marginTop: "6vh",
            position: "absolute",
          }}
        >
          <div
            className="single-social-dropdown"
            onClick={() => setDevise("MAD")}
          >
            <div className="text-social"> MAD </div>
          </div>
          <div className="divider divider-social" />
          <div
            className="single-social-dropdown"
            onClick={() => setDevise("EUR")}
          >
            <div className="text-social"> EUR</div>
          </div>
        </dropvideo>
      ) : null}
    </>
  );
};

export default DeviseDropDown;
