import React, { useEffect, useState } from "react";
import { useGlobalState } from "..";
import useTracking from "../hooks/useTracking";
import AddNotFound from "./AddNotFound";
import Carousel from "./Carousel";
import Edit from "./Edit";

const Videos = ({ id }) => {
  const [user] = useGlobalState("user");
  const [config] = useGlobalState("config");
  const [data, setData] = useState([]);
  const [logged, setLogged] = useGlobalState("isLogged");
 

  useEffect(() => {
    if (config.restrict_videos) {
      setData([
        ...config.videos,
        ...user.videos.filter((image) => {
          
          if (image.is_admin) {
            return image;
          }
        }),
      ]);
    } else {
      setData([...user.videos]);
    }
  }, [user.videos, config.videos]);
  const [lang] = useGlobalState("lang_tran");
  const [onboarding_trans] = useGlobalState("onboarding_trans");
  return (
    <>
      {(!config.restrict_videos && user.videos.length > 0 && config.show_videos) ||
      config.restrict_videos ? (
        <Carousel items={1} values={data} title="Videos" type={2} />
      ) : logged ? (
        <section style={{ marginTop: "3vh" }}>
          <div className="edit-section">
            <div className="title">
              {
                onboarding_trans.profile["Videos-section"]?.title[lang]
              }
            </div>
            <Edit value={6} />
          </div>
          <AddNotFound text={"Ajouter vos Videos"} action={6} />
        </section>
      ) : null}
    </>
  );
};

export default Videos;
