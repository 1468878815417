import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import Edit from "./Edit";
import CarrouselProductPreview from "./CarrouselProductPreview";
import useTracking from "../hooks/useTracking";
import { useParams } from "react-router-dom";
import { useGlobalState } from "..";

const CarouselProduct = (props) => {
  const { id } = useParams();
  const [lang] = useGlobalState("lang_tran");
  const [currentImage, setCurrentImage] = useState(-1);
  const [popup, setPopup] = useState(false);
  const { sendTracking, trackResult } = useTracking({
    action: 160,
    collaborator_id: id,
    componant: "Products",
    title: props?.values[currentImage]?.title[lang],
  });

  useEffect(() => {
    if (currentImage !== -1) {
      console.log("Send Tracking");
      sendTracking();
    }
  }, [currentImage]);

  return (
    <>
      {currentImage !== -1 && popup ? (
        <CarrouselProductPreview
          images={props?.values}
          currentImage={currentImage}
          setCurrentImage={setCurrentImage}
          popup={popup}
          setPopup={setPopup}
        />
      ) : null}
      <section className="contact" data-id={3}>
        <div className="edit-section">
          <div className="title">{props?.title}</div>
          <Edit value={12} />
        </div>
        <Swiper
          modules={[Pagination, Navigation]}
          spaceBetween={10}
          slidesPerView={props.items}
          keyboard={true}
          mousewheel={true}
          cssMode={true}
          pagination={{ enabled: true, clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          className="swiper-carousel"
        >
          {props.values.map((val, i) => {
            return (
              <SwiperSlide
                key={i}
                defaultValue={i}
                onClick={() => {
                  if (props.type != 2) {
                    setCurrentImage(i);
                    setPopup(true);
                  }
                }}
              >
                <div className="relative rounded-[13px] overflow-hidden">
                  <div className="back-carousel-container"></div>
                  <div className="relative">
                    <img
                      class="rounded-2xl h-[175px] w-[168px] min-w-[168px] "
                      alt={i}
                      src={
                        `${process.env.REACT_APP_IMAGE_PATH}` + val?.pictures[0]
                      }
                    />
                    <div className="absolute bottom-3 z-[99] left-2 text-white text-[18px]">
                      {val?.price.amount === 0 || !val?.price.amount
                        ? ""
                        : val?.price.amount}{" "}
                      <span className="text-[16px] font-normal">
                        {val?.price.amount === 0 || !val?.price.amount
                          ? ""
                          : val?.price.currency}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="text-[17px] mt-2 max-w-[200px]">
                  <span style={{ wordWrap: "break-word" }}>
                    {val.title[lang].length > 30
                      ? val.title[lang].slice(0, 30) + "..."
                      : val.title[lang]}
                  </span>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* <Identification /> */}
      </section>
    </>
  );
};

export default CarouselProduct;
