import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGlobalState } from "..";
import useTracking from "../hooks/useTracking";
import useWindowOpen from "../hooks/useWindowOpen";
import AppServices from "../services/AppServices";
import AddNotFound from "./AddNotFound";
import Edit from "./Edit";

const Calendly = ({ id }) => {
  const [user, setUser] = useGlobalState("user");
  const [config, setConfig] = useGlobalState("config");
  const windowOpen = useWindowOpen;
  const [logged, setLogged] = useGlobalState("isLogged");
  const { sendTracking, trackResult } = useTracking({
    action: 102,
    collaborator_id: id,
    componant: "Calendly",
    url: config.restrict_appointment ? config.appointment : user.appointment,
  });
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");

  // const deleteCalendly = () => {
  //     AppServices.post({
  //         action: 1,
  //         data: {
  //             appointment: ""
  //         }
  //     }, '/onboarding').then((response) => {
  //         seteditComponantId(-1)
  //     })
  // }
  const [lang, setLang] = useGlobalState("lang_tran");
  const [onboarding_trans] = useGlobalState("onboarding_trans");

  const addC = {
    fr: "Ajouter votre Calendly",
    en: "Add your Calendly",
  }
  return (
    <>
      <section className="calendar">
        {config.show_appointment &&
        ((config.restrict_appointment && config.appointment?.length > 0) ||
          user.appointment.length > 0 ||
          logged) ? (
          <div className="section-top-bar">
            <div className="title">
              {onboarding_trans.profile["appointment-section"].title[lang]}
            </div>
            <Edit value={2} />
            {/* {logged ? <div className="btn-delete ml-x" >
                    <img src="../../assets/img/icons/delete.svg" alt="" srcSet=""  style={{marginLeft: 0}} onClick={deleteCalendly}/>
                </div>: null} */}
          </div>
        ) : null}
        {config.show_appointment &&
        ((config.restrict_appointment && config.appointment?.length > 0) ||
          user.appointment.length > 0) ? (
          <div
            className="calendar-list"
            onClick={() => {
              windowOpen(
                config.restrict_appointment
                  ? config.appointment
                  : user.appointment
              );
              sendTracking();
            }}
          >
            <div className="calendar-item">
              <div className="calendar-icon-text">
                <div className="calendar-item-icon">
                  <img
                    src="../../assets/img/icons/calendly.svg"
                    alt=""
                    srcSet=""
                  />
                </div>
                <div className="calendar-text">
                  {
                    onboarding_trans.profile["appointment-section"].appuyer[
                      lang
                    ]
                  }{" "}
                  {user.first_name}
                </div>
              </div>
              <div className="calendar-item-arrow">
                <img
                  src="../../assets/img/icons/arrow-right.svg"
                  alt=""
                  srcSet=""
                />
              </div>
            </div>
          </div>
        ) : config.show_appointment && logged ? (
          <AddNotFound text={addC[lang]} action={2} />
        ) : null}
      </section>
    </>
  );
};

export default Calendly;
