import React, { useEffect } from "react";
import { useGlobalState } from "../../..";
import DeviseDropDown from "./DeviseDropDown";
import VideosDropDown from "./DeviseDropDown";

const Fields = ({
  id,
  data,
  setDevise,
  devise,
  removeField,
  fieldsChange,
  handleDropDown,
  locked,
  edit,
}) => {
  const [showDropDown, setShowDropDown] = useGlobalState("dropdown_id");
  const [editComponantId, seteditComponantId] =
    useGlobalState("editComponantId");

  useEffect(() => {
    if ((editComponantId === 12 && !locked) || (!edit && !locked)) {
      function handleClick(e) {
        if (
          e.target.localName !== "dropsocial" &&
          e.target.localName !== "dropvideo"
        ) {
          setShowDropDown(false);
        }
      }
      document.body.addEventListener("click", handleClick);

      return () => {
        document.body.removeEventListener("click", handleClick);
      };
    }
  }, []);

  return (
    <dropvideo className="single-social mb-2 videos-fields mt-[3.8rem]">
      <dropvideo className="social-header">
        <dropvideo className="drop-social" onClick={() => setShowDropDown(id)}>
          <dropvideo className="text-drop-social-0">
            {" "}
            {devise === "MAD" ? "MAD" : "EUR"}{" "}
          </dropvideo>
          <dropvideo className="arrow-drop-social" style={{ display: "flex" }}>
            {!locked ? (
              <img
                src="../../assets/img/icons/arrow-down.svg"
                alt=""
                srcSet=""
              />
            ) : (
              <img src="../../assets/img/icons/lock.svg" alt="" srcset="" />
            )}
          </dropvideo>
        </dropvideo>

        {showDropDown === id ? (
          <DeviseDropDown
            handleDropDown={handleDropDown}
            setDevise={setDevise}
            id={id}
            locked={locked}
          />
        ) : null}
      </dropvideo>
    </dropvideo>
  );
};

export default Fields;
